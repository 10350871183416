import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login'
import ClientCampaigns from "@/views/client/ClientCampaigns";
import ClientSupport from "@/views/client/ClientSupport";
import ClientNews from "@/views/client/ClientNews";
import ClientProfile from "@/views/client/ClientProfile";
import ClientProductCatalogue from "@/views/client/ClientProductCatalogue";
import ClientDashboard from "@/views/client/ClientDashboard";
import AdminClients from "@/views/admin/AdminClients";
import AdminSupportTickets from "@/views/admin/AdminSupportTickets";
import AdminArticles from "@/views/admin/AdminArticles";
import AdminPlans from "@/views/admin/AdminPlans";
import AdminUsers from "@/views/admin/AdminUsers";
import ClientDocuments from "@/views/client/ClientDocuments";
import ArticleDetail from "@/views/client/ArticleDetail";


Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { path: '/login' }},
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/misCampañas',
    name: 'ClientCampaigns',
    component: ClientCampaigns,
    meta: {
      requiresAuth:true,
      requiresAdmin:false
    }
  },
  {
    path: '/misMetricas',
    name: 'ClientDashboard',
    component: ClientDashboard,
    meta: {
      requiresAuth:true,
      requiresAdmin:false
    }
  },
  {
    path: '/miCatalogo',
    name: 'ClientProductCatalogue',
    component: ClientProductCatalogue,
    meta: {
      requiresAuth:true,
      requiresAdmin:false
    }

  },
  {
    path: '/soporte',
    name: 'ClientSupport',
    component: ClientSupport,
    meta: {
      requiresAuth:true,
      requiresAdmin:false
    }

  },
  {
    path: '/novedades',
    name: 'ClientNews',
    component: ClientNews,
    meta: {
      requiresAuth:true,
      requiresAdmin:false
    }
  },
  {
    path: '/ayuda',
    name: 'ClientDocuments',
    component: ClientDocuments,
    meta: {
      requiresAuth:true,
      requiresAdmin:false
    }
  },
  {
    path: '/documentos/:documentId',
    name: 'ClientDocumentDetail',
    component: ArticleDetail,
    meta: {
      requiresAuth:true,
      requiresAdmin:false
    }
  },
  {
    path: '/miPerfil',
    name: 'ClientProfile',
    component: ClientProfile,
    meta: {
      requiresAuth:true,
      requiresAdmin:false
    }
  },



  {
    path: '/admin/clientes',
    name: 'AdminClients',
    component: AdminClients,
    meta: {
      requiresAuth:true,
      requiresAdmin: true,
      secondaryName:'Clientes'
    }

  },
  {
    path: '/admin/planes',
    name: 'AdminPlans',
    component: AdminPlans,
    meta: {
      requiresAuth:true,
      requiresAdmin: true
    }

  },
  {
    path: '/admin/usuarios',
    name: 'AdminUsers',
    component: AdminUsers,
    meta: {
      requiresAuth:true,
      requiresAdmin: true
    }

  },
  {
    path: '/admin/tickets',
    name: 'AdminSupportTickets',
    component: AdminSupportTickets,
    meta: {
      requiresAuth:true,
      requiresAdmin: true
    }
  },
  {
    path: '/admin/novedades-articulos',
    name: 'AdminArticles',
    component: AdminArticles,
    meta: {
      requiresAuth:true,
      requiresAdmin: true
    }

  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) =>{
  const user = JSON.parse(localStorage.getItem('user'))
  if (to.matched.some(record => record.meta.requiresAuth && !record.meta.requiresAdmin)){
    if (user){
      return next()
    }
    next({
      path:'/login',
      query:{redirect : to.fullPath}
    })
  }else if(to.matched.some(record => record.meta.requiresAuth && record.meta.requiresAdmin)) {
      if (user && user.role === 'admin') {
        return next()
      }else if (user && user.role !== 'admin'){
        alert('Usted no cuenta con los permisos necesarios.')
        next(false)
      }else{
        next({
          path:'/login',
          query:{redirect : to.fullPath}
        })
      }
  }else {
    next()
  }
})


export default router
