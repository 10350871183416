export default {
    toggleInitialFormDialog(state){
        state.initialFormDialog = !state.initialFormDialog
    },
    setInitialAccountData(state, payload){
        state.initialAccountData = payload
    },
    setGlobalClient(state, payload){
        state.globalClient = payload
    },
    updateGlobalClient(state, payload){
        state.globalClient = Object.assign(state.globalClient, payload)
    },
    setProductUpdateTimestamp(state, payload){
        state.lastProductsUpdate = payload
    }
}