export default {
    toggleCreateCampaignDialog(context){
        context.commit('toggleCreateCampaignDialog')
    },
    setObjective(context, payload){
        context.commit('setObjective', payload)
    },
    setInitialConfig(context, payload){
        context.commit('setInitialConfig', payload)
    },
    setAudience(context, payload){
        context.commit('setAudience', payload)
    },
    setShopWindows(context, payload){
        context.commit('setShopWindows', payload)
    },
    setBudget(context, payload){
        context.commit('setBudget', payload)
    }
}