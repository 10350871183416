import clientAPI from '@/api/clients.api'


export default {
    toggleInitialFormDialog(context){
        context.commit('toggleInitialFormDialog')
    },

    async submitInitialData(context, payload){
        try{
            const clientId = context.state.globalClient._id
            const {businessInfo} = payload
            const accountData = {
                completedInitialData:true,
                name:payload.name,
                corporateName: payload.corporateName,
                fiscalId: payload.fiscalId,
                administrativeContact:payload.administrativeContact,
                administrativeContactPhone:payload.administrativeContactPhone,
                administrativeContactEmail:payload.administrativeContactEmail,
                country:payload.accountCountry,
                plan:payload.accountPlan,
                businessInfo
            }

            const response = await clientAPI.submitInitialData(clientId, accountData)

            if (response.data.status === 'success'){
                const newClientData = response.data.client
                context.commit('updateGlobalClient', newClientData)
                return response
            }
        }catch (e) {
            console.log(e.response)
        }

    },

    setGlobalClient(context, payload) {
        context.commit('setGlobalClient', payload)
    },

    updateGlobalClient(context, payload) {
        context.commit('updateGlobalClient', payload)
    },

    setProductUpdateTimestamp(context, payload){
        let lastUpdate = payload
        if (!payload){
            const globalClient = context.getters['getGlobalClient']
            lastUpdate = globalClient.lastProductsUpdate
        }

        context.commit('setProductUpdateTimestamp', lastUpdate)
    }
}