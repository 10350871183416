<template>
  <div class="plans">
    <v-container>
      <v-row>
        <v-col md="12">
          <h1 class="subheading text-md-h4">Planes</h1>
        </v-col>
      </v-row>

    </v-container>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-card class="rounded-xl">
            <v-card-title class="d-flex justify-space-between">
              <v-col md="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col md="2" class="mr-4">
                <v-btn
                    color="info"
                    outlined
                    @click="planDialog = !planDialog"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Crear Plan
                </v-btn>
              </v-col>

            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="plans"
                :search="search"
                :loading="loadingPlans"
                loading-text="Cargando ... Por favor aguarde"
                height="370"

            >

              <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="toggleUpdatePlan(item)"
                >
                  mdi-pencil
                </v-icon>
                <!--                <v-icon-->
                <!--                    small-->
                <!--                    @click="deleteClient(item)"-->
                <!--                >-->
                <!--                  mdi-delete-->
                <!--                </v-icon> -->
                <v-icon
                    small
                    @click="alertDelete(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>



    <!--Dialogo Crear Plan-->
    <AdminCreatePlanDialog
        :toggleDialog="planDialog"
        v-on:planCreated="planCreated($event)"
        v-on:closeDialog="closeDialog($event)"
        v-on:responseFail="toggleError($event)">
    </AdminCreatePlanDialog>

    <AdminUpdatePlanDialog
        v-if="currentPlan"
        :toggleDialog="updatePlanDialog"
        :currentPlan="currentPlan"
        v-on:planUpdated="planUpdated($event)"
        v-on:closeDialog="closeDialog($event)"
        v-on:responseFail="toggleError($event)">
    </AdminUpdatePlanDialog>



    <!--Dialogo eliminar plan-->

    <v-dialog
        v-model="toggleAlertDelete"
        max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          ¿Está seguro que desea eliminar el plan?
        </v-card-title>
        <v-card-subtitle class="text-center">
          <v-icon x-large class="text-h2">
            mdi-alert
          </v-icon>
        </v-card-subtitle>
        <v-card-text>
          Si elimina el plan, el cliente no podrá crear campañas.
        </v-card-text>

        <v-card-actions>

          <v-btn
              color="green darken-1"
              text
              @click="cancelDelete"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="red darken-1"
              @click="deletePlan"
              outlined
          >
            <v-icon>
              mdi-delete
            </v-icon>
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-snackbar
        v-model="snackbar"
        top
        class=""
        timeout="8000"
    >
      <v-icon left>mdi-alert</v-icon>
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"

        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </div>

</template>

<script>
import AdminCreatePlanDialog from "@/components/admin/plan/AdminCreatePlanDialog";
import AdminUpdatePlanDialog from "@/components/admin/plan/AdminUpdatePlanDialog";

import httpClient from "@/api/httpClient";


export default {
name: "AdminPlans",
  components:{
    AdminCreatePlanDialog,
    AdminUpdatePlanDialog
  },
  data(){
    return{
      search:'',
      plans:[],
      loadingPlans:false,
      loadingResponse:false,
      headers: [
        { text: 'Cliente', value: 'client.name'},
        { text: 'Nombre',  value: 'name'},
        { text: 'Moneda', value: 'currency'},
        { text: 'Precio', value: 'price' },
        { text: 'Inv Min / Aud', value: 'audienceMinInvestment' },
        { text: 'Creado el', value: 'createdAt' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      planDialog:false,
      validForm:false,
      nameRules:[
        v => !!v || 'Debe ingresar un nombre.',
        v => (v && v.length <= 30) || 'El nombre debe tener menos de 30 caracteres.',
      ],
      clientName:'',
      updatePlanDialog:false,
      currentPlan:null,
      toggleAlertDelete:false,
      deletablePlanId:'',
      showPassword:false,
      snackbar:false,
      errorMessage:''
    }
  },
  methods:{
    validateForm() {
      this.$refs.form.validate()
    },
    async getPlans(){
      this.loadingPlans = true
      const response = await httpClient.get(`/plans`)
      if (response.data.status ==='success'){
        this.plans = response.data.plans
        this.plans.map(plan =>{
          return plan.createdAt = new Date(plan.createdAt).toLocaleDateString()
        })
        this.loadingPlans = false
      }

    },
    closeDialog($event){
      this.planDialog=$event
      this.updatePlanDialog=$event
    },
    planCreated(){
      // this.plans.push(data)
      this.getPlans()
    },
    toggleUpdatePlan(item){
      this.currentPlan = item
      this.updatePlanDialog = !this.updatePlanDialog
    },
    planUpdated(){
      this.getPlans()
    },

    async alertDelete(item){
      this.toggleAlertDelete = true
      this.deletablePlanId = item._id
    },
    cancelDelete(){
      this.deletablePlanId = ''
      this.toggleAlertDelete = !this.toggleAlertDelete
    },
    async deletePlan(){
      if (this.deletablePlanId){
        const response = await httpClient.delete(`/plans/${this.deletablePlanId}`)
        const deletedPlan = response.data.plan
        if (response.data.status === 'success'){
          this.toggleAlertDelete = false
        }
        this.plans = this.plans.filter(plan =>{
          return plan._id !== deletedPlan._id
        })
      }
    },
    toggleError(data){
      this.errorMessage = data
      this.snackbar = true
    },
  },
  async mounted() {
    await this.getPlans()
    //seteo un currentPlan por default asi el dialogo no tira error
    this.currentPlan = this.plans[0]
  }
}
</script>

<style scoped>

</style>