<template>
  <v-row justify="center">

    <v-dialog
        v-model="createShopWindowDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-overlay :value="loadingResponse" >
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card>

        <v-app-bar
            dark
            color="primary"
            fixed
            height="80"
        >
          <v-btn
              icon
              dark
              @click="toggleCreateShopWindowDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Crear Nueva Vidriera</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                @click="createShopWindow"
                x-large
                text
                :loading="loadingResponse"
                :disabled="!validForm || selectedProducts.length<5"
            >
              <v-icon left>
                mdi-content-save
              </v-icon>
              Guardar
            </v-btn>
          </v-toolbar-items>

        </v-app-bar>

        <v-container class="mt-15 py-15">
          <v-form
              ref="form"
              v-model="validForm"
              lazy-validation
          >
            <v-row>
              <v-col md="6">
                <div class="mb-5">
                  <h2>1. Propiedades de la vidriera</h2>
                </div>
                <v-text-field
                    label="Nombre de la vidriera"
                    outlined
                    dense
                    v-model="shopWindow.name"
                    prepend-inner-icon="mdi-text-short"
                    class="mb-5"
                    :rules="nameRules"
                    placeholder="El título va aquí"
                />


                <div class="mb-5">
                  <h2>2. Propiedades del anuncio</h2>
                  <p>Configura el contenido de tu anuncio.</p>
                </div>
                <v-text-field
                    label="Título del anuncio"
                    outlined
                    dense
                    v-model="shopWindow.title"
                    prepend-inner-icon="mdi-format-title"
                    class="mb-5"
                    :rules="titleRules"
                    placeholder="El título va aquí"
                >
                </v-text-field>
                <v-textarea
                    outlined
                    label="Texto principal"
                    counter="500"
                    v-model="shopWindow.copy"
                    prepend-inner-icon="mdi-text"
                    :rules="copyRules"
                    class="mb-5"
                    placeholder="El texto principal va aquí"
                >
                </v-textarea>
                <v-file-input
                    accept="image/*"
                    label="Imagen"
                    outlined
                    counter
                    show-size
                    @change="appendImage"
                    prepend-icon=""
                    prepend-inner-icon="mdi-image"
                    dense
                    class="mb-5"
                    v-model="shopWindow.image"
                    :rules="imageRules"
                ></v-file-input>
                <strong>
                  <small>* Carga imágenes de proporciones cuadradas (relación 1:1) y de alta resolución (mínimo 600x600px). Tamaño máximo 2 MB. Evita textos grandes en tus imágenes.</small>

                </strong>
              </v-col>
              <v-col md="2" class="d-flex align-center justify-center">
                <v-icon  color="info lighten-2" class="arrow-icon">
                  mdi-swap-horizontal
                </v-icon>
              </v-col>
              <v-col md="4">
                <div class="mb-5 ">
                  <h3>Vista previa del anuncio:</h3>
                </div>

                <InstagramPreview :img="this.previewImage" :title="shopWindow.title" :description="shopWindow.copy"/>


              </v-col>


            </v-row>
            <v-row class="mt-5" >
              <v-col md="6">

                <v-btn
                    outlined
                    color="red"
                    @click="resetFields"
                    class="mt-6"
                >
                  <v-icon left>
                    mdi-refresh
                  </v-icon>
                  Restablecer campos
                </v-btn>
              </v-col>


            </v-row>

            <v-divider class="mt-8"></v-divider>
          </v-form>


          <v-row class="mt-10" >
            <v-col md="8" >
              <h2>3. Selección de productos</h2>
              <p>Selecciona los productos que quieras mostrar en tu vidriera junto al anuncio.</p>
            </v-col>
            <v-col md="4" >
              <h3>Productos seleccionados</h3>
              <p>Listado de los productos incluidos en tu vidriera. Puedes agregar o eliminar cuantos quieras.</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="8">
              <v-card flat outlined>
                <v-card-title>
                  <v-col md="6">

                    <h5 :class="selectedProducts.length<15?'alert-red':'alert-green'">Productos seleccionados: {{selectedProducts.length}} / 15</h5>
                    <h6>Debes elegir 15 (quince) productos como mínimo.</h6>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        outlined
                        dense
                        hint="Buscar por Producto, Tipo o Disponibilidad."
                        persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="d-flex flex-column align-end justify-end ">
                    <v-btn
                        color="info"
                        @click="updateProducts"
                        :loading="loadingProducts"
                        :disabled="!enabledProductsUpdate"
                        outlined

                    >
                      <v-icon left>
                        mdi-refresh
                      </v-icon>
                      Actualizar catálogo
                    </v-btn>
                    <h6 class="last-product-update">Podrás actualizar tu catálogo cada {{productUpdateTime}} minutos</h6>
                    <h6 class="last-product-update">Útima actualización: {{new Date(lastProductsUpdate).toLocaleString()}} </h6>
                  </v-col>

                </v-card-title>
                <v-data-table
                    :headers="headers"
                    v-model="selectedProducts"
                    :items="products"
                    item-key="id"
                    :search="search"
                    :loading="loadingProducts"
                    show-select
                    loading-text="Cargando ... Por favor aguarde"
                    class="v-data-table"
                    height="300"
                    :items-per-page="5"

                >
                  <template v-slot:item.image="{ item }">
                    <div class="p-2">
                      <v-img  :src="item['image_link']" alt="asd" class="product-image-container" width="50px" height="50px"></v-img>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col  md="4">
              <v-list
                  v-if="selectedProducts.length>0"
                  two-line
                  style="border: thin solid rgba(0,0,0,.12); max-height: 545px; overflow-y: scroll"
              >
                <template v-for="(product, index) in selectedProducts" >
                  <v-list-item :key="index">
                    <v-list-item-avatar>
                      <img :src="product.image_link" :alt="product.title"/>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-text="product.title"></v-list-item-title>

                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon @click="removeSelectedProduct(product.id)">
                        <v-icon color="grey lighten-1">mdi-close</v-icon>
                      </v-btn>
                    </v-list-item-action>

                  </v-list-item>
                  <v-divider
                      v-if="index < selectedProducts.length - 1"
                      :key="product.title"
                  ></v-divider>
                </template>
              </v-list>
              <div v-else style="display: flex; justify-content: center; flex-direction: column">
                <v-icon style="font-size: 4em" class="mt-5">
                  mdi-playlist-plus
                </v-icon>
              </div>
            </v-col>

          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import httpClient from "@/api/httpClient";
import clientAPI from "@/api/clients.api";
import InstagramPreview from "@/components/client/shopWindow/InstagramPreview";


export default {
  name: "createShopWindowDialog",
  inject:['campaign', 'updatableCampaign'],
  data(){
    return{
      globalClient:null,
      createShopWindowDialog:null,
      search: '',
      headers: [
        { text: 'Identificador', value: 'id' },
        { text: "Imagen", value: "image", sortable: false },
        {
          text: 'Producto',
          align: 'start',
          value: 'title',
        },
        { text: 'Tipo', value: 'product_type' },
        { text: 'Disponibilidad', value: 'availability' },
      ],
      products:[],
      loadingProducts:false,
      loadingResponse:false,
      selectedProducts:[],
      previewImage:'',
      shopWindow:{
        name:'',
        title:'',
        products:[],
        copy:'',
        image:null
      },
      validForm:false,
      nameRules:[
        v => !!v || 'Debes ingresar un nombre para la vidriera.',
      ],
      titleRules:[
        v => !!v || 'Debes ingresar un título para tu anuncio.',
      ],
      copyRules:[
        v => !!v || 'Debes ingresar una descripción para tu anuncio.',
        v => (v && v.length <= 500) || 'La descripción debe tener un máximo de 500 caracteres.',
      ],
      imageRules:[],
      enabledProductsUpdate:true,
      lastProductsUpdate:null,
      productUpdateTime:null
    }
  },
  components:{
    InstagramPreview
  },
  methods:{

    async getMyProducts(){
      this.loadingProducts = true
      const clientId = this.globalClient._id
      try{
        const response = await httpClient.get(`clients/myClients/${clientId}/getMyProducts`)

        if(response.data.status==='success'){
          this.products = response.data.products

          this.lastProductsUpdate = response.data.lastProductsUpdate

          this.loadingProducts = false

        }
      }catch (e) {
        this.loadingProducts = false
        this.enabledProductsUpdate = false
      }
    },
    async createShopWindow(){
      this.loadingResponse=true
      try{
        const formData = new FormData()

        formData.append('name', this.shopWindow.name)
        formData.append('title', this.shopWindow.title)
        formData.append('copy', this.shopWindow.copy)
        formData.append('products', JSON.stringify(this.shopWindow.products))
        formData.append('clientId', this.globalClient._id)
        if (this.shopWindow.image){
          formData.append('image', this.shopWindow.image, this.shopWindow.image.name)
        }

        const response = await httpClient.post(`/shopWindows`, formData)
        if (response.data.status === 'success'){
          const newShopWindow = response.data.shopWindow

          newShopWindow.createdAt = new Date().toLocaleDateString()
          //aca habria q pushear la nueva vidriera al store
          this.loadingResponse=false
          this.resetFields()
          this.$emit('shopWindowCreated', newShopWindow)
          this.toggleCreateShopWindowDialog()
        }
      }catch (e) {
        console.log(e)
      }
    },
    appendImage(e){
      this.shopWindow.image = e
      if (this.shopWindow.image){
        this.previewImage = URL.createObjectURL(this.shopWindow.image)
      }
    },
    resetFields(){
      this.shopWindow.title=''
      this.shopWindow.copy=''
      this.shopWindow.image=null
      this.previewImage=''

    },
    async updateProducts(){
      try{
        this.loadingProducts = true

        const response = await clientAPI.updateMyProducts(this.globalClient._id)
        if(response.data.status==='success'){
          this.enabledProductsUpdate = false
          this.products = response.data.products

          const productSample = this.products[0]
          const productKeys = Object.keys(productSample)
          this.isGTypeXML = productKeys.some(key =>{
            return key.includes('g:')
          })

          await this.$store.dispatch('account/setProductUpdateTimestamp', response.data.lastProductsUpdate)
          this.lastProductsUpdate = response.data.lastProductsUpdate
          this.loadingProducts = false

        }
      }catch (e) {
        this.loadingProducts = false
        this.enabledProductsUpdate = false
      }
    },
    async enableProductsUpdate(){
      const lastUpdate = new Date(this.lastProductsUpdate).getTime()
      let now = new Date().getTime()
      let timeSpan = Math.floor((now - lastUpdate)/1000/60)
      timeSpan < this.productUpdateTime? this.enabledProductsUpdate = false : true
      setTimeout(()=>{
        this.enabledProductsUpdate = true
      },this.productUpdateTime * 60 * 1000)

    },
    toggleCreateShopWindowDialog(){
      this.$store.dispatch('shopWindow/toggleCreateShopWindowDialog')
    },
    removeSelectedProduct(productId){
      this.selectedProducts = this.selectedProducts.filter(product => product.id !== productId)
    }

  },
  computed:{
    dialogOn(){
      return this.$store.getters['shopWindow/getShopWindowDialogToggleState']
    },
  },
  watch:{
    dialogOn(value){
      return this.createShopWindowDialog = value
    },
    products(){
      this.products.map(product =>{
        if ( product['availability'] === 'in stock'){
          product['availability'] = 'Disponible'
        }
        else{
          product['availability'] = 'No disponible'
        }
      })
    },
    selectedProducts(value){
      this.shopWindow.products = value
    },
    'campaign.objective':function (val) {
      if (val !== 'tienda online'){
        this.imageRules = [
          v => !!v || 'Debes ingresar una imagen de portada para tu anuncio.',
          value => !value || value.size < 2000000 || 'La imagen debe pesar menos de 2MB.',
        ]
      }else{
        this.imageRules = [
          value => !value || value.size < 2000000 || 'La imagen debe pesar menos de 2MB.',
        ]
      }
    },
    'updatableCampaign.objective':function (val) {
      if (val !== 'tienda online'){
        this.imageRules = [
          v => !!v || 'Debes ingresar una imagen de portada para tu anuncio.',
          value => !value || value.size < 2000000 || 'La imagen debe pesar menos de 2MB.',
        ]
      }else{
        this.imageRules = [
          value => !value || value.size < 2000000 || 'La imagen debe pesar menos de 2MB.',
        ]
      }
    }
  },
  mounted() {
    this.productUpdateTime = process.env.VUE_APP_CLIENT_PRODUCT_UPDATE_TIMESTAMP_MINUTES
    this.globalClient = this.$store.getters['account/getGlobalClient']
    this.lastProductsUpdate = this.$store.getters['account/getLastProductsUpdate']
    this.getMyProducts()
    this.enableProductsUpdate()
    if (this.campaign){
      if (this.campaign.objective !== 'tienda online'){
        this.imageRules = [
          v => !!v || 'Debes ingresar una imagen de portada para tu anuncio.',
          value => !value || value.size < 2000000 || 'La imagen debe pesar menos de 2MB.',
        ]
      }else{
        this.imageRules = [
          value => !value || value.size < 2000000 || 'La imagen debe pesar menos de 2MB.',
        ]
      }
    }

    if (this.updatableCampaign){
      if (this.updatableCampaign.objective !== 'tienda online'){
        this.imageRules = [
          v => !!v || 'Debes ingresar una imagen de portada para tu anuncio.',
          value => !value || value.size < 2000000 || 'La imagen debe pesar menos de 2MB.',
        ]
      }else{
        this.imageRules = [
          value => !value || value.size < 2000000 || 'La imagen debe pesar menos de 2MB.',
        ]
      }
    }


  },
}
</script>

<style scoped>
.v-data-table {
  line-height:2;
}

.product-image-container{
  border-radius: 50%;
  border: 1px solid #66bb6a;
}

.alert-red{
  color: #fa0404;
}
.alert-green{
  color:#66bb6a
}
.arrow-icon{
  font-size: 60px !important;
}
</style>