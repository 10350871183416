var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-app-bar',{attrs:{"app":"","color":"white","dark":"","flat":"","height":"80"}},[_c('v-app-bar-nav-icon',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Convertics Logo","contain":"","src":_vm.converticsLogo,"transition":"scale-transition","width":"200"}}),_c('v-img',{staticClass:"shrink mt-1 hidden-sm-and-down",attrs:{"alt":"Vuetify Name","contain":"","min-width":"100","src":"","width":"100"}})],1),_c('v-spacer'),_c('v-container',[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{attrs:{"md":"3"}},[_c('v-select',{attrs:{"items":_vm.userClients,"item-text":"name","item-value":"_id","label":"Mis Cuentas:","flat":"","light":"","hide-details":"","color":"primary"},on:{"change":_vm.setGlobalClient},model:{value:(_vm.globalClient),callback:function ($$v) {_vm.globalClient=$$v},expression:"globalClient"}})],1)],1)],1),_c('v-menu',{attrs:{"left":"","nudge-left":"50","max-width":"350","open-on-hover":"","close-delay":"300","disabled":_vm.news.length<1},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 ml-7",attrs:{"color":"primary","dark":"","large":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.notificationIcon)}}),(_vm.news.length>0)?_c('div',{staticClass:"notification-alert"}):_vm._e()],1)]}}])},_vm._l((_vm.news),function(item){return _c('v-list',{key:item._id,staticClass:"py-0"},[_c('v-list-item',{attrs:{"link":"","to":{name:'ClientNews'}}},[_c('v-list-item-avatar',[(item.image)?_c('img',{attrs:{"src":item.image.location,"alt":"avatar"}}):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-newspaper ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.caption))])],1)],1),_c('v-divider',{staticClass:"ma-0"})],1)}),1),_c('v-menu',{attrs:{"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-10 ml-3",attrs:{"color":"primary","dark":"","x-large":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","color":"primary"}},[_vm._v(" mdi-account-circle ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":{name:'ClientProfile'}}},[_c('v-list-item-title',[_vm._v("Mi Perfil")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.signOutUser}},[_c('v-list-item-title',[_vm._v("Cerrar Sesión")])],1)],1)],1)],1),_c('v-navigation-drawer',{attrs:{"app":"","dark":"","color":"dark","mini-variant":"","expand-on-hover":"","width":"200","mini-variant-width":"70"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":"","to":item.route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"deep-orange",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Slide X Transition ")])]}}])},[_c('v-list',_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }