<template>
  <div class="campaigns">
    <v-container>
      <v-row>
        <v-col md="12">
          <h1 class="subheading text-md-h4">Mis Campañas</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="campaigns.length>0">
      <v-row>
        <v-col md="1" class="d-flex align-center">
          <h4 >Filtrar:</h4>
        </v-col>
        <v-col md="5" class="d-flex align-center">
          <v-btn
              text
              color="blue"
              @click="filterCampaigns()"

          >
            <v-icon left>
              mdi-format-list-bulleted
            </v-icon>
            Todas
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="filterCampaigns('activa')"
          >
            <v-icon left>
              mdi-airplane
            </v-icon>
            Activas
          </v-btn>
          <v-btn
              text
              color="grey"
              @click="filterCampaigns('inactiva')"
          >
            <v-icon left>
              mdi-airplane-off
            </v-icon>
            Inactivas
          </v-btn>
        </v-col>

        <v-col md="6" class="d-flex justify-end" >
          <v-btn
              class="ma-2"
              outlined
              color="info lighten-1"
              @click="getMyCampaigns"
              v-if="campaigns.length>0"
              :loading="loadingCampaigns"
          >
            <v-icon left>mdi-refresh</v-icon>
            Actualizar Campañas
          </v-btn>
          <v-btn
            class="ma-2"
            color="success"
            @click="toggleCreateCampaignModal"
            v-if="campaigns.length>0"
          >
            <v-icon left>mdi-plus</v-icon>
            Crear Campaña
            <template v-slot:loader>
              <span>Loading...</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="campaigns.length>0">
      <v-card class="campaign-card pa-3" :class="campaign.status==='activa'?'activa':'inactiva'" flat v-for="campaign in filteredCampaigns" :key="campaign.id">
          <v-row class="d-flex flex-wrap"  >
            <v-col md="2" >
              <div class="caption grey--text">Campaña</div>
              <div style="word-break: break-word; font-weight: bold;">{{ campaign.initialConfig.descriptiveName }} </div>
              <div style="word-break: break-word;">Actualizada el: {{ new Date(campaign.updatedAt).toLocaleDateString() }} </div>
            </v-col>
            <v-col md="2" class="pl-0">
              <div class="caption grey--text text-md-center">Estado</div>
              <div class="d-flex justify-center">
                <v-chip
                    :color="campaign.status === 'activa'?'success':'grey'"
                    outlined
                >
                  {{campaign.status}}
                </v-chip>
              </div>
            </v-col>
            <v-col md="1" >
              <div class="caption grey--text">Objetivo</div>
              <div>{{ campaign.objective }}</div>
            </v-col>
            <v-col md="3" >
              <div class="caption grey--text text-md-center ">Presupuestos</div>
              <div class="d-flex justify-center">Diario: {{ campaign.budget.dailyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} {{globalClient.plan.currency}}</div>
              <div class="d-flex justify-center">Mensual: {{ parsedMonthlyPrice(campaign.budget.dailyPrice) }} {{globalClient.plan.currency}}</div>
            </v-col>
            <v-col md="2" >
              <div class="caption grey--text">Fechas</div>
              <div>Inicio: {{ new Date(campaign.initialConfig.startDate).toLocaleDateString() }}</div>
              <div v-if="campaign.initialConfig.endDate">Fin: {{ new Date(campaign.initialConfig.endDate).toLocaleDateString() }}</div>
            </v-col>


            <v-col md="2">
              <div class="caption grey--text text-md-center">Acciones</div>
              <div class="d-flex justify-center">
                <v-tooltip bottom >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="toggleCampaignDetail(campaign)"
                        color="deep-purple accent-1"

                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver detalle</span>
                </v-tooltip>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="toggleCampaignEditAlert(campaign)"
                        color="info lighten-2"
                        class="mx-3"

                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Campaña</span>
                </v-tooltip>
                <v-tooltip bottom >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="toggleCampaignPause(campaign)"
                        :disabled="campaign.status==='en revisión'"
                        :color="campaign.status === 'activa'?'orange lighten-2':'primary'"

                    >
                      <v-icon>{{campaign.status === 'activa'?'mdi-airplane-landing':'mdi-airplane-takeoff'}}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{campaign.status === 'activa'?'pausar campaña':'activar campaña'}}</span>
                </v-tooltip>

              </div>

            </v-col>
          </v-row>
        </v-card>

    </v-container>

    <v-container v-else class="mt-10">
      <v-row>
        <v-col v-if="loadingCampaigns" md="12" class="d-flex justify-center mt-15">
          <v-progress-circular
              :indeterminate="loadingCampaigns"
              color="primary"
              :size="120"
              :width="4"
          >
            Cargando...
          </v-progress-circular>
        </v-col>
        <v-col v-if="!loadingCampaigns" md="12" class="d-flex flex-column align-center justify-center">
          <v-icon color="grey lighten-1" class="empty-campaign-icon">
            mdi-tray-alert
          </v-icon>
          <h2 class="grey--text">Aún no tienes campañas</h2>
          <v-btn
              class="ma-2"
              color="success"
              outlined
              @click="toggleCreateCampaignModal"
          >
            <v-icon>mdi-plus</v-icon>
            Crear Mi Primer Campaña
            <template v-slot:loader>
              <span>Loading...</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <CreateCampaignDialog v-on:campaignCreated="createdCampaign($event)"/>
    <CampaignDetailDialog
        v-if="currentCampaign"
        :campaign="currentCampaign"
        :toggle="toggleDetail"
        :globalClient="globalClient"
        v-on:closeCampaignDetailDialog="toggleDetail=$event"
    ></CampaignDetailDialog>
    <UpdateCampaignDialog
        v-if="currentCampaign"
        :updatableCampaign="currentCampaign"
        :budget="currentCampaign.budget"
        :toggle="toggleUpdate"
        :globalClient="globalClient"
        v-on:closeUpdateCampaignDialog="toggleUpdate=$event"
        v-on:campaignUpdated="campaignUpdated"
    ></UpdateCampaignDialog>


    <!--Dialogo pausar campaña-->

    <v-dialog
        v-model="togglePause"
        max-width="550"
        v-if="currentCampaign"
    >
      <v-card>
        <v-card-title class="headline">
          {{currentCampaign.status === 'activa'?'¿Está seguro que desea pausar la campaña?':'¿Está seguro que desea activar la campaña?'}}
        </v-card-title>
        <v-card-subtitle class="text-center mt-1">
          <v-icon x-large class="text-h2">
            mdi-alert
          </v-icon>
        </v-card-subtitle>
        <v-card-text class="text-center">
          {{ currentCampaign.status === 'activa'?'Al pausar la campaña, la mísma dejará de correr en las distintas plataformas.' :'Una vez activa, comenzará a consumir parte del saldo asignado a la mísma.' }}
        </v-card-text>
        <v-card-actions>
          <v-btn
              text
              @click="togglePause=!togglePause"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              :color="currentCampaign.status === 'activa'?'orange lighten-1':'primary'"
              outlined
              :loading="loadingResponse"
              @click="updateCampaignStatus(currentCampaign)"
          >
            <v-icon left>
              {{ currentCampaign.status === 'activa'? 'mdi-pause':'mdi-play' }}
            </v-icon>
            {{ currentCampaign.status === 'activa'? 'pausar':'activar' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialogo alerta de actualización de campaña-->

    <v-dialog
        v-model="toggleUpdateAlert"
        max-width="600"
        persistent
    >
      <v-card class="text-center py-5">
        <v-card-title class=" flex justify-center">
          ATENCIÓN
        </v-card-title>
        <v-card-subtitle class="text-center">
          <v-icon x-large class="text-h2">
            mdi-alert
          </v-icon>
        </v-card-subtitle>
        <v-card-text class="px-10" style="font-size: 18px;">
          <p>Cualquier modificación en una campaña “activa” reiniciará el proceso de aprendizaje inteligente pudiendo afectar el rendimiento de la misma.</p>
          <br>
          <p>Una vez realizadas las modificaciones las mismas pueden demorar hasta 72hs hábiles en ser aprobadas.</p>
        </v-card-text>

        <v-card-actions>

          <v-btn
              text
              @click="cancelCampaignEdit"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="orange"
              outlined
              :loading="loadingResponse"
              @click="confirmCampaignEdit"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Editar de todas formas
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CreateCampaignDialog from "@/components/client/campaigns/CreateCampaignDialog";
import CampaignDetailDialog from "@/components/client/campaigns/CampaignDetailDialog";
import UpdateCampaignDialog from "@/components/client/campaigns/UpdateCampaignDialog";

import httpClient from "@/api/httpClient";

export default {
  name: "ClientCampaigns",
  components:{
    CreateCampaignDialog,
    CampaignDetailDialog,
    UpdateCampaignDialog
  },
  data(){
    return{
      campaigns:[],
      newCampaignStatus:null,
      filteredCampaigns:[],
      globalClient:null,
      currentCampaign:null,
      toggleDetail:false,
      toggleUpdate:false,
      toggleUpdateAlert:false,
      togglePause:false,
      loadingCampaigns:false,
      loadingResponse:false,
    }
  },
  methods:{
    toggleCreateCampaignModal(){
      this.$store.dispatch('campaign/toggleCreateCampaignDialog')
    },
    toggleInitialFormModal(){
      this.$store.dispatch('account/toggleInitialFormDialog')
    },
    async getMyCampaigns(){
      this.loadingCampaigns = true
      try{
        const response = await httpClient.get(`/campaigns/myCampaigns/${this.globalClient._id}`)
        if (response.data.status === 'success'){
          this.campaigns = response.data.campaigns
          this.filteredCampaigns = response.data.campaigns
          this.loadingCampaigns = false
        }
      }catch (e) {
        console.log(e)
      }
    },
    createdCampaign(){
      this.getMyCampaigns()
    },
    campaignUpdated(){
      this.getMyCampaigns()
    },
    parsedMonthlyPrice(price){
      let number = price * 31
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    toggleCampaignDetail(campaign){
      this.currentCampaign = campaign
      this.toggleDetail=true
    },
    toggleCampaignUpdate(campaign){
      this.currentCampaign = campaign
      this.toggleUpdate=true
    },
    toggleCampaignEditAlert(campaign){
      this.toggleUpdateAlert=true
      this.currentCampaign = campaign
    },
    confirmCampaignEdit(){
      this.toggleCampaignUpdate(this.currentCampaign)
      this.toggleUpdateAlert=false
    },
    cancelCampaignEdit(){
      this.currentCampaign = null
      this.toggleUpdateAlert=false
    },
    toggleCampaignPause(campaign){
      this.currentCampaign = campaign
      this.togglePause = true
    },
    filterCampaigns(filter){
      if (!filter){
        return this.filteredCampaigns = this.campaigns
      }
      this.filteredCampaigns = this.campaigns.filter(campaign =>{
        return campaign.status === filter
      })
    },
    async updateCampaignStatus(campaign){
      this.loadingResponse = true

      if (campaign.status === 'activa'){
        this.newCampaignStatus = 'inactiva'
      }else if(campaign.status === 'inactiva'){
        this.newCampaignStatus = 'activa'
      }

      try{
        const response = await httpClient.patch(`/campaigns/myCampaigns/${campaign._id}`, {
          status:this.newCampaignStatus
        })
        if (response.data.status === 'success'){
          await this.getMyCampaigns()
          this.loadingResponse = false
          this.togglePause = false

        }
      }catch (e) {
        console.log(e)
      }
    },
  },
  mounted(){
    this.globalClient = this.$store.getters['account/getGlobalClient']
    if (!this.globalClient.completedInitialData){
      this.toggleInitialFormModal()
    }
    this.getMyCampaigns()

  },
  //con este guard chechkeo si esta yendo a ayuda o soporte, y si es así, minimizo el modal inicial
  beforeRouteLeave(to, from, next){
    if (!this.globalClient.completedInitialData){
      if (to.name === 'ClientDocuments' || to.name === 'ClientSupport'){
        this.toggleInitialFormModal()
      }
    }

    next()
  }
}
</script>

<style scoped>
  .campaign-card{
    border-bottom: 1px solid #e2e2e2 !important;
  }
  .empty-campaign-icon{
    font-size: 10em !important;
  }
  .activa{
    border-left: 6px solid #43A047 !important;
  }

  .inactiva{
    border-left: 6px solid #999999 !important;
  }
</style>