import accountMutations from './mutations'
import accountActions from './actions'
import accountGetters from './getters'

export default {
    namespaced:true,
    state:{
        globalClient: null,
        completedInitialForm:false,
        initialFormDialog:false,
        initialAccountData:null,
        lastProductsUpdate:null
    },
    mutations: accountMutations,
    actions: accountActions,
    getters: accountGetters
};
