import { deleteArticleAssets } from "@/api/helpArticle.api";

export default {
    async deleteAsset(_,payload){
        try{
            return await deleteArticleAssets(payload.article._id, payload.fileType)
        }catch (e) {
            console.log(e)
        }

    }
}