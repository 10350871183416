import clientAPI from '@/api/clients.api'
import router from "@/router";


export default {
   async signInUser({dispatch, commit}, payload){

           const {user, token} = payload
           localStorage.setItem('token', token)
           localStorage.setItem('user', JSON.stringify(user))



           if (user && user.role !== 'admin'){

               const defaultGlobalClient = user.clients[0]
               dispatch('account/setGlobalClient', defaultGlobalClient)
               dispatch('account/setProductUpdateTimestamp')

               await dispatch('news/getRecentNews', null,{root:true})

           }
               commit('signInUser', {
                   user,
                   token,
               })

    },

    /*Hay q ver si conviene agregar que el trylogin checkee
    con el backend si el token sigue siendo valido*/

     async tryLogin({dispatch, commit}){
       const user = JSON.parse(localStorage.getItem('user'))
       const token = localStorage.getItem('token')
        if (user && token){
            if (user.role !== 'admin'){
                const response = await clientAPI.getMyClients(user._id)

                user.clients = response.data.clients

                const defaultGlobalClient = user.clients[0]
                dispatch('account/setGlobalClient', defaultGlobalClient)
                dispatch('account/setProductUpdateTimestamp')
                await dispatch('news/getRecentNews', null,{root:true})
                router.push({path:'/misCampañas'})

            }else{
                router.push({path:'/admin/clientes'})
            }

            return  commit('signInUser', {user})
        }

    },
    async signOutUser(context){
       localStorage.removeItem('user')
       localStorage.removeItem('token')
       context.commit('signOutUser')
       await router.push('/login')
    },
    updateAuthenticatedUser(context, payload){
        const {user} = payload
        localStorage.removeItem('user')
        localStorage.setItem('user', JSON.stringify(user))
        context.commit('updateAuthenticatedUser', {user})
    }

}