<template>
  <v-container>
    <v-row class="mb-3 d-flex align-center justify-space-between" >
      <v-col md="3">
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar Vidriera"
            single-line
            hide-details
            outlined
            dense
        ></v-text-field>
      </v-col>
      <v-col md="9" class="d-flex justify-end">
        <v-btn class="mr-8" @click="toggleCreateShopWindowDialog" color="info"  outlined>
          <v-icon left>
            mdi-plus
          </v-icon>
          Crear Vidriera
        </v-btn>
      </v-col>

    </v-row>

    <v-data-table
        v-model="selectedShopWindows"
        :headers="tableHeaders"
        :items="shopWindows"
        item-key="_id"
        :search="search"
        :show-select="showSelect"
        sele
        locale="es"
        :items-per-page="5"
        :footer-props="footerProps"
        no-data-text="¡Su cuenta no posee vidrieras!"
        no-results-text="No hay resultados para su búsqueda."
        :loading="loadingShopWindows"
        height="300"
        sort-by="createdAt"
        sort-desc

    >
      <template v-slot:item.image="{ item }" class="d-flex justify-center">
          <v-img v-if="item.image"  :src="item.image.location" alt="asd" class="ma-0 product-image-container"></v-img>
          <span v-else>Sin imagen</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="toggleUpdateShopWindowDialog(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="alertDelete(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>



    <!--Dialogo eliminar Vidriera-->

    <v-dialog
        v-if="deletableShopWindow"
        v-model="toggleAlertDelete"
        max-width="400"
    >
      <v-card>

        <v-card-title class="headline">
          ¿Está seguro que desea eliminar la vidriera {{deletableShopWindow.name.toUpperCase()}}?
        </v-card-title>
        <v-card-subtitle class="text-center pt-6">
          <v-icon x-large class="text-h2">
            mdi-alert
          </v-icon>
        </v-card-subtitle>
        <v-card-text>
          Si elimina la vidriera no podrá recuperar sus datos. Sus campos e imágenes serán eliminados junto a él (Los productos de su catálogo se mantendrán intáctos).
        </v-card-text>

        <v-card-actions>

          <v-btn
              color="green darken-1"
              text
              @click="cancelDelete"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="red darken-1"
              @click="deleteShopWindow"
              outlined
              :loading="loadingResponse"
          >
            <v-icon>
              mdi-delete
            </v-icon>
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--    <CreateShopWindowDialog v-if="userIsLoggedIn && loggedInUser.role!=='admin'" class="create-shop-window-dialog"/>-->
<!--    Dialogo Crear Vidriera-->
    <CreateShopWindowDialog v-on:shopWindowCreated="shopWindowCreated($event)" class="create-shop-window-dialog"/>


<!--    Dialogo actualizar vidriera-->
    <UpdateShopWindowDialog
        v-if="updatableShopWindow"
        :toggle="updateShopWindowDialog"
        :updatableShopWindow="updatableShopWindow"
        v-on:shopWindowUpdated="shopWindowUpdated"
        v-on:closeUpdateShopWindowDialog="updateShopWindowDialog=false"

    />

  </v-container>



</template>

<script>
import httpClient from "@/api/httpClient";
import CreateShopWindowDialog from "@/components/client/shopWindow/CreateShopWindowDialog";
import UpdateShopWindowDialog from "@/components/client/shopWindow/UpdateShopWindowDialog";
export default {
  name: "ShopWindowTable",
  components:{CreateShopWindowDialog, UpdateShopWindowDialog},
  props:['updatableCampaignShopWindows'],
  data(){
    return {
      globalClient:null,
      selectedShopWindows:[],
      search:'',
      showSelect:true,
      tableHeaders: [
        {
          text: 'Imagen',
          sortable: false,
          value: 'image',
        },
        {
          text: 'Vidriera',
          align: 'center',
          sortable: false,
          value: 'name',
        },
        { text: 'Cant. de Productos', value: 'products.length', align: 'center',},
        { text: 'Creada El', value: 'createdAt',  align: 'center',},
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false},
      ],
      loadingShopWindows:false,
      loadingResponse:false,
      shopWindows:[],
      footerProps:{
        "items-per-page-text":'Items por hoja',
        "items-per-page-all-text":'Todas'
      },
      toggleAlertDelete:false,
      deletableShopWindow:null,
      updateShopWindowDialog:false,
      updatableShopWindow:null
    }
  },
  methods:{
    toggleCreateShopWindowDialog(){
      this.$store.dispatch('shopWindow/toggleCreateShopWindowDialog')
    },
    async getMyShopWindows(){

      if (this.loadingShopWindows){
        return
      }

      this.loadingShopWindows = true

      try {
        const response = await httpClient.get(`/shopWindows/myShopWindows/${this.globalClient._id}`)
        if (response.data.status === 'success'){
          this.shopWindows = response.data.shopWindows
          this.shopWindows.map(shopWindow =>{
            return shopWindow.createdAt = new Date(shopWindow.createdAt).toLocaleDateString()
          })
          this.loadingShopWindows = false
        }
      }catch (e) {
        console.log(e)
      }
    },
    shopWindowCreated(){
      this.getMyShopWindows()
    },

    toggleUpdateShopWindowDialog(item){
      this.updatableShopWindow = item
      this.updateShopWindowDialog = true
    },
    shopWindowUpdated(){
      this.updateShopWindowDialog = false
      this.getMyShopWindows()
    },

    async alertDelete(item){
      this.toggleAlertDelete = true
      this.deletableShopWindow = item
    },
    cancelDelete(){
      this.deletableShopWindow = null
      this.toggleAlertDelete = !this.toggleAlertDelete
    },
    async deleteShopWindow(){
      if (this.deletableShopWindow){
        this.loadingResponse = true
        const response = await httpClient.delete(`/shopWindows/myShopWindows/${this.deletableShopWindow._id}`)
        const deletedWindow = response.data.shopWindow
        if (response.data.status === 'success'){
          this.toggleAlertDelete = false
          this.loadingResponse = false
        }
        this.shopWindows = this.shopWindows.filter(window =>{
          return window._id !== deletedWindow._id
        })
      }
    }
  },
  watch:{
    //Limita la cantidad de filas a elegir.
    selectedShopWindows (val, oldVal) {
      if (val.length > 3) {
        this.$nextTick(() => {
          this.selectedShopWindows = oldVal
        })
      }
      this.selectedShopWindows = val
      this.$emit('selectedShopWindowsChanged', val)
    },

    //aca modifico el valor segunvidrieras ya seleccionadas (para actualizar)
    updatableCampaignShopWindows(val){
      this.selectedShopWindows = val
    },
  },
  beforeMount() {
    //aca checkeo si esto recibiendo el prop con vidrieras ya seleccionadas (para actualizar)
    if (this.updatableCampaignShopWindows){
      this.selectedShopWindows = this.updatableCampaignShopWindows
    }
  },
  mounted() {
    this.globalClient = this.$store.getters['account/getGlobalClient']
    this.getMyShopWindows()
  },

}
</script>

<style scoped>
.shop-window-card{
  border-bottom: 1px solid #e2e2e2;
}
.product-image-container{
  border-radius: 50%;
  border: 2px solid #94c3ea;
  width:50px;
  height:50px;
}
</style>