import axios from "axios";

const httpClient = axios.create({
    baseURL:process.env.VUE_APP_API_ENDPOINT,
    timeout:15000,
})

httpClient.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer '+localStorage.getItem('token')

    return config;

})

export default httpClient