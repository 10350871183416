<template>
  <v-row justify="center">

    <v-dialog
        v-model="toggle"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card color="grey lighten-3">
        <v-app-bar
            dark
            color="primary"
            fixed
            height="60"
            elevation="1"
        >
          <v-btn
              icon
              dark
              @click="emitCloseDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Detalle de Campaña : {{campaign.initialConfig.descriptiveName}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
         </v-toolbar-items>
        </v-app-bar>

        <v-card-text class="mt-15 pt-10">
          <div>
            <v-container>
              <v-row>
                <v-col md="8">
                  <!--CONFIG INICIAL-->
                  <v-card class="mb-5 rounded-xl" elevation="1">
                    <v-card-title class="px-8 pb-1">CONFIGURACIÓN INICIAL</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="12" class="d-flex align-center justify-space-around">
                            <div class="d-flex align-start ">
                              <v-icon left color="info lighten-2">mdi-text-short</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Nombre</h3>
                                <span >{{campaign.initialConfig.descriptiveName}}</span>
                              </div>
                            </div>
                            <div class="d-flex align-start ">
                              <v-icon left color="info lighten-2" >mdi-calendar-refresh</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Comienza el</h3>
                                <span  >{{new Date(campaign.initialConfig.startDate).toLocaleDateString()}}</span>
                              </div>
                            </div>
                            <div class="d-flex align-start ">
                              <v-icon left color="info lighten-2">mdi-calendar-check</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Finaliza el</h3>
                                <span  >{{campaign.initialConfig.endDate? new Date(campaign.initialConfig.endDate).toLocaleDateString(): 'No posee'}}</span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>

                    </v-card-text>
                  </v-card>

                  <!--CONFIG AUDIENCIAS-->
                  <v-expansion-panels >
                    <v-expansion-panel class="mb-5 rounded-xl">
                      <v-expansion-panel-header>
                        <v-card-title class="py-0">
<!--                          <v-icon left>-->
<!--                            mdi-account-group-->
<!--                          </v-icon>-->
                          AUDIENCIA
                        </v-card-title>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content >
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col md="3" class="">
                                <div class="d-flex align-start ">
                                  <v-icon left color="info lighten-2">mdi-map</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>País</h3>
                                    <span >{{campaign.audience.country}}</span>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="4">
                                <div class="d-flex align-start ">
                                  <v-icon left color="info lighten-2" >mdi-calendar-refresh</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Rango Etáreo</h3>
                                    <span>De {{campaign.audience.ageRange.minAge}} a {{campaign.audience.ageRange.maxAge}} años</span>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="5">
                                <div class="d-flex align-start ">
                                  <v-icon left color="info lighten-2">mdi-baby-carriage</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Estado Parental</h3>
                                    <span  >{{campaign.audience.parentalState? campaign.audience.parentalState: 'No posee'}}</span>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <div class="d-flex align-start ">
                                  <v-icon left color="info lighten-2">mdi-map-marker</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Regiones</h3>
                                    <div class="d-flex">
                                      <v-chip v-for="region in campaign.audience.regions" :key="region"
                                              outlined
                                              class="mr-2 mt-1"
                                              color="orange"
                                      >
                                        {{region}}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <div class="d-flex align-start ">
                                  <v-icon left color="info lighten-2">mdi-bookmark-multiple</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Intereses</h3>
                                    <div class="d-flex">
                                      <v-chip v-for="interest in campaign.audience.interests" :key="interest"
                                              outlined
                                              class="mr-2 mt-1"
                                              color="orange"
                                      >
                                        {{interest}}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <div class="d-flex align-start ">
                                  <v-icon left color="info lighten-2">mdi-gender-male-female</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Género</h3>
                                    <div class="d-flex">
                                      <v-chip v-for="gender in campaign.audience.gender" :key="gender"
                                              outlined
                                              class="mr-2 mt-1"
                                              :color="gender === 'mujeres'? 'pink':'blue'"
                                              label
                                      >
                                        {{gender}}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <div class="d-flex align-start ">
                                  <div class="d-flex flex-column">
                                    <h3>Información Extra</h3>
                                    <span >{{campaign.audience.extraInfo?campaign.audience.extraInfo:'no posee'}}</span>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>

                        </v-card-text>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!--CONFIG VIDRIERAS-->
                  <v-card v-if="campaign.shopWindows.length>0" class="mb-5 rounded-xl" elevation="1">
                    <div class="d-flex justify-space-between">
                      <v-card-title class="px-8 pb-1">VIDRIERAS</v-card-title>
                    </div>
                    <div class="d-flex align-center justify-space-between pl-8 pr-4">

                      <div class="mr-6">
                        <v-select
                            outlined
                            dense
                            :items="campaign.shopWindows"
                            item-text="title"
                            hide-details
                            label="Vidrieras"
                            full-width
                            v-model="selectedShopWindow"
                            return-object
                        >
                        </v-select>
                      </div>
                      <div class="mr-2 ">
                        <v-btn   outlined color="orange lighten-1" @click="showProducts = !showProducts">
                          <v-icon left>
                            {{!showProducts?'mdi-eye':'mdi-eye-off'}}
                          </v-icon>
                          {{!showProducts?'Mostrar productos':'Ocultar productos'}}
                        </v-btn>
                      </div>
                    </div>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="12" class="d-flex align-center ">
                            <div class="d-flex align-start ">
                              <v-icon left color="info lighten-2">mdi-text-short</v-icon>
                              <div  class="d-flex flex-column">
                                <h3>Título</h3>
                                <span >{{selectedShopWindow.title?selectedShopWindow.title:''}}</span>
                              </div>
                            </div>
                            <div class="d-flex align-start ml-15 ">
                              <v-icon left color="info lighten-2">mdi-cart</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Productos Totales</h3>
                                <span >{{selectedShopWindow.products.length}}</span>
                              </div>

                            </div>
                            <div class="d-flex align-start ml-15">
                              <v-icon left color="info lighten-2" >mdi-calendar-check</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Fue creada el</h3>
                                <span  >{{new Date(selectedShopWindow.createdAt).toLocaleDateString()}}</span>
                              </div>
                            </div>

                          </v-col>
                          <v-expand-transition v-if="showProducts" >
                            <v-col md="12">
                                <div >
                                  <v-data-table
                                      :headers="headers"
                                      :items="selectedShopWindow.products"
                                      loading-text="Cargando ... Por favor aguarde"
                                      class="v-data-table"
                                      height="320"
                                      :items-per-page="5"
                                  >
                                    <template v-slot:item.image="{ item }">
                                      <div class="p-2">
                                        <v-img :src="item['image_link']" :alt="item.title" class="product-image-container" width="50px" height="50px"></v-img>
                                      </div>
                                    </template>

                                  </v-data-table>
                                </div>
                            </v-col>
                          </v-expand-transition>

                          <v-col md="12">
                            <div class="d-flex align-start ">
                              <v-icon left color="info lighten-2">mdi-calendar-check</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Texto Principal</h3>
                                <span  >{{selectedShopWindow.copy}}</span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>

                    </v-card-text>
                  </v-card>

                </v-col>

                <v-col md="4">
                  <!--INFO GENERAL-->

                  <v-card class="mb-5 rounded-xl"  elevation="1">
                    <v-card-title class="px-8 pb-1">INFORMACIÓN GENERAL</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="6" class="">
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="info lighten-2">mdi-target</v-icon>
                              <div>
                                <h3>Objetivo</h3>
                                <span >{{campaign.objective}}</span>
                              </div>
                            </div>
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="info lighten-2">mdi-calendar-check</v-icon>
                              <div>
                                <h3>Estado</h3>
                                <v-chip label small :color="campaign.status === 'activa'?'primary':''">{{campaign.status}}</v-chip>
                              </div>
                            </div>

                          </v-col>
                          <v-col md="6">
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="info lighten-2">mdi-calendar-check</v-icon>
                              <div>
                                <h3>Creada el</h3>
                                <span >{{new Date(campaign.createdAt).toLocaleDateString()}}</span>
                              </div>
                            </div>
                            <div class="d-flex align-start mb-1">
                              <v-icon left color="info lighten-2">mdi-calendar-refresh</v-icon>
                              <div>
                                <h3>Actualizada el</h3>
                                <span >{{new Date(campaign.updatedAt).toLocaleDateString()}}</span>
                              </div>
                            </div>

                          </v-col>
                        </v-row>
                      </v-container>

                    </v-card-text>
                  </v-card>


                  <!-- PRESUPUESTO-->
                  <v-card class="rounded-xl" elevation="1" >
                    <v-card-title class="px-8 pb-1">PRESUPUESTO</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="5">
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="info lighten-2">mdi-cash</v-icon>
                              <div>
                                <h3>Inversión Diaria</h3>
                                <span >{{campaign.budget.dailyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' ' + globalClient.plan.currency}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="7">
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="info lighten-2">mdi-airplane-takeoff</v-icon>
                              <div>
                                <h3>Categoría</h3>
                                <span >{{campaign.budget.title}}</span>
                                <div>
                                  <v-icon small v-for="n in campaign.budget.starCount" :key="n">
                                    mdi-star
                                  </v-icon>
                                </div>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="5">
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="info lighten-2">mdi-cash-multiple</v-icon>
                              <div>
                                <h3>Inversión Mensual</h3>
                                <span >{{ (campaign.budget.dailyPrice*31).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' ' + globalClient.plan.currency}}</span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>

                    </v-card-text>
                  </v-card>

                </v-col>


              </v-row>
            </v-container>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "CampaignDetailDialog",
  props:['toggle', 'campaign', 'globalClient'],
  data(){
    return{
      tab:null,
      selectedShopWindow:null,
      showProducts:false,
      headers: [
        { text: 'Identificador', value: 'id' },
        { text: "Imagen", value: "image", sortable: false },
        { text: 'Producto', align: 'start', value: 'title',},
        { text: 'Precio', value: 'price' },
        { text: 'Disponibilidad', value: 'availability' },
      ],
      products:[],
    }
  },
  methods:{
    emitCloseDialog(){
      this.$emit('closeCampaignDetailDialog', false)
    }
  },
  watch:{
    campaign(val){
      this.selectedShopWindow = val.shopWindows[0]
    }
  },
  beforeMount() {
    this.selectedShopWindow = this.campaign.shopWindows[0]
  },
  mounted() {
    this.selectedShopWindow = this.campaign.shopWindows[0]
  }
}
</script>

<style scoped>
.v-data-table {
  line-height: 1.4 !important;
  border: 1px solid #c4c4c4;
}


.product-image-container{
  border-radius: 50%;
  border: 2px solid #94c3ea;
  margin:5px 0 !important;
}
</style>