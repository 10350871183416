<template>
  <div class="documents">
    <v-container>
      <v-row>
        <v-col md="12">
          <h1 class="subheading text-md-h4">Novedades</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <p >En esta sección encontrarás novedades y noticias acerca de nuestra plataforma.</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col md="4">
          <v-text-field
              append-icon="mdi-magnify"
              label="Buscar por palabra clave"
              single-line
              outlined
              dense
              flat
              hide-details
              solo
              v-model="stringFilter"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4">
          <v-select
              solo
              outlined
              dense
              flat
              label="Filtrar Documentos"
              prepend-inner-icon="mdi-filter-variant"
              :items="tags"
              v-model="selectedFilter"
              clearable
              @change="filterDocuments"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="loadingDocuments" md="12" class="d-flex justify-center mt-15">
          <v-progress-circular
              :indeterminate="loadingDocuments"
              color="primary"
              :size="120"
              :width="4"
          >
            Cargando...
          </v-progress-circular>
        </v-col>
        <v-col md="4" v-for="document in filteredDocuments" :key="document._id">
          <v-card
              class="mx-auto rounded-xl"
          >
            <div v-if="document.tag" class="d-flex justify-end pt-5 pr-5">
              <v-chip
                  color="info lighten-2"
                  label
                  text-color="white"

              >
                <v-icon left small>
                  mdi-tag
                </v-icon>
                {{ document.tag }}
              </v-chip>
            </div>
            <v-card-title class="headline" >{{ document.title }}</v-card-title>
            <v-card-text class="text--primary" >

              <div >{{ document.caption }}</div>
            </v-card-text>




            <v-card-actions class="d-flex justify-space-between px-4">
              <div class="caption">
                Publicado el: {{new Date(document.createdAt).toLocaleDateString()}}
              </div>
              <v-btn
                  color="orange"
                  text
                  @click="toggleArticleSheet(document)"
              >
                Ver Detalle
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ArticleDetail :document="selectedDocument" :sheet="articleSheet" v-on:closeSheet="articleSheet=$event"/>
  </div>
</template>

<script>
import httpClient from "@/api/httpClient";
import ArticleDetail from "@/views/client/ArticleDetail";

export default {
  name: "ClientDocuments",
  data(){
    return{
      documents:[],
      search:'',
      loadingDocuments:false,
      loadingResponse:false,
      articleSheet:false,
      selectedDocument:null,
      tags:['ninguno'],
      selectedFilter:null,
      stringFilter:'',
      filteredDocuments:[]
    }
  },
  components:{
    ArticleDetail
  },
  methods:{
    async getDocuments(){
      this.loadingDocuments = true
      try{
        const response = await httpClient.get(`/helpArticles?articleType=novedad`)
        if (response.data.status === 'success'){
          this.documents = response.data.data
          this.filteredDocuments = response.data.data
          this.tags = this.documents.map(document =>{
            if (document.tag){
              return document.tag
            }else{
              return null
            }
          })
          this.loadingDocuments = false
        }
      }catch (e) {
        console.log(e)
      }
    },
    toggleArticleSheet(document){
      this.selectedDocument = document
      this.articleSheet = !this.articleSheet
    },
    filterDocuments(){
      const filter = this.selectedFilter
      if (!filter){
        return this.filteredDocuments = this.documents
      }
      this.filteredDocuments = this.documents.filter(document=>{
        // return document.tag === filter
        return document.tag.includes(filter) ||  document.tag === filter || document.title.includes(filter)
      })
    },
    searchDocuments(){
      const filter = this.stringFilter.toLowerCase()
      if (!filter){
        return this.filteredDocuments = this.documents
      }
      this.filteredDocuments = this.documents.filter(document=>{
        // return document.tag === filter
        return document.title.toLowerCase().match(filter) ||
            document.caption.toLowerCase().match(filter) ||
            document.tag.toLowerCase().match(filter)
      })
    },
    toggleInitialFormModal(){
      this.$store.dispatch('account/toggleInitialFormDialog')
    },
  },
  watch:{
    selectedFilter(){
      return this.filterDocuments()
    },
    stringFilter(){
      return this.searchDocuments()
    }
  },
  mounted() {
    this.getDocuments()
    this.globalClient = this.$store.getters['account/getGlobalClient']
    if (!this.globalClient.completedInitialData){
      this.$router.push({name:'ClientCampaigns'})
    }
  }
}
</script>

<style scoped>
.headline{
  word-break:break-word;
}
</style>