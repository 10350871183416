
export default {
    signInUser(state, payload){
        const {user} = payload
        state.authenticatedUser = user
    },
    signOutUser(state){
        state.authenticatedUser = null
    },
    updateAuthenticatedUser(state, payload){
        const {user} = payload
        state.authenticatedUser = user
    },

}