<template>
  <v-app>
    <Navbar v-if="userIsLoggedIn && loggedInUser.role!=='admin'"/>
    <AdminNavbar v-if="userIsLoggedIn && loggedInUser.role==='admin'"/>
    <v-main class="v-main-container">
<!--      <v-container fluid>-->
      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>
<!--      </v-container>-->
    </v-main>
    <InitialFormDialog v-if="userIsLoggedIn && loggedInUser.role!=='admin'"  />
  </v-app>
</template>
<script>
import Navbar from '@/components/client/navigation/Navbar'
import InitialFormDialog from "@/components/client/account/InitialFormDialog";
import AdminNavbar from "@/components/admin/navigation/AdminNavbar";


export default {
  name: 'App',

  components: {
    Navbar,
    AdminNavbar,
    // CreateCampaignDialog,
    InitialFormDialog
  },

  data: () => ({
    loggedInUser:null,
    userClients:[],
    globalClient:null
  }),
  methods:{
  },
  computed:{
    userIsLoggedIn(){
      return this.$store.getters.getAuthenticatedUser
    }
  },
  watch:{
    userIsLoggedIn(val){
      this.loggedInUser = val
    }
  },
  mounted() {
    this.$store.dispatch('tryLogin')
  },

};
</script>
<style scoped>
  .v-main-container{
    background-color: #efefef;
    padding-top: 50px !important;
  }
  .create-shop-window-dialog{
    display: none;
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: opacity .20s ease-out;
  }

  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(0px);

    opacity: 0;
  }
</style>