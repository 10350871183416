<template>
  <div class="clientUserProfile">
    <v-container>
      <v-row>
        <v-col md="12">
          <v-card class="mb-2 rounded-xl" outlined elevation="2">
            <v-card-title class="px-8 pb-1 d-flex justify-space-between">
              <div>
                INFORMACIÓN PERSONAL
              </div>
              <div>
                <v-btn icon @click="toggleUpdate">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col md="12" class="d-flex align-center justify-space-around">
                    <div class="d-flex align-start ">
                      <v-icon left large color="grey lighten-1">mdi-text-short</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Nombre</h3>
                        <span style="text-transform: capitalize">{{user.completeName}}</span>
                      </div>
                    </div>
                    <div class="d-flex align-start ">
                      <v-icon left large color="grey lighten-1" >mdi-at</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Email</h3>
                        <span>{{user.email}}</span>
                      </div>
                    </div>
                    <div class="d-flex align-start ">
                      <v-icon left large color="grey lighten-1">mdi-account-key</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Tipo de usuario</h3>
                        <span style="text-transform: capitalize">{{user.role === 'user'? 'Usuario':user.role}}</span>
                      </div>
                    </div>
                    <div class="d-flex align-start ">
                      <v-icon left large color="grey lighten-1">mdi-calendar-check</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Dado de alta el</h3>
                        <span>{{new Date(user.createdAt).toLocaleDateString()}}</span>
                      </div>
                    </div>
                    <div class="d-flex align-start ">
                      <v-icon left large color="grey lighten-1">mdi-calendar-check</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Actualizado el</h3>
                        <span>{{new Date(user.updatedAt).toLocaleDateString()}}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>

          </v-card>

        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-expand-transition>
            <v-card class="rounded-xl" outlined elevation="2" v-if="updatableUser && toggleUpdateFields ">
              <v-card-title class="px-8 pb-1 d-flex justify-space-between">
                <div>
                  ACTUALIZAR DATOS
                </div>

              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form
                      lazy-validation
                      ref="form"
                      v-model="validForm">
                    <v-row>
                      <v-col md="4">
                        <v-text-field
                            v-model="updatableUser.completeName"
                            outlined
                            dense
                            label="Nuevo Nombre"
                            hint="Ingresa un nuevo nombre para tu usuario"
                            persistent-hint
                            prepend-inner-icon="mdi-text-short"
                            :rules="nameRules"

                        />
                      </v-col>
                      <v-col md="4">
                        <v-text-field
                            v-model="updatableUser.email"
                            outlined
                            dense
                            label="Nuevo Email"
                            hint="Ingresa una nueva casilla de email para tu usuario"
                            persistent-hint
                            prepend-inner-icon="mdi-at"
                            :rules="emailRules"

                        />
                      </v-col>
                      <v-col md="4">
                        <v-text-field
                            outlined
                            dense
                            label="Confirmar Nuevo Email"
                            hint="Confirma casilla de email para tu usuario"
                            persistent-hint
                            prepend-inner-icon="mdi-at"
                            :rules="[emailConfirmRules(updatableUser.email)]"
                            v-model="updatableUser.emailConfirm"
                            :disabled="!updatableUser.email"

                        />
                      </v-col>
                      <v-col md="4">
                        <v-text-field
                            v-model="user.role"
                            outlined
                            dense
                            label="Tipo de Usuario"
                            disabled
                            readonly

                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions  class="d-flex justify-space-between pl-5 pr-5 pb-5">
                <v-btn text @click="toggleUpdate">
                  cancelar
                </v-btn>
                <div>
                  <v-btn v-if="user.role !== 'admin'" outlined color="orange" class="mr-5">
                    <v-icon left>mdi-lock-reset</v-icon>
                    Solicitar Cambio de Contraseña
                  </v-btn>
                  <v-btn outlined color="primary" @click="updateUserData" :disabled="!validForm" :loading="loadingDataResponse">
                    <v-icon left>mdi-refresh</v-icon>
                    Actualizar Datos
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-expand-transition>

        </v-col>
      </v-row>


      <v-row>
        <v-col md="12">
          <v-expand-transition>
            <v-card class="rounded-xl" outlined elevation="2" v-if="toggleUpdateFields && user.role === 'admin'">
              <v-card-title class="px-8 pb-1 d-flex justify-space-between">
                <div>
                  SEGURIDAD
                </div>

              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form
                      ref="passwordForm"
                      v-model="validPasswordForm">

                    <v-row >
                      <v-col md="4">
                        <v-text-field
                            v-model="updatableUser.currentPassword"
                            outlined
                            dense
                            label="Contraseña Actual"
                            hint="Ingresa tu contraseña actual"
                            persistent-hint
                            :rules="passwordRules"
                            prepend-inner-icon="mdi-lock"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            :type="showPassword ? 'text' : 'password'"
                        />
                      </v-col>
                      <v-col md="4">
                        <v-text-field
                            v-model="updatableUser.newPassword"
                            outlined
                            dense
                            label="Nueva Contraseña"
                            hint="Ingresa una nueva contraseña"
                            persistent-hint
                            :rules="passwordRules"
                            prepend-inner-icon="mdi-lock"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            :type="showPassword ? 'text' : 'password'"
                        />
                      </v-col>
                      <v-col md="4">
                        <v-text-field
                            v-model="updatableUser.newPasswordConfirm"

                            outlined
                            dense
                            label="Confirmar Nueva Contraseña"
                            hint="Confirma la nueva contraseña"
                            persistent-hint
                            :rules="[passwordConfirmRules(updatableUser.newPassword)]"
                            prepend-inner-icon="mdi-lock"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                            :type="showPassword ? 'text' : 'password'"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions  class="d-flex justify-space-between pl-5 pr-5 pb-5">
                <v-btn text @click="toggleUpdate">
                  cancelar
                </v-btn>
                <div>
                  <v-btn outlined color="primary" @click="updateUserPassword" :disabled="!validPasswordForm" :loading="loadingPasswordResponse">
                    <v-icon left>mdi-lock-reset</v-icon>
                    Actualizar Contraseña
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-expand-transition>
        </v-col>
      </v-row>

      <!--Datos generales de la cuenta-->

      <v-row v-if="user.role !== 'admin'">
        <v-col md="12">
          <v-card class="mb-5 rounded-xl" outlined elevation="2">
            <v-card-title class="px-8 pb-1 d-flex justify-space-between">
              <div>
                INFORMACIÓN DE LA CUENTA
              </div>
              <div v-if="user.role === 'propietario'">
                <v-btn icon @click="toggleClientInfoUpdate">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col md="2" class="d-flex  ">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-briefcase</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Nombre</h3>
                        <span >{{globalClient.name}}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="3">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1" >mdi-account</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Contacto Administrativo</h3>
                        <span  >{{globalClient.administrativeContact}}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="3">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-at</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Email administrativo</h3>
                        <span  >{{globalClient.administrativeContactEmail}}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="4">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-phone</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Teléfono contacto administrativo</h3>
                        <span  >{{globalClient.administrativeContactPhone}}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-divider class="my-6" ></v-divider>

                <v-row >
                  <v-col md="2" class="d-flex align-center">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-map</v-icon>
                      <div class="d-flex flex-column">
                        <h3>País</h3>
                        <span >{{globalClient.country}}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="3">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-identifier</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Razón Social</h3>
                        <span >{{globalClient.corporateName}}</span>

                      </div>
                    </div>
                  </v-col>
                  <v-col md="3">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-identifier</v-icon>
                      <div class="d-flex flex-column">
                        <h3>ID Fiscal</h3>
                        <span >{{globalClient.fiscalId}}</span>

                      </div>
                    </div>
                  </v-col>
                  <v-col md="2">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-calendar-check</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Creada el</h3>
                        <span >{{new Date(globalClient.createdAt).toLocaleDateString()}}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


      <!-- Informacion del negocio-->
      <v-row v-if="user.role !== 'admin'">
        <v-col md="12">
          <v-card class="mb-5 rounded-xl" outlined elevation="2">
            <v-card-title class="px-8 pb-1 d-flex justify-space-between">
              <div>
                INFORMACIÓN DE MI NEGOCIO
              </div>
              <div v-if="user.role === 'propietario'">
                <v-btn icon @click="toggleClientBusinessInfoUpdate">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col md="3">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-filter-variant</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Rubro</h3>
                        <span >
                            {{globalClient.businessInfo.workArea}}
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="3" class="d-flex  ">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-cart</v-icon>
                      <div class="d-flex flex-column">
                        <h3>URL Tienda Online</h3>
                        <span >
                          <a :href="globalClient.businessInfo.shopURL" target="_blank">
                            Ir a la tienda
                          </a>
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="3">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1" >mdi-instagram</v-icon>
                      <div class="d-flex flex-column">
                        <h3>URL Perfíl Instagram</h3>
                        <span >
                          <a :href="globalClient.businessInfo.instagramURL" target="_blank">
                            Ir al perfíl
                          </a>
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="3">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-facebook</v-icon>
                      <div class="d-flex flex-column">
                        <h3>URL Perfíl Facebook</h3>
                        <span >
                          <a :href="globalClient.businessInfo.facebookURL" target="_blank">
                            Ir al perfíl
                          </a>
                        </span>
                      </div>
                    </div>
                  </v-col>

                </v-row>

                <v-divider class="my-6" ></v-divider>

                <v-row >
                  <v-col md="3" class="d-flex align-center">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-cloud-tags</v-icon>
                      <div class="d-flex flex-column">
                        <h3>XML Productos</h3>
                        <span >
                          <a :href="globalClient.businessInfo.xmlURL" target="_blank">
                            Ir al archivo XML
                          </a>
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="4" class="d-flex align-center">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-domain</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Competencia</h3>
                        <span >
                          <v-chip class="mr-2 mb-1" small color="orange" outlined label v-for="competitor in globalClient.businessInfo.competition" :key="competitor">
                            {{competitor}}
                          </v-chip>
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="5">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-information-outline</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Información destacada</h3>
                        <span>
                          <v-chip class="mr-2 mb-1" small color="orange" outlined label v-for="info in globalClient.businessInfo.featuredInfo" :key="info">
                            {{info}}
                          </v-chip>
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col md="12">
                    <div class="d-flex align-start ">
                      <v-icon large left color="grey lighten-1">mdi-text</v-icon>
                      <div class="d-flex flex-column">
                        <h3>Descripción del negocio</h3>
                        <span>
                          <span>
                            {{globalClient.businessInfo.description}}
                          </span>
                        </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

<!--    Snackbar mensajes response-->
    <v-snackbar
        v-model="snackbar"
        bottom
        class="my-10"
        timeout="8000"
        :color="this.errorMessage?'':'primary'"
    >
      <v-icon left>{{ errorMessage?'mdi-alert':'mdi-check' }}</v-icon>

      <strong>
        {{ errorMessage?errorMessage:successMessage }}

      </strong>

      <template v-slot:action="{ attrs }">
        <v-btn
            :color="errorMessage?'red':'white'"
            text
            v-bind="attrs"
            @click="snackbar = false"

        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

<!--    Dialogo actualizar cliente (info de la cuenta)-->
    <v-dialog
        v-model="clientUpdateDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Actualizar Información de la cuenta : {{ globalClient.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col md="6">
                  <v-text-field
                      label="Nombre del Negocio"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-text-short"
                      dense
                      hint="¿Cómo se llama tu negocio?"
                      persistent-hint
                      v-model="newClientInfo.name"
                      class="mb-4"

                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                      label="Razón Social"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-card-account-details-outline"
                      dense
                      hint="¿Cuál es la razón social de tu negocio?"
                      persistent-hint
                      v-model="newClientInfo.corporateName"
                      class="mb-4"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-text-field
                      label="ID fiscal"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-identifier"
                      dense
                      hint="Nº de identificación fiscal o tributaria (CUIT, RFC, RUT, Otro...)"
                      persistent-hint
                      v-model="newClientInfo.fiscalId"
                      class="mb-4"
                  />
                </v-col>
                <v-col md="6">
                  <v-autocomplete
                      v-model="newClientInfo.country"
                      :items="countries"
                      outlined
                      label="País"
                      prepend-inner-icon="mdi-map-marker"
                      hint="¿En qué país se encuentra? Escriba para buscar en la lista."
                      persistent-hint
                      dense
                      class="mb-4"

                  />
                </v-col>

              </v-row>
              <v-divider class=" mb-10"></v-divider>
              <v-row>
                <v-col md="6">
                  <v-text-field
                      label="Nombre del contacto administrativo"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-account"
                      dense
                      hint="¿Con quién nos contactaremos?"
                      persistent-hint
                      v-model="newClientInfo.administrativeContact"
                      class="mb-4"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                      label="Email del contacto administrativo"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-at"
                      dense
                      hint="¿A qué casilla nos contactaremos? "
                      persistent-hint
                      v-model="newClientInfo.administrativeContactEmail"
                      class="mb-4"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-text-field
                      label="Teléfono del contacto administrativo"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-phone"
                      dense
                      hint="¿A que número telefónico nos contactaremos?"
                      persistent-hint
                      v-model="newClientInfo.administrativeContactPhone"
                      class="mb-4"
                  />
                </v-col>
              </v-row>



            </v-container>

          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn
              text
              @click="toggleClientInfoUpdate"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="primary"
              :loading="loadingDataResponse"
              @click="updateClientInfo"
          >
            <v-icon left>
              mdi-content-save
            </v-icon>
            Guardar cambios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Dialogo actualizar datos del negocio-->
    <v-dialog
        v-model="clientBusinessInfoUpdateDialog"
        persistent
        max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Actualizar Información de la cuenta : {{ globalClient.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col md="6">
                  <v-text-field
                      label="URL Tienda Online"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-cart"
                      dense
                      hint="Carga la URL de tu tienda online."
                      persistent-hint
                      v-model="newClientInfo.businessInfo.shopURL"
                      class="mb-4"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                      label="URL Página de Facebook"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-facebook"
                      dense
                      hint="Carga la URL de tu página de Facebook."
                      persistent-hint
                      v-model="newClientInfo.businessInfo.facebookURL"
                      class="mb-4"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <v-text-field
                      label="URL Perfíl de Instagram"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-instagram"
                      dense
                      hint="Carga la URL de tu perfil de Instagram."
                      persistent-hint
                      v-model="newClientInfo.businessInfo.instagramURL"
                      class="mb-4"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                      class="mb-6"
                      label="URL XML de la tienda online"
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-cloud-tags"
                      dense
                      hint="Cargá la URL del XML generado por tu tienda online."
                      persistent-hint
                      v-model="newClientInfo.businessInfo.xmlURL"
                  />
                </v-col>
              </v-row>
              <v-divider class="mb-10"></v-divider>
              <v-row>
                <v-col md="6">
                  <v-autocomplete
                      :items="workAreas"
                      label="Rubro"
                      outlined
                      prepend-inner-icon="mdi-store-outline"
                      dense
                      class="mb-4"
                      hint="Elija el rubro al que pertenezca el negocio."
                      persistent-hint
                      v-model="newClientInfo.businessInfo.workArea"
                  ></v-autocomplete>

                  <v-combobox
                      v-model="newClientInfo.businessInfo.featuredInfo"
                      hide-selected
                      hint="Agrega información que creas que pueda destacar a tu negocio (cuotas sin interés, envío gratis, retiro por local, etc.)"
                      label="Información Destacada"
                      multiple
                      persistent-hint
                      prepend-inner-icon="mdi-information"
                      small-chips
                      outlined
                      deletable-chips
                      class="mb-4"

                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Presiona enter <kbd>enter</kbd> para agregar uno nuevo
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>


                  <v-combobox
                      v-model="newClientInfo.businessInfo.competition"
                      hide-selected
                      hint="Agrega hasta 10 nombres de competidores."
                      label="Tu competencia"
                      multiple
                      persistent-hint
                      prepend-inner-icon="mdi-compare-horizontal"
                      small-chips
                      outlined
                      :counter="10"
                      deletable-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Presiona enter <kbd>enter</kbd> para agregar uno nuevo
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col md="6">
                  <v-textarea
                      outlined
                      dense
                      label="Descripción de tu negocio"
                      prepend-inner-icon="mdi-text"
                      v-model="newClientInfo.businessInfo.description"
                      height="300"
                  />
                </v-col>
              </v-row>



            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn
              text
              @click="toggleClientBusinessInfoUpdate"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="primary"
              :loading="loadingDataResponse"
              @click="updateClientBusinessInfo"
          >
            <v-icon left>
              mdi-content-save
            </v-icon>
            Guardar cambios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>


</template>

<script>

import httpClient from "@/api/httpClient";
import countryList from "@/utils/countryList";
import clientAPI from "@/api/clients.api";
export default {
  name: "ClientProfile",
  data(){
    return{
      user:null,
      globalClient:null,
      toggleUpdateFields:false,
      updatableUser:null,
      updatableClient:null,
      clientUpdateDialog:false,
      clientBusinessInfoUpdateDialog:false,
      showPassword:false,
      emailConfirm:'',
      nameRules:[
        v => (v && v.length <= 30) || 'El nombre debe tener menos de 30 caracteres.',
      ],
      emailRules:[
        v => /.+@.+\..+/.test(v) || 'El e-mail debe tener un formato válido.',

      ],
      passwordRules:[
        v => (v && v.length >= 8) || 'La contraseña debe tener al menos 8 caracteres.',
      ],
      emailConfirmRules(email){
        return v =>  v === email || 'Las casillas de correo deben coincidir.'
      },
      passwordConfirmRules(password){
        return v => v && v === password || 'Las contraseñas deben coincidir.'
      },
      validForm:false,
      validPasswordForm:false,
      snackbar:false,
      errorMessage:null,
      successMessage:null,
      loadingDataResponse:false,
      loadingPasswordResponse:false,
      newClientInfo:{
        name:'',
        administrativeContact:'',
        administrativeContactEmail:'',
        administrativeContactPhone:'',
        country:'',
        fiscalId:''
      },
      countries: countryList(),
      workAreas:['Accesorios y Bijouterie', 'Alimentos y Bebidas', 'Anteojos', 'Antigüedades', 'Arte', 'Automóviles y Motos', 'Autopartes y Repuestos', 'Bicicletas', 'Bikinis y Trajes de Baño', 'Blanquería', 'Calzado', 'Carteras/Bolsos/Mochilas', 'Casa y Jardín', 'Colchones y Sommiers', 'Computación', 'Cosmética y Belleza', 'Cotillón y Artículos para fiestas', 'Cursos y Talleres', 'Decoración y Bazar', 'Deportes y Tiempo Libre', 'Electro y Tecnología', 'Flores y Plantas', 'Herramientas y Ferretería', 'Iluminación y Artículos de electricidad', 'Indumentaria para adultos', 'Indumentaria para bebés y chicos', 'Indumentaria y Accesorios para futuras mamás', 'Instrumentos musicales', 'Juguetes/Juegos/Hobbies',
        'Lencería y Ropa Interior', 'Librería e Insumos para oficinas', 'Libros y Revistas', 'Mascotas', 'Materiales de construcción', 'Muebles', 'Regalos y Objetos', 'Relojes', 'Telas y Mercería', 'Viajes y Turismo', 'Otro'],
    }
  },
  methods:{
    toggleUpdate(){
      this.toggleUpdateFields = !this.toggleUpdateFields
    },
    toggleClientInfoUpdate(){
      this.clientUpdateDialog = !this.clientUpdateDialog
    },
    toggleClientBusinessInfoUpdate(){
      this.clientBusinessInfoUpdateDialog = !this.clientBusinessInfoUpdateDialog
    },
    async updateUserData(){
      this.loadingDataResponse = true
      if (this.validForm){
        try{
          const newData = {
            userId:this.user._id,
            completeName:this.updatableUser.completeName,
            email:this.updatableUser.email,
            emailConfirm:this.updatableUser.emailConfirm
          }

          const response = await httpClient.patch(`/users/updateMyData`,
            newData
          )
          if (response.data.status === 'success'){
            this.successMessage = 'Usuario actualizado con éxito.'
            this.errorMessage = null
            this.snackbar = true

            this.user = response.data.user
            this.toggleUpdate()
            await this.$store.dispatch('updateAuthenticatedUser', {user:this.user})
            this.loadingDataResponse = false
          }
        }catch (e) {
          if (e.response.data.status === 'fail'){
            this.errorMessage = e.response.data.message
            this.snackbar = true
            this.loadingDataResponse=false

          }
        }
      }
    },
    async updateUserPassword(){
      this.loadingPasswordResponse = true

      if (this.validPasswordForm){
        try{
          const data = {
            currentPassword:this.updatableUser.currentPassword,
            newPassword:this.updatableUser.newPassword,
            newPasswordConfirm:this.updatableUser.newPasswordConfirm,

          }

          const response = await httpClient.patch(`/users/updateMyPassword`, data)
          if (response.data.status === 'success'){
            this.successMessage = 'Contraseña actualizada con éxito.'
            this.errorMessage = null
            this.snackbar = true

            this.updatableUser.currentPassword = null
            this.updatableUser.newPassword = null
            this.updatableUser.newPasswordConfirm = null
            this.loadingPasswordResponse = false

            this.toggleUpdate()
          }
        }catch (e) {
          if (e.response.data.status === 'fail'){
            this.errorMessage = e.response.data.message
            this.snackbar = true
            this.loadingPasswordResponse = false


          }
        }
      }
    },
    async updateClientInfo(){
      try{
        this.loadingDataResponse = true
        const response = await httpClient.patch(`clients/myClients/${this.globalClient._id}`,{
          name:this.newClientInfo.name,
          corporateName:this.newClientInfo.corporateName,
          fiscalId:this.newClientInfo.fiscalId,
          country:this.newClientInfo.country,
          administrativeContact:this.newClientInfo.administrativeContact,
          administrativeContactEmail:this.newClientInfo.administrativeContactEmail,
          administrativeContactPhone:this.newClientInfo.administrativeContactPhone
        })

        if (response.data.status === 'success'){
          const updatedClient = response.data.client

          this.successMessage = 'Información actualizada con éxito.'
          this.errorMessage = null
          this.snackbar = true
          this.clientUpdateDialog = false

          this.loadingDataResponse = false
          await this.$store.dispatch('account/updateGlobalClient', updatedClient )

        }
      }catch (e) {
        if (e.response.data.status === 'fail'){
          this.errorMessage = e.response.data.message
          this.snackbar = true
          this.loadingDataResponse=false

        }
      }

    },
    async updateClientBusinessInfo(){
      try{
        this.loadingDataResponse = true
        const response = await httpClient.patch(`clients/myClients/${this.globalClient._id}`, {
          businessInfo:{
            shopURL:this.newClientInfo.businessInfo.shopURL,
            facebookURL:this.newClientInfo.businessInfo.facebookURL,
            instagramURL:this.newClientInfo.businessInfo.instagramURL,
            workArea:this.newClientInfo.businessInfo.workArea,
            featuredInfo:this.newClientInfo.businessInfo.featuredInfo,
            competition:this.newClientInfo.businessInfo.competition,
            xmlURL:this.newClientInfo.businessInfo.xmlURL,
            description:this.newClientInfo.businessInfo.description,
          }
        })

        if (response.data.status === 'success'){
          const updatedClient = response.data.client

          this.successMessage = 'Información actualizada con éxito.'
          this.errorMessage = null
          this.snackbar = true
          this.clientBusinessInfoUpdateDialog = false

          this.loadingDataResponse = false

          if (this.newClientInfo.businessInfo.xmlURL!==''){
            await this.updateProducts()
          }
          await this.$store.dispatch('account/updateGlobalClient', updatedClient )

        }
      }catch (e) {
        if (e.response.data.status === 'fail'){
          this.errorMessage = e.response.data.message
          this.snackbar = true
          this.loadingDataResponse=false

        }
      }

    },
    async updateProducts(){
      try{
        this.loadingProducts = true

        const response = await clientAPI.updateMyProducts(this.globalClient._id)
        if(response.data.status==='success'){
          this.enabledProductsUpdate = false

          await this.$store.dispatch('account/setProductUpdateTimestamp', response.data.lastProductsUpdate)
          this.lastProductsUpdate = response.data.lastProductsUpdate
          this.loadingProducts = false

        }
      }catch (e) {
        this.loadingProducts = false
         if(e.response.status === 400 && e.response.data.status === 'fail'){
          this.errorMessage = 'Los datos han sido actualizados de forma correcta. ' + e.response.data.message
          this.snackbar = true
          this.loadingDataResponse=false
        }else if (e.response.status !== 400 &&e.response.data.status === 'fail'){
          this.errorMessage = e.response.data.message
          this.snackbar = true
          this.loadingDataResponse=false

        }
      }
    },
  },
  // watch:{
  //
  // },
  beforeMount() {
    this.user = this.$store.getters['getAuthenticatedUser']
    this.globalClient = this.$store.getters['account/getGlobalClient']
    this.updatableUser = {
      userId: this.user._id,
      completeName: this.user.completeName
    }

    this.newClientInfo = {... this.globalClient}
  }
}
</script>

<style scoped>

</style>