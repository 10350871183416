<template>
  <div class="articles">
    <v-container>
      <v-row>
        <v-col md="12">
          <h1 class="subheading text-md-h4">Documentos y Novedades</h1>
        </v-col>
      </v-row>

    </v-container>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-card max-height="500" class="rounded-xl">
            <v-card-title class="d-flex justify-space-between">
              <v-col md="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <v-btn
                    color="info"
                    outlined
                    @click="dialog = !dialog"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Crear documento o novedad
                </v-btn>
              </v-col>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="articles"
                :search="search"
                :loading="loadingArticles"
                loading-text="Cargando ... Por favor aguarde"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="toggleUpdateArticle(item)"
                >
                  mdi-pencil
                </v-icon>
                <!--                <v-icon-->
                <!--                    small-->
                <!--                    @click="deleteClient(item)"-->
                <!--                >-->
                <!--                  mdi-delete-->
                <!--                </v-icon> -->
                <v-icon
                    small
                    @click="alertDelete(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

    </v-container>



    <!--Dialogo Crear Articulo o Novedad-->
    <v-dialog
        v-model="dialog"
        persistent
        max-width="1100"
    >
      <v-card>
        <v-progress-linear
            :active="loadingResponse"
            :indeterminate="loadingResponse"
            absolute
            top
            height="10"
            striped
            background-color="grey"
            color="primary accent-4"
            class="loadingBar"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">
            <v-icon>
              mdi-briefcase-plus
            </v-icon>
            Crear Novedad o Documento

          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                id="articleForm"
                ref="form"
                v-model="validForm"
                enctype="multipart/form-data"
            >
              <v-row class="d-flex justify-center">
                <v-col cols="6">
                  <v-select
                      v-model="newArticleInfo.articleType"
                      :items="['novedad','ayuda']"
                      label="Tipo de Articulo"
                      required
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-btn-toggle
                      v-model="newArticleInfo.active"
                      dense
                      color="primary accent-4"
                      mandatory

                  >
                    <v-btn value="inactivo" text >
                      <v-icon left>
                        mdi-eye-off
                      </v-icon>
                      <span >No Mostrar</span>


                    </v-btn>

                    <v-btn value="activo" text  >
                      <span class="hidden-sm-and-down">Mostrar</span>

                      <v-icon right >
                        mdi-eye
                      </v-icon>
                    </v-btn>

                  </v-btn-toggle>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="newArticleInfo.title"
                      label="Nombre del Articulo *"
                      required
                      outlined
                      dense
                      :rules="nameRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                      v-model="newArticleInfo.tag"
                      label="Etiqueta del Articulo *"
                      required
                      outlined
                      dense
                      :rules="tagRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                      v-model="newArticleInfo.caption"
                      label="Bajada del Articulo *"
                      required
                      outlined
                      dense
                      :rules="captionRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <div>
                    <h4>
                      Descripción
                    </h4>
                  </div>

                  <template>
                    <div class="editor">
                      <tiptap-vuetify
                          v-model="newArticleInfo.description"
                          :extensions="extensions"
                          :card-props="{flat: true, outlined:true, minHeight:300}"
                          placeholder="Información del artículo"
                      />
                    </div>
                  </template>


                </v-col>
                <v-col cols="5">
                  <v-file-input
                      v-model="newArticleInfo.file"
                      accept=".pdf, .doc, .docx"
                      label="Documento"
                      outlined
                      counter
                      show-size
                      prepend-icon=""
                      prepend-inner-icon="mdi-file"
                      @change="appendFile"
                      dense
                  ></v-file-input>
                </v-col>
                <v-col cols="5">
                  <v-file-input
                      v-model="newArticleInfo.image"
                      accept="image/*"
                      label="Imagen"
                      outlined
                      counter
                      show-size
                      prepend-icon=""
                      prepend-inner-icon="mdi-file-image"
                      @change="appendImage"
                      dense
                  ></v-file-input>
                </v-col>
              </v-row>
              <p>Los campos con * son obligatorios</p>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="createArticle"
              :loading="loadingResponse"
          >
            Crear Articulo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialogo actualizar Articulo-->

    <v-dialog
        v-model="toggleUpdateArticleDialog"
        persistent
        max-width="1100"
        v-if="currentArticle"
    >
      <v-card>
        <v-progress-linear
            :active="loadingResponse"
            :indeterminate="loadingResponse"
            absolute
            top
            height="10"
            striped
            background-color="grey"
            color="primary accent-4"
            class="loadingBar"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">
            <v-icon>
              mdi-pencil
            </v-icon>
            Actualizar Novedad o Documento

          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                id="updateArticleForm"
                ref="form"
                v-model="validForm"
                enctype="multipart/form-data"
            >
              <v-row class="d-flex justify-center">
                <v-col cols="6">
                  <v-select
                      v-model="currentArticle.articleType"
                      :items="['novedad','ayuda']"
                      label="Tipo de Articulo"
                      required
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-btn-toggle
                      v-model="currentArticle.active"
                      dense
                      color="primary accent-4"
                      mandatory
                  >
                    <v-btn value="inactivo" text>
                      <v-icon left>
                        mdi-eye-off
                      </v-icon>
                      <span >No Mostrar</span>


                    </v-btn>

                    <v-btn value="activo" text >
                      <span class="hidden-sm-and-down">Mostrar</span>

                      <v-icon right >
                        mdi-eye
                      </v-icon>
                    </v-btn>

                  </v-btn-toggle>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="currentArticle.title"
                      label="Nombre del Articulo *"
                      required
                      outlined
                      dense
                      :rules="nameRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                      v-model="currentArticle.tag"
                      label="Etiqueta del Articulo *"
                      required
                      outlined
                      dense
                      :rules="tagRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                      v-model="currentArticle.caption"
                      label="Bajada del Articulo *"
                      required
                      outlined
                      dense
                      :rules="nameRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <template>
                    <div class="editor">
                      <tiptap-vuetify
                          v-model="currentArticle.description"
                          :extensions="extensions"
                          :card-props="{flat: true, outlined:true, minHeight:300}"
                          placeholder="Información del artículo"
                      />
                    </div>
                  </template>
                </v-col>
                <v-col cols="5">
                  <div v-if="currentArticle.file" class="old-file-container mb-4 px-3 py-1">
                    <h4>Archivo Actual:</h4>
                    <div  class="d-flex justify-space-between align-center">
                      <a  :href="currentArticle.file.location" >{{currentArticle.file.name}}</a>
                      <v-btn icon @click="deleteFile" >
                        <v-icon  color="red lighten-2">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>

                  <v-file-input
                      accept=".pdf, .doc, .docx"
                      label="Documento"
                      outlined
                      counter
                      show-size
                      prepend-icon=""
                      prepend-inner-icon="mdi-file"
                      @change="appendFile"
                      dense
                  ></v-file-input>
                </v-col>
                <v-col cols="5">
                  <div v-if="currentArticle.image" class="old-file-container mb-4 px-3 py-1">
                    <h4>Imagen Actual:</h4>
                    <div class="d-flex justify-space-between align-center">
                      <a :href="currentArticle.image.location" >{{currentArticle.image.name}}</a>
                      <v-btn icon @click="deleteImage">
                        <v-icon  color="red lighten-2">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>

                  <v-file-input
                      accept="image/*"
                      label="Imagen"
                      outlined
                      counter
                      show-size
                      prepend-icon=""
                      prepend-inner-icon="mdi-file-image"
                      @change="appendImage"
                      dense
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="toggleUpdateArticleDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="updateArticle"
              :loading="loadingResponse"
          >
            Guardar Cambios
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!--Dialogo eliminar Articulo-->

    <v-dialog
        v-if="deletableArticle"
        v-model="toggleAlertDelete"
        max-width="400"
    >
      <v-card>

        <v-card-title class="headline">
          ¿Está seguro que desea eliminar el articulo {{deletableArticle.title.toUpperCase()}}?
        </v-card-title>
        <v-card-subtitle class="text-center pt-6">
          <v-icon x-large class="text-h2">
            mdi-alert
          </v-icon>
        </v-card-subtitle>
        <v-card-text>
          Si elimina el articulo no podrá recuperar sus datos. Sus archivos e imágenes serán eliminados junto a él.
        </v-card-text>

        <v-card-actions>

          <v-btn
              color="green darken-1"
              text
              @click="cancelDelete"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="red darken-1"
              @click="deleteArticle"
              outlined
          >
            <v-icon>
              mdi-delete
            </v-icon>
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from "@/api/httpClient";
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

export default {
  name: "AdminArticles",
  components: {
    TiptapVuetify
  },
  data(){
    return{
      search:'',
      articles:[],
      loadingArticles:false,
      loadingResponse: false,
      headers: [
        { text: 'ID', value: '_id', sortable:false },
        { text: 'Tipo', value: 'articleType' },
        {text:  'Título', align: 'start', value: 'title'},
        { text: 'Estado', value: 'active' },
        { text: 'Creado el', value: 'createdAt' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      dialog:false,
      validForm:false,
      nameRules:[
        v => !!v || 'Debe ingresar un nombre.',
        v => (v && v.length <= 60) || 'El nombre debe tener menos de 30 caracteres.',
      ],
      tagRules:[
        v => !!v || 'Debe ingresar una etiqueta de filtro.',
        v => (v && v.length <= 30) || 'El nombre debe tener menos de 30 caracteres.',
      ],
      captionRules:[
        v => !!v || 'Debe ingresar una bajada.',
        v => (v && v.length <= 100) || 'El nombre debe tener menos de 30 caracteres.',
      ],
      emailRules:[
        v => !!v || 'Debe ingresar un email.',

      ],
      phoneRules:[
        v => !!v || 'Debe ingresar un teléfono.',

      ],
      newArticleInfo:{
        articleType:'',
        title:'',
        caption:'',
        description:'',
        image:null,
        file:null,
        active:'inactivo',
        tag:''
      },
      toggleUpdateArticleDialog:false,
      updatableArticle:null,
      currentArticle:null,
      toggleAlertDelete:false,
      deletableArticle:null,
      file:null,
      image:null,
      extensions: [
        History,
        Blockquote,
        Link,
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3,4,5,6]
          }
        }],
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],

    }
  },
  methods:{
    validateForm() {
      this.$refs.form.validate()
    },
    appendFile(e){
      this.newArticleInfo.file = e
      if (this.currentArticle){
        this.file = e

      }
    },
    appendImage(e){
      this.newArticleInfo.image = e
      if (this.currentArticle){
        this.image = e
      }
    },
    async deleteFile(){
      const response = this.$store.dispatch('helpArticle/deleteAsset', {article:this.currentArticle, fileType:'file'})
      response.then(data =>{
        if (data.data.status==='success'){
          this.currentArticle.file=null

        }
      })


    },
    async deleteImage(){

      const response = this.$store.dispatch('helpArticle/deleteAsset', {article:this.currentArticle, fileType:'image'})
      response.then(data =>{
        if (data.data.status==='success'){
          this.currentArticle.image=null
        }
      })



    },
    async getAdminArticles(){
      this.loadingArticles = !this.loadingArticles
      const response = await httpClient.get(`/helpArticles`)
      if (response.data.status ==='success'){
        this.articles = response.data.data
        this.loadingArticles = false
      }
    },

    async createArticle(){
      this.validateForm()
      this.loadingResponse = true
      const {active, tag, articleType, title, caption, description, image, file} = this.newArticleInfo

      const formData = new FormData()
      formData.append('active', active)
      formData.append('tag', tag)
      formData.append('articleType', articleType)
      formData.append('title', title)
      formData.append('caption', caption)
      formData.append('description', description)
      if (this.newArticleInfo.image){
        formData.append('image', image, image.name)
      }
      if (this.newArticleInfo.file){
        formData.append('file', file, file.name)

      }

      if (this.validForm){
        const response = await httpClient.post(`/helpArticles`, formData)
        if (response.data.status === 'success'){
          this.articles.push(response.data.data)
          this.loadingResponse = false
          this.dialog=false
        }

      }

    },

    toggleUpdateArticle(item){
      this.toggleUpdateArticleDialog = !this.toggleUpdateArticleDialog
      this.currentArticle = item

    },

    async updateArticle(){
      this.loadingResponse = true
      const {active, articleType, title, caption, description} = this.currentArticle

      const image = this.image
      const file = this.file
      const formData = new FormData()
      formData.append('active', active)
      formData.append('articleType', articleType)
      formData.append('title', title)
      formData.append('caption', caption)
      formData.append('description', description)
      if (this.image){
        formData.append('image', image, image.name)
      }
      if (this.file){
        formData.append('file', file, file.name)
      }

      const response = await httpClient.patch(`/helpArticles/${this.currentArticle._id}`,formData)
      if(response.data.status === 'success'){
        if (this.image){
          this.currentArticle.image = image
        }
        if (this.file){
          this.currentArticle.file = file
        }
        this.toggleUpdateArticleDialog = false
        this.loadingResponse = false
        this.currentArticle = null
      }
    },

    async alertDelete(item){
      this.toggleAlertDelete = true
      this.deletableArticle = item
    },
    cancelDelete(){
      this.deletableArticle = null
      this.toggleAlertDelete = !this.toggleAlertDelete
    },
    async deleteArticle(){
      if (this.deletableArticle){
        const response = await httpClient.delete(`/helpArticles/${this.deletableArticle._id}`)
        const deletedArticle = response.data.data
        if (response.data.status === 'success'){
          this.toggleAlertDelete = false
        }
        this.articles = this.articles.filter(article =>{
          return article._id !== deletedArticle._id
        })
      }
    }
  },
  mounted() {
    this.getAdminArticles()
  },
}
</script>

<style scoped>
  .loadingBar{
    position: fixed !important;
  }
  .old-file-container{
    border: 1px solid #acacac;
    border-radius: 5px;
  }
</style>