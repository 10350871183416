export default {
    completedInitialForm(state){
        return state.completedInitialForm
    },
    getInitialFormDialogToggleState(state){
        return state.initialFormDialog
    },
    getGlobalClient(state){
        return state.globalClient
    },
    getLastProductsUpdate(state){
        return state.lastProductsUpdate
    }
}