<template>
  <div class="clients">
    <v-container>
      <v-row>
        <v-col md="12">
          <h1 class="subheading text-md-h4">Clientes</h1>
        </v-col>
      </v-row>

    </v-container>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-card class="rounded-xl">
            <v-card-title class="d-flex justify-space-between">
              <v-col md="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col md="2" class="mr-4">
                <v-btn
                    color="info"
                    outlined
                    @click="dialog = !dialog"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Crear Cliente
                </v-btn>
              </v-col>

            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="clients"
                :search="search"
                :loading="loadingClients"
                loading-text="Cargando ... Por favor aguarde"
                height="370"
                calculate-widths

            >

              <template v-slot:item.actions="{ item }" >
                <v-icon
                    small
                    class="mr-5"
                    @click="toggleDetailDialog(item)"
                    color="primary"

                >
                  mdi-eye
                </v-icon>
                <v-icon
                    small
                    class="mr-5"

                    @click="toggleUpdateClient(item)"
                    color="info lighten-1"

                >
                  mdi-pencil
                </v-icon>
<!--                <v-icon-->
<!--                    small-->
<!--                    @click="deleteClient(item)"-->
<!--                >-->
<!--                  mdi-delete-->
<!--                </v-icon> -->
                <v-icon
                    small
                    @click="alertDelete(item)"
                    color="red lighten-1"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>



<!--Dialogo Crear Cliente-->
    <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            <v-icon>
              mdi-briefcase-plus
            </v-icon>
            Crear Cliente

          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="validForm">
              <v-row class="d-flex justify-center">
                <v-col cols="10">
                  <v-text-field
                      v-model="clientName"
                      label="Nombre del Cliente *"
                      required
                      outlined
                      :rules="nameRules"
                      prepend-inner-icon="mdi-text"

                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                      v-model="administrativeContact"
                      label="Nombre del contacto administrativo *"
                      required
                      outlined
                      :rules="nameRules"
                      prepend-inner-icon="mdi-account"

                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                      v-model="administrativeContactEmail"
                      label="Email del contacto administrativo *"
                      required
                      outlined
                      hint="Este email será utilizado para crear un usuario del tipo 'Propietario de cuenta' automáticamente."
                      persistent-hint
                      :rules="emailRules"
                      prepend-inner-icon="mdi-at"

                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                      v-model="administrativeContactPhone"
                      label="Teléfono del contacto administrativo *"
                      required
                      outlined
                      :rules="phoneRules"
                      prepend-inner-icon="mdi-phone"

                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                      v-model="password"
                      label="Contraseña *"
                      required
                      outlined
                      :rules="passwordRules"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"

                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                      v-model="passwordConfirm"
                      label="Confirmar Contraseña *"
                      required
                      outlined
                      :rules="[passwordConfirmRules(password)]"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <p>Los campos con * son obligatorios</p>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="createClient"
              :loading="loadingResponse"
              :disabled="!validForm"
          >
            Crear Cliente
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--Dialogo actualizar Cliente-->

    <v-dialog
        v-model="toggleUpdateClientDialog"
        persistent
        max-width="800px"
        v-if="updatableClient"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Actualizar Cliente : {{ updatableClient.name.toUpperCase() }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="d-flex justify-space-between">

              <v-col class="d-flex" md="3">
                <v-switch
                    v-model="updatableClient.status"
                    label="Activo"
                    color="success"
                    :value="!updatableClient.status"
                    hide-details
                    inset
                ></v-switch>
              </v-col>
              <v-col md="6">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="updatableClient.subscriptionEndDate"
                        label="Fecha de baja"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      width="400" v-model="updatableClient.subscriptionEndDate" locale="es"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="12"
                  md="12"
              >
                <v-text-field
                  outlined
                  label="Informe (Data Studio)"
                  rows="3"
                  v-model="updatableClient.dataStudio"
                  prepend-inner-icon="mdi-monitor-dashboard"
                />
              </v-col>
              <v-col
                  cols="12"
                  md="12"
              >
                <v-textarea
                  outlined
                  label="Comentarios del Administrador"
                  rows="3"
                  v-model="updatableClient.adminComments"
                  prepend-inner-icon="mdi-text"
                />
              </v-col>
              <v-col sm="12">
                <h3>¿Tiene productos en su catálogo?</h3>
                <p v-html="shopProductInfo"></p>
                <v-file-input
                    outlined
                    label="Cargar catálogo en XML"
                    rows="3"
                    :prepend-icon="false"
                    v-model="updatableClient.xmlFile"
                    show-size
                    prepend-inner-icon="mdi-file-code-outline"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="toggleUpdateClientDialog = !toggleUpdateClientDialog"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              :loading="loadingResponse"
              @click="updateClient"
          >
            Guardar cambios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--Dialogo eliminar cliente-->

    <v-dialog
        v-model="toggleAlertDelete"
        max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          ¿Está seguro que desea eliminar al cliente?
        </v-card-title>
        <v-card-subtitle class="text-center">
          <v-icon x-large class="text-h2">
            mdi-alert
          </v-icon>
        </v-card-subtitle>
        <v-card-text>
          Si elimina el cliente no podrá recuperar sus datos y todos sus usuarios serán eliminados junto a el.
        </v-card-text>

        <v-card-actions>

          <v-btn
              color="green darken-1"
              text
              @click="cancelDelete"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="red darken-1"
              @click="deleteClient"
              outlined
              :loading="loadingResponse"
          >
            <v-icon>
              mdi-delete
            </v-icon>
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-snackbar
        v-model="snackbar"
        top
        class=""
        timeout="6000"
    >
      <v-icon left>mdi-alert</v-icon>
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"

        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <AdminClientDetailDialog v-if="selectedClient" :client="selectedClient" :toggle="detailDialog" v-on:closeClientDetailDialog="closeDetailDialog"></AdminClientDetailDialog>

  </div>
</template>

<script>
import httpClient from "@/api/httpClient";
import AdminClientDetailDialog from "@/components/admin/client/AdminClientDetailDialog";
export default {
  name: "AdminClients",
  components:{
    AdminClientDetailDialog
  },
  data(){
    return{
      search:'',
      clients:[],
      loadingClients:false,
      loadingResponse:false,
      headers: [
        {text: 'Nombre', align: 'center', value: 'name'},
        { text: 'Estado', value: 'status' },
        { text: 'Campañas', value: 'campaigns.length' },
        { text: 'Administrador', value: 'administrativeContactEmail' },
        { text: 'Creado el', value: 'createdAt' },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center' },
      ],
      dialog:false,
      validForm:false,
      nameRules:[
        v => !!v || 'Debe ingresar un nombre.',
        v => (v && v.length <= 30) || 'El nombre debe tener menos de 30 caracteres.',
      ],
      emailRules:[
        v => !!v || 'Debe ingresar un email.',
        v => /.+@.+\..+/.test(v) || 'El e-mail debe tener un formato válido.',

      ],
      phoneRules:[
        v => !!v || 'Debe ingresar un teléfono.',
      ],
      passwordRules:[
        v => !!v || 'Debes ingresar una contraseña.',
        v => (v && v.length >= 8) || 'La contraseña debe tener al menos 8 caracteres.',
      ],
      passwordConfirmRules(password){
        return v => v && v === password || 'Las contraseñas deben coincidir.'
      },
      clientName:'',
      administrativeContact:'',
      administrativeContactEmail:'',
      administrativeContactPhone:'',
      password:'',
      passwordConfirm: '',
      toggleUpdateClientDialog:false,
      updatableClient:null,
      toggleAlertDelete:false,
      deletableClientId:'',
      showPassword:false,
      snackbar:false,
      errorMessage:'',
      selectedClient:null,
      detailDialog:false

    }
  },
  methods:{
    validateForm() {
      this.$refs.form.validate()
    },
    async getAdminClients(){
      this.loadingClients = !this.loadingClients
      const response = await httpClient.get(`/clients`)
      if (response.data.status ==='success'){
        this.clients = response.data.data.clients

        this.loadingClients = false
      }
    },
    async createClient(){
      this.validateForm()
      this.loadingResponse=true
      if (this.validForm){
        try {
          const response = await httpClient.post(`/clients`,{
            name:this.clientName,
            administrativeContact:this.administrativeContact,
            administrativeContactEmail:this.administrativeContactEmail,
            administrativeContactPhone:this.administrativeContactPhone,
            password:this.password,
            passwordConfirm:this.passwordConfirm
          })
          if (response.data.status === 'success'){
            this.clients.push(response.data.data.client)
            this.loadingResponse=false
            this.dialog=false
          }
        }catch (e) {
          if (e.response.data.status === 'error' || e.response.data.status === 'fail'){
            console.log(e.response.data.message)
            this.errorMessage = e.response.data.message
            this.snackbar = true
            this.loadingResponse=false
          }
        }

      }

    },
    toggleUpdateClient(item){
      this.toggleUpdateClientDialog = !this.toggleUpdateClientDialog
      this.updatableClient = item

    },
    async updateClient(){
      try{
        this.loadingResponse = true
        let formData = new FormData()
        formData.append('active', this.updatableClient.status)
        if (this.updatableClient.dataStudio){
          formData.append('dataStudio', this.updatableClient.dataStudio)
        }
        if (this.updatableClient.adminComments){
          formData.append('adminComments', this.updatableClient.adminComments)
        }
        if (this.updatableClient.subscriptionEndDate){
          formData.append('subscriptionEndDate', this.updatableClient.subscriptionEndDate)
        }
        if (this.updatableClient.xmlFile){
          formData.append('xmlFile', this.updatableClient.xmlFile)
        }

        const response = await httpClient.patch(`/clients/${this.updatableClient._id}`, formData)
        if(response.data.status === 'success'){
          this.toggleUpdateClientDialog = false
          this.loadingResponse = false
        }
      }catch (e) {
        if (e.response.data.status === 'error' || e.response.data.status === 'fail'){
          console.log(e.response.data.message)
          this.errorMessage = e.response.data.message
          this.snackbar = true
          this.loadingResponse=false
        }
      }
    },

    async alertDelete(item){
      this.toggleAlertDelete = true
      this.deletableClientId = item._id
    },
    cancelDelete(){
      this.deletableClientId = ''
      this.toggleAlertDelete = !this.toggleAlertDelete
    },
    async deleteClient(){
      if (this.deletableClientId){
        try{
          this.loadingResponse = true
          const response = await httpClient.delete(`/clients/${this.deletableClientId}`)
          const deletedClient = response.data.data.client
          if (response.data.status === 'success'){
            this.toggleAlertDelete = false
            this.loadingResponse = false
          }
          this.clients = this.clients.filter(client =>{
            return client._id !== deletedClient._id
          })
        }catch (e) {
          if (e.response.data.status === 'error' || e.response.data.status === 'fail'){
            console.log(e.response.data.message)
            this.errorMessage = e.response.data.message
            this.snackbar = true
            this.loadingResponse=false
          }
        }
      }
    },
    toggleDetailDialog(item){
      this.selectedClient = item
      this.detailDialog = true
    },
    closeDetailDialog(){
      this.detailDialog = false
    }
  },
  computed:{
    shopProductInfo(){
      if (this.updatableClient.businessInfo.shopProducts.length<1){
        return 'El cliente no cargó productos a su catálogo.'
      }
      return `El cliente tiene <strong>${this.updatableClient.businessInfo.shopProducts.length}</strong> productos cargados en su catálogo.`
    }
  },
  created() {
    this.getAdminClients()
  }
}
</script>

<style scoped>

</style>