<template>
  <div class="product-catalogue">
    <v-container>
      <v-row>
        <v-col md="12">
          <h1 class="subheading text-md-h4">Catálogo de Productos</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-card class="rounded-xl">
            <v-card-title>
              <v-col md="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    outlined
                    dense
                    hint="Buscar por Producto, Precio o Disponibilidad."
                    persistent-hint
                ></v-text-field>
              </v-col>
              <v-col md="8" class="d-flex flex-column align-end justify-end ">
                <v-btn
                    color="info"
                    @click="updateProducts"
                    :loading="loadingProducts"
                    :disabled="!enabledProductsUpdate"
                    outlined
                    class="mb-2"
                >
                  <v-icon left>
                    mdi-refresh
                  </v-icon>
                  Actualizar catálogo
                </v-btn>
                <small class="last-product-update-info">Podrá actualizar su catálogo cada {{productUpdateTime}} minutos</small>
                <small class="last-product-update" >Útima actualización: {{new Date(lastProductsUpdate).toLocaleString()}} </small>
              </v-col>

            </v-card-title>
            <v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="products"
                  :search="search"
                  :loading="loadingProducts"
                  loading-text="Cargando ... Por favor aguarde"
                  class="v-data-table"
                  height="370"
              >
                <template v-slot:item.image="{ item }">
                  <div class="p-2">
                    <v-img :src="item['image_link']" :alt="item.title" class="product-image-container" width="50px" height="50px"></v-img>
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import clientAPI from '@/api/clients.api'
import httpClient from "@/api/httpClient";

export default {
  name: "ClientProductCatalogue",
  data(){
    return{
      search: '',
      headers: [
        { text: 'Identificador', value: 'id' },
        { text: "Imagen", value: "image", sortable: false },
        {
          text: 'Producto',
          align: 'center',
          value: 'title',
        },
        { text: 'Tipo', value: 'product_type',align: 'start'},
        { text: 'Precio', value: 'price', align: 'center' },
        { text: 'Disponibilidad', value: 'availability', align: 'center' },
      ],
      products:[],
      loadingProducts:false,
      globalClient:null,
      enabledProductsUpdate:true,
      lastProductsUpdate:null,
      productUpdateTime:null,
    }
  },
  methods:{
    async getMyProducts(){
      this.loadingProducts = true
      const clientId = this.globalClient._id
      try{
        const response = await httpClient.get(`clients/myClients/${clientId}/getMyProducts`)

        if(response.data.status==='success'){
          this.products = response.data.products

          this.lastProductsUpdate = response.data.lastProductsUpdate
          this.loadingProducts = false

        }
      }catch (e) {
          this.loadingProducts = false
          this.enabledProductsUpdate = false
      }
    },
    async updateProducts(){
      try{
        this.loadingProducts = true

        const response = await clientAPI.updateMyProducts(this.globalClient._id)
        if(response.data.status==='success'){
          this.enabledProductsUpdate = false
          this.products = response.data.updatedProducts

          await this.$store.dispatch('account/setProductUpdateTimestamp', response.data.lastProductsUpdate)
          this.lastProductsUpdate = response.data.lastProductsUpdate
          this.loadingProducts = false

        }
      }catch (e) {
          this.loadingProducts = false
          this.enabledProductsUpdate = false
      }
    },
    async enableProductsUpdate(){
      const lastUpdate = new Date(this.lastProductsUpdate).getTime()
      let now = new Date().getTime()
      let timeSpan = Math.floor((now - lastUpdate)/1000/60)
      timeSpan < this.productUpdateTime? this.enabledProductsUpdate = false : true
      setTimeout(()=>{
        this.enabledProductsUpdate = true
      },this.productUpdateTime * 60 * 1000)

    },
    toggleInitialFormModal(){
      this.$store.dispatch('account/toggleInitialFormDialog')
    },
  },
  watch:{
    products(){
      this.products.map(product =>{
        if ( product['availability'] === 'in stock'){
          product['availability'] = 'Disponible'
        }
        else{
          product['availability'] = 'No disponible'
        }
      })
    }
  },

  mounted() {
    this.productUpdateTime = process.env.VUE_APP_CLIENT_PRODUCT_UPDATE_TIMESTAMP_MINUTES
    this.globalClient = this.$store.getters['account/getGlobalClient']
    if (!this.globalClient.completedInitialData){
      this.$router.push({name:'ClientCampaigns'})
    }
    this.lastProductsUpdate = this.$store.getters['account/getLastProductsUpdate']
    this.getMyProducts()
    this.enableProductsUpdate()
    this.products.map(product =>{
      if ( product['availability'] === 'in stock'){
        product['availability'] = 'Disponible'
      }
      else{
        product['availability'] = 'No disponible'
      }
    })


  }
}
</script>

<style scoped>
.v-data-table {
  line-height: 4.3;
}

.product-image-container{
  border-radius: 50%;
  border: 2px solid #94c3ea;
}
.last-product-update-info{
  line-height: 18px;
  font-size: 11px;
}
.last-product-update{
  line-height: 18px;
  color: green;
  font-size: 11px;
}

</style>