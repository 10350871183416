export default {
    getCreateCampaignDialogToggleState(state){
        return state.createCampaignDialog
    },
    getCampaignObjectives(state){
        return state.campaignObjectives
    },
    getCampaignFinalConfig(state){
        return state.newCampaign
    }
}