<template>
  <div class="users">
    <v-container>
      <v-row>
        <v-col md="12">
          <h1 class="subheading text-md-h4">Usuarios</h1>
        </v-col>
      </v-row>

    </v-container>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-card class="rounded-xl">
            <v-card-title class="d-flex justify-space-between">
              <v-col md="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col md="2" class="mr-4">
                <v-btn
                    color="info"
                    outlined
                    @click="dialog = !dialog"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Crear Usuario
                </v-btn>
              </v-col>

            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="users"
                :search="search"
                :loading="loadingUsers"
                loading-text="Cargando ... Por favor aguarde"
                height="370"

            >

              <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="toggleUpdateUser(item)"
                >
                  mdi-pencil
                </v-icon>
                <!--                <v-icon-->
                <!--                    small-->
                <!--                    @click="deleteUser(item)"-->
                <!--                >-->
                <!--                  mdi-delete-->
                <!--                </v-icon> -->
                <v-icon
                    small
                    @click="alertDelete(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>



    <!--Dialogo Crear Usuario-->
    <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            <v-icon>
              mdi-briefcase-plus
            </v-icon>
            Crear Usuario

          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="validForm">
              <v-row class="d-flex justify-center">
                <v-col cols="10">
                  <v-text-field
                      v-model="newUserData.completeName"
                      label="Nombre completo del usuario *"
                      required
                      outlined
                      :rules="nameRules"
                      prepend-inner-icon="mdi-account"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                      v-model="newUserData.email"
                      label="Email del usuario *"
                      required
                      outlined
                      :rules="emailRules"
                      prepend-inner-icon="mdi-at"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                      v-model="newUserData.password"
                      label="Contraseña *"
                      required
                      outlined
                      :rules="passwordRules"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"

                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                      v-model="newUserData.passwordConfirm"
                      label="Confirmar Contraseña *"
                      required
                      outlined
                      :rules="[passwordConfirmRules(newUserData.password)]"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-select
                      v-model="newUserData.role"
                      :items="roles"
                      color="blue-grey lighten-2"
                      label="Tiene el ROL de ..."
                      outlined

                  >

                  </v-select>
                </v-col>
                <v-col cols="10" v-if="newUserData.role && newUserData.role !== 'admin'">
<!--              !!!!!!!!!!!!!!!!!!    el chip del autocomplete no se borra al hacer click en la cruz !!!!!!!!!!!!!!!!!!!!!!!-->
                  <v-autocomplete
                      v-model="newUserData.clients"
                      :items="clients"
                      chips
                      color="blue-grey lighten-2"
                      label="Pertenece a la/s cuenta/s"
                      item-text="name"
                      item-value="_id"
                      multiple
                      outlined
                      deletable-chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close

                      >

                        {{ data.item.name }}
                      </v-chip>
                    </template>

                  </v-autocomplete>
                </v-col>

              </v-row>
              <p>Los campos con * son obligatorios</p>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="createUser"
              :loading="loadingResponse"
              :disabled="!validForm"
          >
            Crear Usuario
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialogo actualizar Usuario-->

    <v-dialog
        v-model="toggleUpdateUserDialog"
        persistent
        max-width="600px"
        v-if="updatableUser"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Actualizar Usuario : {{ updatableUser.completeName }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="validForm">
              <v-row class="d-flex justify-center">
                <v-col cols="10">
                  <v-text-field
                      v-model="updatableUser.completeName"
                      label="Nombre completo del usuario *"
                      required
                      outlined
                      :rules="nameRules"
                      prepend-inner-icon="mdi-account"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                      v-model="updatableUser.email"
                      label="Email del usuario *"
                      required
                      outlined
                      :rules="emailRules"
                      prepend-inner-icon="mdi-at"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                      v-model="updatableUser.password"
                      label="Contraseña *"
                      outlined
                      :rules="updatableUser.password?[updatePasswordRules(updatableUser.password)]:[]"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"

                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                      v-model="updatableUser.passwordConfirm"
                      label="Confirmar Contraseña *"
                      outlined
                      :rules="updatableUser.passwordConfirm?[updatePasswordConfirmRules(updatableUser.password)]:[]"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-select
                      v-model="updatableUser.role"
                      :items="roles"
                      color="blue-grey lighten-2"
                      label="Tiene el ROL de ..."
                      outlined

                  >

                  </v-select>
                </v-col>
                <v-col cols="10" v-if="updatableUser.role !== 'admin'">
                  <v-autocomplete
                      v-model="updatableUser.clients"
                      :items="clients"
                      chips
                      color="blue-grey lighten-2"
                      label="Pertenece a la/s cuenta/s"
                      item-text="name"
                      item-value="_id"
                      multiple
                      outlined
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close

                      >

                        {{ data.item.name }}
                      </v-chip>
                    </template>

                  </v-autocomplete>
                </v-col>

              </v-row>
              <p>Los campos con * son obligatorios</p>
            </v-form>

          </v-container>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="toggleUpdateUserDialog = !toggleUpdateUserDialog"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"

              @click="updateUser"
          >
            Guardar cambios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialogo eliminar Usuario-->

    <v-dialog
        v-model="toggleAlertDelete"
        max-width="400"
        v-if="deletableUser"
    >
      <v-card>
        <v-card-title class="headline">
          ¿Está seguro que desea eliminar el usuario {{deletableUser.completeName.toUpperCase()}}?
        </v-card-title>
        <v-card-subtitle class="text-center pt-6">
          <v-icon x-large class="text-h2">
            mdi-alert
          </v-icon>
        </v-card-subtitle>
        <v-card-text>
          Si elimina el usuario no podrá recuperar sus datos.
        </v-card-text>

        <v-card-actions>

          <v-btn
              color="green darken-1"
              text
              @click="cancelDelete"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="red darken-1"
              @click="deleteUser"
              outlined
          >
            <v-icon>
              mdi-delete
            </v-icon>
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>





    <v-snackbar
        v-model="snackbar"
        top
        class=""
        timeout="6000"
    >
      <v-icon left>mdi-alert</v-icon>
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"

        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import httpClient from "@/api/httpClient";

export default {
  name: "AdminUsers",
  data(){
    return{
      search:'',
      users:[],
      clients:[],
      loadingUsers:false,
      loadingResponse:false,
      headers: [
        { text: 'ID', value: '_id', sortable:false },
        {text:  'Nombre Completo', align: 'start', value: 'completeName'},
        { text: 'Email', value: 'email' },
        { text: 'Rol', value: 'role' },
        { text: 'Creado el', value: 'createdAt' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      dialog:false,
      validForm:false,
      snackbar:false,
      nameRules:[
        v => !!v || 'Debes ingresar un nombre.',
        v => (v && v.length <= 30) || 'El nombre debe tener menos de 30 caracteres.',
      ],
      emailRules:[
        v => !!v || 'Debes ingresar un email.',
        v => /.+@.+\..+/.test(v) || 'El e-mail debe tener un formato válido.',

      ],
      passwordRules:[
        v => !!v || 'Debes ingresar una contraseña.',
        v => (v && v.length >= 8) || 'La contraseña debe tener al menos 8 caracteres.',
      ],
      passwordConfirmRules(password){
          return v => v && v === password || 'Las contraseñas deben coincidir.'
      },
      updatePasswordRules(){
          return v => ( v.length >= 8) || 'La contraseña debe tener al menos 8 caracteres.'
      },
      updatePasswordConfirmRules(password){
          return v => v === password || 'Las contraseñas deben coincidir.'
      },
      toggleUpdateUserDialog:false,
      updatableUser:null,
      toggleAlertDelete:false,
      deletableUser:'',
      showPassword:false,
      newUserData:{
        completeName:'',
        email:'',
        role:null,
        password:'',
        passwordConfirm:'',
        clients:[]
      },
      roles:['admin', 'propietario', 'usuario'],
      errorMessage:''
    }
  },
  methods:{
    validateForm() {
      this.$refs.form.validate()
    },
    async getAdminClients(){
      const response = await httpClient.get(`${process.env.VUE_APP_API_ENDPOINT}/clients`)
      if (response.data.status ==='success'){
        this.clients = response.data.data.clients
      }
    },
    async getAdminUsers(){
      this.loadingUsers = !this.loadingUsers
      const response = await httpClient.get(`${process.env.VUE_APP_API_ENDPOINT}/users`)
      if (response.data.status ==='success'){
        this.users = response.data.data.users
        this.loadingUsers = false
      }
    },
    async createUser(){
      this.validateForm()
      if (this.validForm){
        this.loadingResponse = true
        try {
          const response = await httpClient.post('users', this.newUserData)
          if (response.data.status ==='success') {
            this.users.push(response.data.data.user)
            this.loadingResponse=false
            this.dialog = false
          }
        }catch (e) {
          if (e.response.data.status === 'error' || e.response.data.status === 'fail'){
            this.errorMessage = e.response.data.message
            this.snackbar = true
            this.loadingResponse = false
          }
        }

      }

    },
    toggleUpdateUser(item){
      this.toggleUpdateUserDialog = !this.toggleUpdateUserDialog
      this.updatableUser = item

    },
    async updateUser(){
      this.validateForm()
      if (this.validForm){
        this.loadingResponse=true
        try {
          const {completeName, email, password, role, clients} = this.updatableUser
          const response = await httpClient.patch(
              `${process.env.VUE_APP_API_ENDPOINT}/users/${this.updatableUser._id}`,
              {completeName, email, password, role, clients}
          )
          if(response.data.status === 'success'){
            this.toggleUpdateUserDialog = false
            this.loadingResponse = false
          }
        }catch (e) {
          if (e.response.data.status === 'error' || e.response.data.status === 'fail'){
            this.errorMessage = e.response.data.message
            this.snackbar = true
            this.loadingRepsonse = false
          }
        }
      }


    },

    async alertDelete(item){
      this.toggleAlertDelete = true
      this.deletableUser = item
    },
    cancelDelete(){
      this.deletableUser = null
      this.toggleAlertDelete = !this.toggleAlertDelete
    },
    async deleteUser(){
      if (this.deletableUser){
        try{
          const response = await httpClient.delete(`${process.env.VUE_APP_API_ENDPOINT}/users/${this.deletableUser._id}`)
          const deletedUser = response.data.data.user
          if (response.data.status === 'success'){
            this.toggleAlertDelete = false
          }
          this.users = this.users.filter(user =>{
            return user._id !== deletedUser._id
          })
        }catch (e) {
          if (e.response.data.status === 'error' || e.response.data.status === 'fail'){
            this.errorMessage = e.response.data.message
            this.snackbar = true
            this.loadingRepsonse = false
          }
        }

      }
    }
  },
  computed:{

  },
  created() {
    this.getAdminClients()
    this.getAdminUsers()
  }
}
</script>

<style scoped>

</style>