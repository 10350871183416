import campaignMutations from './mutations'
import campaignActions from './actions'
import campaignGetters from './getters'

export default {
    namespaced:true,
    state:{
        createCampaignDialog: false,
        campaignObjectives:[
            {
                objective:'tienda online',
                description:'Promocionar los productos de mi tienda online ante potenciales clientes (Instagram, Facebook, Google).',
                icon:'mdi-store',
                disabled:false
            },
            {
                objective:'marca',
                description:'Generar mayor conocimiento sobre mi marca (Llegar a más personas, Aumentar el tráfico al perfil de Instagram, Aumentar las interacciones con mis posteos).',
                icon:'mdi-bullhorn',
                disabled:true
            },
            {
                objective:'mensajes',
                description:'Incentivar a mis potenciales clientes a contactarme vía mensajes directos (Whatsapp, Facebook Messenger, Instagram Direct).',
                icon:'mdi-message',
                disabled:true
            }
        ],
        newCampaign:{
            objective:null,
            initialConfig: {
                descriptiveName:'',
                startDate:null,
                endDate:null
            },
            audience:{
                country:null,
                regions:[],
                gender:[],
                ageRange:{
                    minAge:0,
                    maxAge:100
                },
                parentalState:'',
                interests:[],
                extraInfo:''
            },
            shopWindows:[],
            budget:null

        }
    },
    mutations: campaignMutations,
    actions: campaignActions,
    getters: campaignGetters
}