<template>
  <div class="support">
    <v-container>
      <v-row>
        <v-col md="12">
          <h1 class="subheading text-md-h4">
            Soporte
          </h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="d-flex justify-center">
        <v-col md="8">
            <v-row>
              <v-col class="d-flex align-center">
                <v-icon left>
                  mdi-face-agent
                </v-icon>
                <h2>Nuevo ticket de soporte</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <p>¡Hola! Por favor escribe tu consulta y nuestro equipo se encargará
                  de resolverla en cuanto sea posible. <br>
                  No olvides visitar el área de <router-link class="text-md-body-2" to="/ayuda">Ayuda</router-link> donde podrás encontrar documentación que tal véz sea
                  útil para aclarar tus dudas o problemas.
                </p>
              </v-col>
            </v-row>
            <v-form ref="form" v-model="valid">
              <v-row >
                <v-col md="12">
                  <v-select
                      v-model="subject"
                      :items="subjects"
                      label="Motivo"
                      outlined
                      prepend-inner-icon="mdi-account-circle"
                      background-color="white"
                      :rules="subjectRules"

                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-textarea
                      label="Mensaje"
                      prepend-inner-icon="mdi-text"
                      v-model="message"
                      outlined
                      background-color="white"
                      :rules="messageRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions class="pt-0">
                <v-container>
                  <v-row >
                    <v-col md="8">
                      <div>
                        <v-alert
                            type="success"
                            :value="responseSuccess"
                            dense
                            text
                            transition="scale-transition"

                        >Su consulta ha sido enviada con éxito.</v-alert>
                      </div>
                    </v-col>
                    <v-col md="4" >
                      <div class="d-flex justify-end align-center">
                        <v-btn
                            color="primary"
                            @click="submitSupportTicket"
                            :loading="loadingResponse"
                        >
                          Enviar Ticket
                        </v-btn>
                      </div>

                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-form>
        </v-col>
      </v-row>
    </v-container>

  </div>

</template>

<script>
import httpClient from "@/api/httpClient";
export default {
  name: "ClientSupport.vue",
  data(){
    return {
      valid:false,
      globalClient:null,
      selectedAccount:null,
      subjects:[
          'Ayuda con la plataforma',
          'Error en la plataforma',
          'Consulta sobre rendimiento de campañas',
          'Consulta sobre material para campañas',
          'Consulta sobre Facturación y cobros',
          'Consulta sobre Planes'
      ],
      subject:'',
      message:'',
      clientData:{
        _id:'',
        administrativeContact:'',
        administrativeContactEmail:'',
        administrativeContactPhone:'',
        name:''
      },
      subjectRules:[
        v => !!v || 'Debe seleccionar un motivo para su consulta.',
      ],
      messageRules:[
        v => !!v || 'Su consulta debe tener un mensaje.',
      ],
      loadingResponse:false,
      responseSuccess:false
    }
  },
  methods:{
    validate () {
      this.$refs.form.validate()
    },
    async submitSupportTicket(){
      this.validate()

      try{
        if (this.valid){
          this.loadingResponse = !this.loadingResponse

          // const response = await httpClient.post(`/support/submitNewTicket`,{
          //   subject:this.subject,
          //   message:this.message,
          //   client:this.globalClientId
          //
          // })
          console.log(this.clientData)
          const response = await httpClient.post(`/support/submitNewTicket`,{
            message:this.message,
            subject:this.subject,
            client: this.clientData,
            // _id:this.globalClient._id,
            // administrativeContact:this.globalClient.administrativeContact,
            // administrativeContactEmail:this.globalClient.administrativeContactEmail,
            // administrativeContactPhone:this.globalClient.administrativeContactPhone,
            // name:this.globalClient.name
          })

          if (response.data.status ==='success'){
            this.loadingResponse = !this.loadingResponse
            this.responseSuccess= !this.responseSuccess
          }
          setTimeout(()=>{
            this.responseSuccess = false
          },4000)
        }
      }catch (e) {
        console.log(e)
      }
    }
  },
  created() {
    this.globalClient = this.$store.getters['account/getGlobalClient']

      this.clientData._id=this.globalClient._id
      this.clientData.administrativeContact=this.globalClient.administrativeContact
      this.clientData.administrativeContactEmail=this.globalClient.administrativeContactEmail
      this.clientData.administrativeContactPhone=this.globalClient.administrativeContactPhone
      this.clientData.name=this.globalClient.name

  }
}
</script>

<style scoped>

</style>