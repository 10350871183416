import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import es from "vuetify/lib/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.green.lighten1, // #43A047
                secondary: colors.blue.lighten4, // #B3E5FC
                accent: colors.indigo.base, // #3F51B5
                lightGrey:colors.grey.lighten5 //#F5F5F5
            },
        },
    },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    lang: {
        locales: {es},
        current: 'es',
    },
});
