<template>
    <v-dialog
        v-model="initialFormDialog"
        persistent
        max-width="1000px"
    >
      <v-card
          class="mx-auto py-2"
          flat

      >
        <v-card-title class="title font-weight-regular">
          <v-avatar
              v-if="step>0 && step!==4"
              color="primary"
              class="subheading white--text mr-3"
              size="28"
              v-text="step"
          ></v-avatar>
          <span>{{ currentTitle }}</span>
          <v-spacer/>
          <v-menu offset-y  open-on-hover nudge-left="50">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="grey lighten-1"
                  v-bind="attrs"
                  v-on="on"
                  large
                  icon
              >
                <v-icon dark >
                  mdi-help
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  link
                  :to="{name:'ClientSupport'}"
              >
                <v-list-item-title>
                  <v-icon left>
                    mdi-message-question-outline
                  </v-icon>
                  Necesito Ayuda
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  link
                  :to="{name:'ClientDocuments'}"
              >
                <v-list-item-title>
                  <v-icon left>
                    mdi-file-document-outline
                  </v-icon>
                  Ver guías
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-progress-linear
              :active="step===2"
              :indeterminate="loadingResponse"
              absolute
              bottom
              height="6"
              striped
              background-color="grey"
              color="primary accent-4"
              class="mt-3"
          ></v-progress-linear>
        </v-card-title>
        <v-divider></v-divider>
        <v-window v-model="step">
          <v-window-item :value="0">
            <div class="pa-4 text-center">
              <svg height="250" viewBox="0 0 952 666" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="welcome 1" clip-path="url(#clip0)">
                  <path id="Vector" d="M42.3849 255.25C43.2947 311.424 64.2902 317.505 90.0734 311.647C119.528 304.954 143.703 283.976 155.618 256.22L163.835 237.078C169.298 224.352 169.482 209.978 164.346 197.116C159.211 184.255 149.177 173.961 136.451 168.498H136.451C130.15 165.793 123.377 164.355 116.521 164.268C109.664 164.18 102.857 165.444 96.4891 167.986C90.1209 170.529 84.3158 174.302 79.4054 179.088C74.495 183.874 70.5755 189.581 67.8705 195.882C41.4286 206.269 36.2063 227.773 42.3849 255.25Z" fill="#2F2E41"/>
                  <path id="Vector_2" d="M208.174 142.19L216.088 128.799C217.054 127.164 217.638 125.331 217.795 123.438C217.952 121.545 217.678 119.641 216.994 117.869C216.31 116.097 215.233 114.503 213.845 113.206C212.457 111.909 210.794 110.944 208.979 110.382V110.382C207.064 109.789 205.035 109.662 203.061 110.012C201.087 110.362 199.225 111.179 197.631 112.395C196.036 113.61 194.756 115.189 193.895 117C193.035 118.811 192.62 120.801 192.685 122.805L193.15 137.199L155.959 239.121L176.24 243.355L208.174 142.19Z" fill="#E79797"/>
                  <path id="Vector_3" d="M12.5159 393.443L2.77518 405.571C1.58571 407.052 0.747023 408.783 0.322058 410.634C-0.102907 412.485 -0.103123 414.409 0.321425 416.26C0.745973 418.112 1.58426 419.843 2.77341 421.324C3.96255 422.805 5.47167 423.998 7.18754 424.812V424.812C8.99863 425.672 10.989 426.087 12.9928 426.022C14.9966 425.956 16.9557 425.413 18.707 424.437C20.4582 423.461 21.9509 422.081 23.0605 420.411C24.1701 418.741 24.8645 416.83 25.0859 414.838L26.6763 400.524L78.008 304.941L58.5374 297.86L12.5159 393.443Z" fill="#E79797"/>
                  <path id="Vector_4" d="M72.6978 494.336L74.1315 511.559L74.4678 515.577L169.803 506.408C173.089 506.038 176.242 504.897 179.004 503.079C181.766 501.261 184.061 498.817 185.701 495.946C187.341 493.074 188.281 489.856 188.443 486.553C188.606 483.25 187.987 479.955 186.636 476.937L155.89 410.931L145.27 388.133L99.2486 377.513C61.2986 406.559 95.443 438.615 138.402 471.042C140.084 472.317 141.783 473.591 143.5 474.866L72.6978 494.336Z" fill="#2F2E41"/>
                  <path id="Vector_5" d="M40.574 553.975C41.7356 554.141 42.9204 554.006 44.0147 553.582C45.109 553.159 46.0761 552.461 46.8234 551.557L78.0079 513.807L76.2379 496.106L65.6049 489.727C64.7351 489.205 63.7621 488.879 62.7535 488.771C61.745 488.663 60.725 488.776 59.7644 489.102C58.8039 489.428 57.9259 489.959 57.1914 490.658C56.4569 491.358 55.8835 492.209 55.5111 493.153L35.1715 544.679C34.7916 545.642 34.6347 546.678 34.7125 547.709C34.7903 548.741 35.1008 549.742 35.6206 550.636C36.1405 551.531 36.8563 552.296 37.7142 552.874C38.5721 553.452 39.5498 553.829 40.574 553.975V553.975Z" fill="#2F2E41"/>
                  <path id="Vector_6" opacity="0.2" d="M67.3877 490.796L69.1577 512.037L74.1316 511.559L164.493 502.868C167.779 502.498 170.932 501.357 173.694 499.539C176.456 497.721 178.751 495.277 180.391 492.406C182.031 489.534 182.971 486.316 183.133 483.013C183.296 479.71 182.677 476.415 181.326 473.397L155.891 416.454V410.931L145.27 388.133L99.2487 377.513C61.2987 406.559 95.4431 438.615 138.402 471.042L138.19 471.326L67.3877 490.796Z" fill="black"/>
                  <path id="Vector_7" d="M31.7237 546.895C32.8852 547.061 34.07 546.926 35.1643 546.502C36.2586 546.079 37.2257 545.381 37.973 544.477L69.1576 506.727L67.3875 489.026L56.7545 482.647C55.8848 482.125 54.9118 481.798 53.9032 481.691C52.8946 481.583 51.8746 481.696 50.9141 482.022C49.9536 482.348 49.0755 482.879 48.341 483.578C47.6065 484.278 47.0331 485.129 46.6607 486.073L26.3212 537.599C25.9413 538.562 25.7844 539.598 25.8622 540.629C25.9399 541.661 26.2504 542.662 26.7703 543.556C27.2902 544.451 28.0059 545.216 28.8638 545.794C29.7217 546.372 30.6995 546.749 31.7237 546.895V546.895Z" fill="#2F2E41"/>
                  <path id="Vector_8" d="M63.8474 487.256L65.6174 508.497L160.953 499.326C164.239 498.955 167.391 497.815 170.154 495.998C172.916 494.18 175.211 491.737 176.852 488.866C178.492 485.995 179.433 482.777 179.597 479.475C179.76 476.172 179.142 472.877 177.793 469.858L152.35 412.914V381.053L90.3983 370.433C50.955 400.62 89.3862 434.06 134.65 467.786L63.8474 487.256Z" fill="#2F2E41"/>
                  <path id="Vector_9" d="M124.029 237.678C138.693 237.678 150.58 225.791 150.58 211.128C150.58 196.464 138.693 184.577 124.029 184.577C109.366 184.577 97.4785 196.464 97.4785 211.128C97.4785 225.791 109.366 237.678 124.029 237.678Z" fill="#E79797"/>
                  <path id="Vector_10" d="M88.6282 255.379L125.799 280.16L157.66 273.079L139.96 255.379V228.828L111.639 227.058C111.044 237.66 104.969 247.363 88.6282 255.379Z" fill="#E79797"/>
                  <path id="Vector_11" d="M83.3181 379.283L152.35 388.133L168.281 313.791C175.682 294.057 171.435 276.622 157.733 261.054C157.209 258.434 155.813 256.068 153.773 254.343C151.733 252.618 149.168 251.635 146.497 251.554L139.96 251.839C140.699 275.314 123.404 269.66 97.4786 250.069L94.3481 251.008C90.3013 252.222 86.8269 254.852 84.5607 258.418C82.2945 261.984 81.3881 266.247 82.0073 270.426L84.8618 289.694C86.0722 297.333 87.9525 304.85 90.4809 312.159C97.5584 333.311 95.0292 355.704 83.3181 379.283Z" fill="#83BEDE"/>
                  <path id="Vector_12" d="M51.457 299.63L83.318 310.251L97.4785 258.919L92.6812 256.738C88.7039 254.931 84.1878 254.704 80.0492 256.103C75.9106 257.503 72.4597 260.425 70.3966 264.276L51.457 299.63Z" fill="#83BEDE"/>
                  <path id="Vector_13" d="M145.27 251.839L162.971 271.309L184.211 237.678L157.66 223.518L145.27 251.839Z" fill="#83BEDE"/>
                  <path id="Vector_14" d="M93.0535 203.162C109.738 209.453 127.544 208.801 146.155 203.162V181.921H93.0535V203.162Z" fill="#2F2E41"/>
                  <path id="Vector_15" d="M921.132 567.779L902.827 575.406L780.798 409.141L692.327 578.457L669.447 573.881L736.563 340.5H814.356L921.132 567.779Z" fill="#2F2E41"/>
                  <path id="Vector_16" d="M945.689 606.565C944.674 606.842 943.615 606.915 942.571 606.781C941.528 606.647 940.522 606.307 939.611 605.783L901.008 583.557C899.966 582.957 899.076 582.125 898.407 581.126C897.738 580.127 897.308 578.987 897.151 577.795C896.993 576.603 897.112 575.391 897.498 574.253C897.884 573.114 898.527 572.079 899.377 571.229L902.827 567.779L921.132 566.254L949.114 593.117C950.132 594.094 950.872 595.323 951.259 596.68C951.647 598.037 951.668 599.472 951.32 600.839C950.971 602.206 950.267 603.457 949.278 604.463C948.288 605.469 947.05 606.194 945.689 606.565Z" fill="#2F2E41"/>
                  <path id="Vector_17" d="M646.415 612.667C647.43 612.943 648.489 613.017 649.532 612.882C650.575 612.748 651.582 612.409 652.493 611.884L691.096 589.658C692.138 589.058 693.028 588.227 693.697 587.227C694.366 586.228 694.796 585.089 694.953 583.897C695.111 582.705 694.992 581.493 694.606 580.354C694.22 579.215 693.577 578.181 692.727 577.331L689.276 573.881L670.972 572.355L642.99 599.218C641.972 600.195 641.232 601.425 640.844 602.781C640.457 604.138 640.436 605.573 640.784 606.94C641.132 608.307 641.837 609.558 642.826 610.564C643.815 611.57 645.054 612.296 646.415 612.667V612.667Z" fill="#2F2E41"/>
                  <path id="Vector_18" d="M684.7 97.9673L677.074 102.543L661.268 76.8601C660.298 75.2827 659.721 73.495 659.586 71.6478C659.451 69.8006 659.762 67.948 660.493 66.2463C661.224 64.5446 662.354 63.0436 663.787 61.8701C665.22 60.6965 666.914 59.8847 668.726 59.5031C670.338 59.1639 672.003 59.1731 673.611 59.5302C675.218 59.8874 676.731 60.5841 678.047 61.5737C679.364 62.5634 680.453 63.823 681.243 65.2682C682.032 66.7134 682.504 68.3107 682.625 69.953L684.7 97.9673Z" fill="#FFB9B9"/>
                  <path id="Vector_19" d="M895.973 297.924L899.137 289.612L927.44 300.023C929.178 300.663 930.737 301.711 931.986 303.079C933.234 304.447 934.135 306.096 934.612 307.885C935.09 309.675 935.13 311.553 934.73 313.361C934.329 315.17 933.5 316.855 932.312 318.276V318.276C931.255 319.539 929.94 320.561 928.456 321.275C926.972 321.988 925.352 322.376 923.706 322.412C922.059 322.448 920.424 322.132 918.91 321.485C917.396 320.837 916.038 319.873 914.927 318.658L895.973 297.924Z" fill="#FFB9B9"/>
                  <path id="Vector_20" d="M776.222 189.489C788.859 189.489 799.103 179.245 799.103 166.609C799.103 153.972 788.859 143.728 776.222 143.728C763.586 143.728 753.342 153.972 753.342 166.609C753.342 179.245 763.586 189.489 776.222 189.489Z" fill="#FFB9B9"/>
                  <path id="Vector_21" d="M796.052 215.42H764.019V180.337L791.476 177.286L796.052 215.42Z" fill="#FFB9B9"/>
                  <path id="Vector_22" d="M818.932 349.652H731.987C735.052 301.14 737.48 254.259 731.987 227.623L737.042 203.356C737.743 199.994 739.683 197.018 742.477 195.021C745.271 193.024 748.715 192.152 752.123 192.578L764.019 194.065C772.076 208.228 781.812 208.092 793.001 195.59H807.33C809.241 195.59 811.132 195.971 812.893 196.711C814.655 197.451 816.251 198.535 817.588 199.899C818.926 201.263 819.978 202.88 820.682 204.656C821.387 206.432 821.731 208.33 821.693 210.24C825.426 227.332 826.668 244.874 825.38 262.321L818.932 349.652Z" fill="#3F3D56"/>
                  <path id="Vector_23" d="M672.497 97.967L684.7 91.866L750.291 200.167L731.987 227.623L672.497 97.967Z" fill="#3F3D56"/>
                  <path id="Vector_24" d="M896.146 302.264L906.575 293.468L851.162 215.819C845.741 208.223 838.267 202.328 829.617 198.826C820.966 195.324 811.497 194.359 802.318 196.044L800.57 196.365L821.523 233.033L849.12 253.928L896.146 302.264Z" fill="#3F3D56"/>
                  <path id="Vector_25" d="M802.683 153.102C802.683 153.102 806.959 143.696 795.843 139.42C795.843 139.42 786.437 124.029 767.625 134.29C767.625 134.29 754.798 139.42 754.798 153.102C754.798 166.783 750.523 169.349 750.523 169.349C750.523 169.349 756.89 162.817 758.6 157.686C760.31 152.556 773.61 151.392 778.741 156.522C783.871 161.653 796.698 160.798 796.698 160.798L795.843 179.61C795.843 179.61 800.118 168.493 801.828 168.493C803.539 168.493 805.249 153.102 802.683 153.102Z" fill="#2F2E41"/>
                  <path id="Vector_26" d="M800.571 665.128C877.891 665.128 940.571 661.863 940.571 657.836C940.571 653.809 877.891 650.544 800.571 650.544C723.251 650.544 660.571 653.809 660.571 657.836C660.571 661.863 723.251 665.128 800.571 665.128Z" fill="#E6E6E6"/>
                  <path id="Vector_27" d="M101.571 661.69C142.44 661.69 175.571 659.965 175.571 657.836C175.571 655.708 142.44 653.982 101.571 653.982C60.7017 653.982 27.5708 655.708 27.5708 657.836C27.5708 659.965 60.7017 661.69 101.571 661.69Z" fill="#E6E6E6"/>
                  <path id="Vector_28" d="M666.954 128.289L217.086 168.608C214.877 168.803 212.681 168.114 210.979 166.692C209.277 165.271 208.209 163.232 208.008 161.023L198.007 49.4302C197.812 47.2213 198.5 45.0251 199.922 43.3233C201.344 41.6216 203.383 40.5532 205.591 40.3527L655.46 0.0338009C657.669 -0.161553 659.865 0.527222 661.567 1.94903C663.268 3.37084 664.337 5.40959 664.537 7.61804L674.539 119.211C674.734 121.42 674.045 123.616 672.623 125.318C671.202 127.02 669.163 128.088 666.954 128.289V128.289Z" fill="#4CAF50"/>
                  <path id="Vector_29" d="M269.655 134.383C289.056 134.383 304.785 118.655 304.785 99.2532C304.785 79.8515 289.056 64.1233 269.655 64.1233C250.253 64.1233 234.525 79.8515 234.525 99.2532C234.525 118.655 250.253 134.383 269.655 134.383Z" fill="white"/>
                  <path id="Vector_30" d="M348.374 68.6841C346.829 68.8255 345.404 69.5739 344.411 70.765C343.417 71.9562 342.937 73.4927 343.076 75.0374C343.214 76.5822 343.96 78.0088 345.149 79.0044C346.338 79.9999 347.874 80.4829 349.419 80.3474L624.338 55.7081C625.105 55.6405 625.85 55.4225 626.533 55.0664C627.215 54.7103 627.82 54.2232 628.314 53.633C628.808 53.0427 629.181 52.3609 629.411 51.6265C629.641 50.8922 629.724 50.1196 629.655 49.3531C629.587 48.5866 629.367 47.8411 629.01 47.1594C628.653 46.4776 628.165 45.8729 627.574 45.3799C626.984 44.8869 626.301 44.5152 625.566 44.2862C624.832 44.0571 624.059 43.9751 623.293 44.0449L348.374 68.6841Z" fill="white"/>
                  <path id="Vector_31" d="M351.509 103.674C350.742 103.741 349.996 103.959 349.314 104.315C348.631 104.671 348.025 105.158 347.531 105.748C347.037 106.338 346.664 107.02 346.434 107.755C346.204 108.49 346.12 109.262 346.189 110.029C346.258 110.796 346.477 111.542 346.834 112.224C347.192 112.905 347.68 113.51 348.271 114.003C348.862 114.496 349.545 114.868 350.28 115.097C351.015 115.326 351.788 115.407 352.555 115.337L627.474 90.6979C628.24 90.6303 628.986 90.4122 629.669 90.0561C630.351 89.7 630.956 89.213 631.45 88.6227C631.944 88.0325 632.317 87.3507 632.547 86.6163C632.777 85.8819 632.86 85.1094 632.791 84.3428C632.722 83.5763 632.503 82.8309 632.146 82.1491C631.789 81.4674 631.301 80.8627 630.71 80.3697C630.119 79.8766 629.437 79.505 628.702 79.2759C627.968 79.0468 627.195 78.9648 626.429 79.0346L351.509 103.674Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="951.571" height="665.128" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              <h2 class="title font-weight-regular mb-2">
                Bienvenido/a a Convertics
              </h2>
              <span class="caption grey--text">¡Gracias por confiar en nosotros!</span><br>
              <span class="caption grey--text">A continuación, deberás completar algunos datos para poder comenzar. </span>
            </div>
          </v-window-item>

          <v-window-item :value="1">
            <div class="px-4 pt-2 pb-0  text-center">
              <v-container>
                <v-form ref="form" v-model="validFirstStepForm">
                  <v-row>
                    <v-col md="6" class="d-flex flex-column">
                      <v-text-field
                          label="Nombre del Negocio"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-text-short"
                          dense
                          hint="¿Cómo se llama tu negocio?"
                          persistent-hint
                          v-model="initialData.name"
                          class="mb-4"
                          :rules="nameRules"


                      />
                      <v-text-field
                          label="Razón Social"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-card-account-details-outline"
                          dense
                          hint="¿Cuál es la razón social de tu negocio?"
                          persistent-hint
                          v-model="initialData.corporateName"
                          class="mb-4"
                          :rules="corporateNameRules"

                      />
                      <v-text-field
                          label="ID fiscal"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-identifier"
                          dense
                          hint="Nº de identificación fiscal o tributaria (CUIT, RFC, RUT, Otro...)"
                          persistent-hint
                          v-model="initialData.fiscalId"
                          class="mb-4"
                          :rules="fiscalIdRules"
                      />
                      <v-text-field
                          label="Nombre del contacto administrativo"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-account"
                          dense
                          hint="¿Con quién nos contactaremos?"
                          persistent-hint
                          v-model="initialData.administrativeContact"
                          class="mb-4"
                          :rules="administrativeContactRules"

                      />

                    </v-col>
                    <v-col md="6">
                      <svg height="300" viewBox="0 0 884 725" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="undraw_Forms_re_pkrt 1" clip-path="url(#clip0)">
                          <path id="Vector" d="M657.542 567.621H383.541C379.166 567.616 374.973 565.876 371.879 562.782C368.786 559.689 367.046 555.495 367.041 551.121V354.12C367.046 349.746 368.786 345.552 371.879 342.458C374.972 339.365 379.166 337.625 383.541 337.62H657.542C661.916 337.625 666.11 339.365 669.203 342.458C672.296 345.552 674.036 349.746 674.042 354.12V551.121C674.036 555.495 672.296 559.689 669.203 562.782C666.11 565.875 661.916 567.616 657.542 567.621V567.621Z" fill="#E6E6E6"/>
                          <path id="Vector_2" d="M388.606 554.467H652.477C654.598 554.467 656.633 553.624 658.133 552.124C659.634 550.624 660.477 548.589 660.477 546.467V358.773C660.477 356.651 659.634 354.616 658.133 353.116C656.633 351.616 654.598 350.773 652.477 350.773H388.606C386.484 350.773 384.449 351.616 382.949 353.116C381.449 354.616 380.606 356.651 380.606 358.773V546.467C380.606 548.589 381.449 550.624 382.949 552.124C384.449 553.624 386.484 554.467 388.606 554.467Z" fill="white"/>
                          <path id="Vector_3" d="M290.5 303.145H16.5C12.1254 303.14 7.93148 301.4 4.8382 298.307C1.74492 295.214 0.00494149 291.02 0 286.645V89.645C0.00493885 85.2705 1.74492 81.0765 4.8382 77.9832C7.93148 74.8899 12.1254 73.15 16.5 73.145H290.5C294.875 73.15 299.069 74.8899 302.162 77.9832C305.255 81.0765 306.995 85.2705 307 89.645V286.645C306.995 291.02 305.255 295.214 302.162 298.307C299.069 301.4 294.875 303.14 290.5 303.145V303.145Z" fill="#E6E6E6"/>
                          <path id="Vector_4" d="M21.5647 289.992H285.435C287.557 289.992 289.592 289.149 291.092 287.649C292.592 286.149 293.435 284.114 293.435 281.992V94.2976C293.435 92.1759 292.592 90.1411 291.092 88.6408C289.592 87.1405 287.557 86.2976 285.435 86.2976H21.5647C19.443 86.2976 17.4081 87.1405 15.9078 88.6408C14.4075 90.1411 13.5647 92.1759 13.5647 94.2976V281.992C13.5647 284.114 14.4075 286.149 15.9078 287.649C17.4081 289.149 19.443 289.992 21.5647 289.992Z" fill="white"/>
                          <path id="Vector_5" d="M621.023 409.093H452.473C451.682 409.093 450.899 409.249 450.169 409.551C449.439 409.854 448.775 410.297 448.216 410.856C447.657 411.415 447.214 412.079 446.911 412.809C446.609 413.54 446.453 414.322 446.453 415.113C446.453 415.903 446.609 416.686 446.911 417.417C447.214 418.147 447.657 418.811 448.216 419.37C448.775 419.928 449.439 420.372 450.169 420.674C450.899 420.977 451.682 421.133 452.473 421.133H621.023C622.62 421.133 624.151 420.498 625.28 419.37C626.409 418.241 627.043 416.709 627.043 415.113C627.043 413.516 626.409 411.985 625.28 410.856C624.151 409.727 622.62 409.093 621.023 409.093V409.093Z" fill="#E6E6E6"/>
                          <path id="Vector_6" d="M452.473 446.137C451.682 446.137 450.899 446.293 450.169 446.596C449.439 446.898 448.775 447.342 448.216 447.901C447.657 448.46 447.214 449.123 446.911 449.854C446.609 450.584 446.453 451.367 446.453 452.157C446.453 452.948 446.609 453.73 446.911 454.461C447.214 455.191 447.657 455.855 448.216 456.414C448.775 456.973 449.439 457.416 450.169 457.719C450.899 458.021 451.682 458.177 452.473 458.177H621.023C622.62 458.177 624.151 457.543 625.28 456.414C626.409 455.285 627.043 453.754 627.043 452.157C627.043 450.561 626.409 449.029 625.28 447.901C624.151 446.772 622.62 446.137 621.023 446.137H452.473Z" fill="#E6E6E6"/>
                          <path id="Vector_7" d="M452.473 483.182C450.876 483.182 449.345 483.816 448.216 484.945C447.087 486.074 446.453 487.605 446.453 489.201C446.453 490.798 447.087 492.329 448.216 493.458C449.345 494.587 450.876 495.221 452.473 495.221H621.023C622.62 495.221 624.151 494.587 625.28 493.458C626.409 492.329 627.043 490.798 627.043 489.201C627.043 487.605 626.409 486.074 625.28 484.945C624.151 483.816 622.62 483.182 621.023 483.182H452.473Z" fill="#E6E6E6"/>
                          <path id="Vector_8" d="M420.522 422.985C424.614 422.985 427.931 419.668 427.931 415.576C427.931 411.484 424.614 408.167 420.522 408.167C416.43 408.167 413.113 411.484 413.113 415.576C413.113 419.668 416.43 422.985 420.522 422.985Z" fill="#83BEDE"/>
                          <path id="Vector_9" d="M420.522 460.029C424.614 460.029 427.931 456.712 427.931 452.62C427.931 448.528 424.614 445.211 420.522 445.211C416.43 445.211 413.113 448.528 413.113 452.62C413.113 456.712 416.43 460.029 420.522 460.029Z" fill="#E6E6E6"/>
                          <path id="Vector_10" d="M420.522 497.073C424.614 497.073 427.931 493.756 427.931 489.664C427.931 485.572 424.614 482.255 420.522 482.255C416.43 482.255 413.113 485.572 413.113 489.664C413.113 493.756 416.43 497.073 420.522 497.073Z" fill="#E6E6E6"/>
                          <path id="Vector_11" d="M553.881 415.113C553.881 415.903 553.725 416.686 553.423 417.417C553.12 418.147 552.677 418.811 552.118 419.37C551.559 419.928 550.895 420.372 550.165 420.674C549.434 420.977 548.652 421.133 547.861 421.133H452.689C451.202 421.154 449.758 420.638 448.621 419.679C447.485 418.72 446.733 417.382 446.505 415.913C446.393 415.063 446.463 414.198 446.711 413.377C446.96 412.556 447.38 411.798 447.945 411.152C448.51 410.507 449.206 409.989 449.987 409.634C450.767 409.279 451.615 409.095 452.473 409.093H547.861C549.458 409.093 550.989 409.727 552.118 410.856C553.247 411.985 553.881 413.516 553.881 415.113V415.113Z" fill="#83BEDE"/>
                          <path id="Vector_12" d="M520.541 312.315C533.839 312.315 544.62 301.535 544.62 288.237C544.62 274.938 533.839 264.158 520.541 264.158C507.243 264.158 496.462 274.938 496.462 288.237C496.462 301.535 507.243 312.315 520.541 312.315Z" fill="#E6E6E6"/>
                          <path id="Vector_13" d="M532.425 290.165C533.49 283.602 529.033 277.418 522.47 276.353C515.906 275.288 509.722 279.745 508.657 286.308C507.592 292.872 512.049 299.056 518.613 300.121C525.176 301.186 531.36 296.728 532.425 290.165Z" fill="white"/>
                          <path id="Vector_14" d="M41.0709 182.425C39.4887 182.425 37.9713 183.054 36.8525 184.172C35.7337 185.291 35.1052 186.808 35.1052 188.391C35.1052 189.973 35.7337 191.49 36.8525 192.609C37.9713 193.728 39.4887 194.356 41.0709 194.356H265.929C267.511 194.356 269.029 193.728 270.148 192.609C271.266 191.49 271.895 189.973 271.895 188.391C271.895 186.808 271.266 185.291 270.148 184.172C269.029 183.054 267.511 182.425 265.929 182.425H41.0709Z" fill="#E6E6E6"/>
                          <path id="Vector_15" d="M41.0709 219.136C39.4887 219.136 37.9713 219.765 36.8525 220.884C35.7337 222.003 35.1052 223.52 35.1052 225.102C35.1052 226.684 35.7337 228.202 36.8525 229.32C37.9713 230.439 39.4887 231.068 41.0709 231.068H265.929C267.511 231.068 269.029 230.439 270.148 229.32C271.266 228.202 271.895 226.684 271.895 225.102C271.895 223.52 271.266 222.003 270.148 220.884C269.029 219.765 267.511 219.136 265.929 219.136H41.0709Z" fill="#E6E6E6"/>
                          <path id="Vector_16" d="M141.569 257.684C139.987 257.684 138.469 258.312 137.35 259.431C136.232 260.55 135.603 262.067 135.603 263.649C135.603 265.232 136.232 266.749 137.35 267.868C138.469 268.987 139.987 269.615 141.569 269.615H165.431C167.013 269.615 168.531 268.987 169.65 267.868C170.768 266.749 171.397 265.232 171.397 263.649C171.397 262.067 170.768 260.55 169.65 259.431C168.531 258.312 167.013 257.684 165.431 257.684H141.569Z" fill="#83BEDE"/>
                          <path id="Vector_17" d="M121.524 106.675C121.038 106.675 120.571 106.869 120.227 107.213C119.883 107.557 119.689 108.023 119.689 108.51V155.106C119.689 155.593 119.883 156.06 120.227 156.404C120.571 156.748 121.038 156.941 121.524 156.942H185.476C185.962 156.941 186.429 156.748 186.773 156.404C187.117 156.06 187.311 155.593 187.311 155.106V108.51C187.311 108.023 187.117 107.557 186.773 107.213C186.429 106.869 185.962 106.675 185.476 106.675L121.524 106.675Z" fill="#83BEDE"/>
                          <path id="Vector_18" d="M153.5 136.751C159.076 136.751 163.596 132.231 163.596 126.655C163.596 121.079 159.076 116.559 153.5 116.559C147.924 116.559 143.404 121.079 143.404 126.655C143.404 132.231 147.924 136.751 153.5 136.751Z" fill="white"/>
                          <path id="Vector_19" d="M170.02 152.353C170.024 153.949 169.646 155.522 168.919 156.942H138.081C137.295 155.403 136.918 153.688 136.985 151.961C137.052 150.234 137.561 148.553 138.463 147.079C139.366 145.606 140.632 144.388 142.14 143.544C143.648 142.7 145.347 142.257 147.075 142.257H159.924C162.6 142.256 165.167 143.317 167.061 145.209C168.954 147.1 170.019 149.666 170.02 152.342V152.353Z" fill="white"/>
                          <path id="Vector_20" d="M153.5 136.751C159.076 136.751 163.596 132.231 163.596 126.655C163.596 121.079 159.076 116.559 153.5 116.559C147.924 116.559 143.404 121.079 143.404 126.655C143.404 132.231 147.924 136.751 153.5 136.751Z" fill="white"/>
                          <path id="Vector_21" d="M170.02 152.353C170.024 153.949 169.646 155.522 168.919 156.942H138.081C137.295 155.403 136.918 153.688 136.985 151.961C137.052 150.234 137.561 148.553 138.463 147.079C139.366 145.606 140.632 144.388 142.14 143.544C143.648 142.7 145.347 142.257 147.075 142.257H159.924C162.6 142.256 165.167 143.317 167.061 145.209C168.954 147.1 170.019 149.666 170.02 152.342V152.353Z" fill="white"/>
                          <path id="Vector_22" d="M153.5 47.7251C166.679 47.7251 177.363 37.0414 177.363 23.8625C177.363 10.6836 166.679 0 153.5 0C140.321 0 129.637 10.6836 129.637 23.8625C129.637 37.0414 140.321 47.7251 153.5 47.7251Z" fill="#E6E6E6"/>
                          <path id="Vector_23" d="M153.5 35.7937C160.09 35.7937 165.431 30.4519 165.431 23.8624C165.431 17.273 160.09 11.9312 153.5 11.9312C146.911 11.9312 141.569 17.273 141.569 23.8624C141.569 30.4519 146.911 35.7937 153.5 35.7937Z" fill="#83BEDE"/>
                          <path id="Vector_24" d="M748.119 711.24L736.687 711.239L731.25 667.143L748.123 667.145L748.119 711.24Z" fill="#FFB8B8"/>
                          <path id="Vector_25" d="M728.521 707.973H750.569V721.855H714.639C714.639 720.032 714.998 718.226 715.696 716.542C716.393 714.858 717.416 713.328 718.705 712.039C719.994 710.75 721.524 709.727 723.208 709.029C724.893 708.332 726.698 707.973 728.521 707.973V707.973Z" fill="#2F2E41"/>
                          <path id="Vector_26" d="M809.741 711.24L798.308 711.239L792.872 667.143L809.745 667.145L809.741 711.24Z" fill="#FFB8B8"/>
                          <path id="Vector_27" d="M790.142 707.973H812.19V721.855H776.26C776.26 720.032 776.619 718.226 777.317 716.542C778.015 714.858 779.037 713.328 780.326 712.039C781.615 710.75 783.146 709.727 784.83 709.029C786.514 708.332 788.319 707.973 790.142 707.973V707.973Z" fill="#2F2E41"/>
                          <path id="Vector_28" d="M776.26 397.765C789.824 397.765 800.821 386.769 800.821 373.204C800.821 359.64 789.824 348.643 776.26 348.643C762.695 348.643 751.699 359.64 751.699 373.204C751.699 386.769 762.695 397.765 776.26 397.765Z" fill="#FFB8B8"/>
                          <path id="Vector_29" d="M692.277 533.382C692.449 532.022 692.916 530.716 693.647 529.557C694.378 528.397 695.354 527.411 696.507 526.67C697.66 525.928 698.962 525.448 700.32 525.264C701.678 525.08 703.061 525.195 704.369 525.603L718.096 509.149L730.129 515.051L710.419 538.016C709.588 540.169 707.997 541.943 705.945 543.001C703.894 544.059 701.526 544.328 699.29 543.757C697.054 543.186 695.105 541.814 693.812 539.902C692.52 537.99 691.974 535.67 692.277 533.382V533.382Z" fill="#FFB8B8"/>
                          <path id="Vector_30" d="M810.411 550.024C809.826 548.784 809.52 547.431 809.514 546.06C809.509 544.689 809.804 543.334 810.379 542.09C810.954 540.845 811.795 539.742 812.843 538.858C813.89 537.973 815.119 537.33 816.442 536.971L819.198 515.721L832.52 514.247L828.207 544.201C828.661 546.464 828.269 548.815 827.106 550.808C825.943 552.802 824.089 554.299 821.895 555.017C819.701 555.735 817.321 555.623 815.204 554.703C813.088 553.782 811.382 552.118 810.411 550.024L810.411 550.024Z" fill="#FFB8B8"/>
                          <path id="Vector_31" d="M796.786 419.595C798.092 417.918 799.817 416.615 801.788 415.818C803.758 415.021 805.904 414.758 808.009 415.055C810.113 415.352 812.103 416.199 813.775 417.511C815.448 418.822 816.745 420.552 817.536 422.525L837.667 472.765C839.724 477.9 840.38 483.488 839.568 488.959L833.011 533.118C832.926 533.687 832.719 534.232 832.405 534.715C832.09 535.197 831.675 535.606 831.188 535.913C830.701 536.221 830.153 536.419 829.582 536.495C829.011 536.571 828.43 536.523 827.88 536.354L819.24 533.701C818.346 533.426 817.576 532.846 817.065 532.063C816.553 531.279 816.333 530.34 816.442 529.411C819.491 503.414 815.461 477.076 804.778 453.18L795.288 431.952C794.393 429.95 794.059 427.742 794.323 425.565C794.587 423.389 795.438 421.325 796.786 419.595V419.595Z" fill="#83BEDE"/>
                          <path id="Vector_32" d="M782.24 397.764C785.936 397.844 789.608 397.141 793.013 395.701C796.419 394.26 799.481 392.116 801.998 389.408C804.516 386.7 806.432 383.49 807.62 379.989C808.809 376.488 809.243 372.775 808.894 369.094C808.545 365.413 807.422 361.847 805.597 358.632C803.772 355.416 801.287 352.623 798.306 350.436C795.325 348.249 791.914 346.718 788.299 345.943C784.684 345.168 780.945 345.166 777.33 345.939C771.929 342.659 765.649 340.453 759.355 341.014C753.061 341.575 746.837 345.312 744.483 351.176C742.129 357.04 744.658 364.77 750.51 367.153C754.262 368.681 758.499 367.94 762.503 367.324C766.507 366.708 770.874 366.306 774.359 368.372C777.844 370.438 779.41 375.977 776.289 378.56C774.635 379.984 773.454 381.877 772.903 383.989C772.352 386.101 772.457 388.33 773.205 390.381C774.774 394.437 779.054 397.695 782.24 397.764Z" fill="#2F2E41"/>
                          <path id="Vector_33" d="M812.713 532.411L817.069 697.751C817.084 698.302 816.984 698.85 816.777 699.361C816.57 699.872 816.259 700.334 815.864 700.719C815.47 701.104 815 701.404 814.484 701.598C813.968 701.793 813.418 701.879 812.867 701.851L798.515 701.119C797.599 701.072 796.727 700.712 796.045 700.099C795.363 699.486 794.912 698.657 794.768 697.752L777.697 590.363C777.624 589.903 777.393 589.484 777.044 589.176C776.695 588.868 776.249 588.692 775.784 588.678C775.319 588.663 774.864 588.811 774.496 589.097C774.128 589.382 773.872 589.786 773.77 590.241L750.663 693.637C750.539 694.19 750.3 694.71 749.96 695.163C749.621 695.616 749.189 695.992 748.693 696.266C748.197 696.54 747.649 696.705 747.085 696.751C746.52 696.798 745.952 696.723 745.419 696.534L731.175 695.664C730.351 695.371 729.646 694.816 729.167 694.084C728.689 693.352 728.463 692.483 728.525 691.611L740.959 530.846L812.713 532.411Z" fill="#2F2E41"/>
                          <path id="Vector_34" d="M819.705 453.881C822.108 428.825 803.685 406.163 778.578 404.38C767.761 403.612 750.966 403.337 745.43 417.516C728.753 460.23 757.948 459.192 747.889 496.199C737.83 533.206 728.946 535.327 742.748 538C756.549 540.672 818.614 556.447 816.066 530.399C814.499 514.389 817.308 478.873 819.705 453.881Z" fill="#83BEDE"/>
                          <path id="Vector_35" d="M748.383 413.172C750.365 412.404 752.515 412.174 754.615 412.502C756.715 412.831 758.691 413.708 760.344 415.045C761.997 416.382 763.267 418.131 764.028 420.116C764.789 422.101 765.013 424.251 764.677 426.35L756.838 475.341C755.497 483.718 751.955 491.589 746.573 498.148L721.113 529.172C720.748 529.617 720.291 529.978 719.773 530.23C719.255 530.483 718.689 530.62 718.113 530.633C717.537 530.646 716.965 530.535 716.436 530.307C715.907 530.079 715.434 529.739 715.049 529.311L709.001 522.595C708.375 521.9 708.012 521.007 707.977 520.071C707.941 519.136 708.234 518.218 708.805 517.477L710.586 515.165C725.397 495.944 734.741 473.079 737.631 448.986L740.747 423.002C741.008 420.825 741.857 418.76 743.202 417.028C744.547 415.296 746.338 413.963 748.383 413.172V413.172Z" fill="#83BEDE"/>
                          <path id="Vector_36" d="M882.708 724.225H624.708C624.443 724.225 624.188 724.12 624.001 723.932C623.813 723.745 623.708 723.491 623.708 723.225C623.708 722.96 623.813 722.706 624.001 722.518C624.188 722.331 624.443 722.225 624.708 722.225H882.708C882.973 722.225 883.228 722.331 883.415 722.518C883.603 722.706 883.708 722.96 883.708 723.225C883.708 723.491 883.603 723.745 883.415 723.932C883.228 724.12 882.973 724.225 882.708 724.225Z" fill="#CCCCCC"/>
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="883.708" height="724.225" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>

                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="6" class="pb-0">
                      <v-text-field
                          label="Email del contacto administrativo"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-at"
                          dense
                          hint="¿A qué casilla nos contactaremos? "
                          persistent-hint
                          v-model="initialData.administrativeContactEmail"
                          class="mb-4"
                          :rules="emailRules"
                      />
                      <v-text-field
                          label="Teléfono del contacto"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-phone"
                          dense
                          hint="¿A que número telefónico nos contactaremos?"
                          persistent-hint
                          v-model="initialData.administrativeContactPhone"
                          class="mb-4"
                          :rules="phoneRules"

                      />
                    </v-col>
                    <v-col md="6" class="pb-0">
                      <v-autocomplete
                          v-model="initialData.accountCountry"
                          :items="countries"
                          outlined
                          label="País"
                          prepend-inner-icon="mdi-map-marker"
                          hint="¿En qué país se encuentra? Escriba para buscar en la lista."
                          persistent-hint
                          dense
                          class="mb-4"
                          :rules="countryRules"


                      />
                      <v-text-field
                          label="Plan asignado"
                          outlined
                          prepend-inner-icon="mdi-airplane-takeoff"
                          dense
                          disabled
                          v-model="initialData.accountPlan"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </div>
          </v-window-item>

          <v-window-item :value="2">
            <div class="px-4 pt-6 pb-0 text-center">
              <v-container>
                <v-form ref="form" v-model="validSecondStepForm">
                  <v-row >
                    <v-col md="6">
                      <v-text-field
                          label="URL Tienda Online"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-cart"
                          dense
                          hint="Carga la URL de tu tienda online."
                          persistent-hint
                          v-model="initialData.businessInfo.shopURL"
                          class="mb-4"
                          :rules="shopDomainRules"
                      />
                      <v-text-field
                          label="URL Página de Facebook"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-facebook"
                          dense
                          hint="Carga la URL de tu página de Facebook."
                          persistent-hint
                          v-model="initialData.businessInfo.facebookURL"
                          class="mb-4"
                          :rules="facebookDomainRules"

                      />
                      <v-text-field
                          label="URL Perfíl de Instagram"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-instagram"
                          dense
                          hint="Carga la URL de tu perfil de Instagram."
                          persistent-hint
                          v-model="initialData.businessInfo.instagramURL"
                          class="mb-4"
                          :rules="instagramDomainRules"

                      />


                    </v-col>
                    <v-spacer></v-spacer>

                    <v-col md="6" >
                      <v-autocomplete
                          :items="workAreas"
                          label="Rubro"
                          outlined
                          prepend-inner-icon="mdi-store-outline"
                          dense
                          class="mb-4"
                          hint="Elija el rubro al que pertenezca el negocio."
                          persistent-hint
                          v-model="initialData.businessInfo.workArea"

                      ></v-autocomplete>
                      <v-combobox
                          v-model="initialData.businessInfo.featuredInfo"
                          hide-selected
                          hint="Agrega información que creas que pueda destacar a tu negocio (cuotas sin interés, envío gratis, retiro por local, etc.)"
                          label="Información Destacada"
                          multiple
                          persistent-hint
                          prepend-inner-icon="mdi-information"
                          small-chips
                          outlined
                          deletable-chips
                          class="mb-4"
                          :rules="featuredInfoRules"


                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Presiona enter <kbd>enter</kbd> para agregar uno nuevo
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>

                      <v-combobox
                          v-model="initialData.businessInfo.competition"
                          hide-selected
                          hint="Agrega hasta 10 nombres de competidores."
                          label="Tu competencia"
                          multiple
                          persistent-hint
                          prepend-inner-icon="mdi-compare-horizontal"
                          small-chips
                          outlined
                          deletable-chips
                          :rules="competitionRules"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Presiona enter <kbd>enter</kbd> para agregar uno nuevo
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>

                    </v-col>
                    <v-col md="12">
                      <v-textarea
                          outlined
                          dense
                          label="Descripción de tu negocio"
                          prepend-inner-icon="mdi-text"
                          v-model="initialData.businessInfo.description"
                          :rules="descriptionRules"
                      />
                    </v-col>
                  </v-row>

                </v-form>

              </v-container>
            </div>
          </v-window-item>
          <v-window-item :value="3">
            <div class="pa-4 text-center">
              <svg height="250" viewBox="0 0 1117 858" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="online-shop 1" clip-path="url(#clip0)">
                  <path id="Vector" opacity="0.1" d="M1089.16 582.74C1088.16 583.47 1087.24 584.18 1086.26 584.87C1082.64 587.427 1078.86 589.736 1074.93 591.78C1054.35 602.63 1028.98 607.93 1004.27 611.53C948.85 619.61 892.27 621.18 835.93 622.72C683.7 626.9 531.1 631.07 379.24 621.44C319.24 617.64 257.92 611.28 203.55 591.78C195.757 588.993 188.117 585.92 180.63 582.56C165.48 575.75 150.93 567.46 138.83 557.56C127.46 548.28 118.24 537.62 112.73 525.5C101.24 500.26 107.32 471.91 120.44 447.17C125.889 437.161 132.034 427.548 138.83 418.4C148.96 404.47 160.13 390.94 170.32 377.02C206.58 327.54 230.24 264.94 196.77 214.19C187.15 199.61 173.25 186.81 165.41 171.59C155.41 152.12 156.18 130.5 159.36 109.5C159.773 106.833 160.21 104.193 160.67 101.58C166.1 70.44 176.32 36.89 207.83 17.45C240.56 -2.74999 287.04 -1.72999 327.66 5.45001C390.83 16.73 449.1 40.21 507.99 61.41C566.88 82.61 628.49 101.98 693.21 104.06C750.36 105.9 817.02 92.13 854.95 56.34C891.79 21.58 961.04 18.93 1010.95 39.93C1045.21 54.34 1070.77 80.3 1081.55 109.5C1083.73 115.362 1085.3 121.435 1086.24 127.62C1087.1 133.273 1087.39 139 1087.08 144.71C1086.93 147.53 1086.64 150.32 1086.24 153.08C1077.9 210.7 1017.87 256.15 981.38 307.62C963.55 332.76 950.96 362.96 963.22 390.07C981.92 431.38 1046.95 446.07 1086.22 476.81C1093.61 482.405 1100.12 489.079 1105.52 496.61C1124.57 523.97 1117.54 560.92 1089.16 582.74Z" fill="#4CAF50"/>
                  <path id="Vector_2" opacity="0.1" d="M408.16 851.7C469.756 851.7 519.69 845.199 519.69 837.18C519.69 829.161 469.756 822.66 408.16 822.66C346.564 822.66 296.63 829.161 296.63 837.18C296.63 845.199 346.564 851.7 408.16 851.7Z" fill="#4CAF50"/>
                  <g id="browser">
                    <path id="Vector_3" d="M1088.1 584.67V95.54H136.75V556.61C148.9 566.75 163.51 575.28 178.75 582.29C186.257 585.75 193.923 588.913 201.75 591.78H1076.75L1088.1 584.67Z" fill="url(#paint0_linear)"/>
                    <path id="Vector_4" d="M1086.26 584.87V109.54H138.83V557.64C150.93 567.5 165.48 575.79 180.63 582.64C188.11 585.973 195.75 589.047 203.55 591.86H1074.93L1086.26 584.87Z" fill="white"/>
                    <path id="Vector_5" d="M1086.26 96.93H138.84V122.07H1086.26V96.93Z" fill="#5A5773"/>
                  </g>
                  <g id="buttons">
                    <path id="Vector_6" d="M157.76 116C161.35 116 164.26 113.09 164.26 109.5C164.26 105.91 161.35 103 157.76 103C154.17 103 151.26 105.91 151.26 109.5C151.26 113.09 154.17 116 157.76 116Z" fill="white"/>
                    <path id="Vector_7" d="M175.26 116C178.85 116 181.76 113.09 181.76 109.5C181.76 105.91 178.85 103 175.26 103C171.67 103 168.76 105.91 168.76 109.5C168.76 113.09 171.67 116 175.26 116Z" fill="white"/>
                    <path id="Vector_8" d="M192.76 116C196.35 116 199.26 113.09 199.26 109.5C199.26 105.91 196.35 103 192.76 103C189.17 103 186.26 105.91 186.26 109.5C186.26 113.09 189.17 116 192.76 116Z" fill="white"/>
                  </g>
                  <g id="boxes">
                    <path id="Vector_9" d="M537.45 177.32H380.95V275.66H537.45V177.32Z" fill="#4CAF50"/>
                    <path id="Vector_10" opacity="0.2" d="M320.76 177.32H164.26V275.66H320.76V177.32Z" fill="#5A5773"/>
                    <path id="Vector_11" opacity="0.2" d="M754.14 177.32H597.64V275.66H754.14V177.32Z" fill="#5A5773"/>
                    <path id="Vector_12" opacity="0.2" d="M320.76 317.91H164.26V416.25H320.76V317.91Z" fill="#5A5773"/>
                    <path id="Vector_13" opacity="0.2" d="M537.45 317.91H380.95V416.25H537.45V317.91Z" fill="#5A5773"/>
                    <path id="Vector_14" opacity="0.2" d="M754.14 317.91H597.64V416.25H754.14V317.91Z" fill="#5A5773"/>
                    <path id="Vector_15" opacity="0.2" d="M320.76 458.49H164.26V556.83H320.76V458.49Z" fill="#5A5773"/>
                    <path id="Vector_16" opacity="0.2" d="M537.45 458.49H380.95V556.83H537.45V458.49Z" fill="#5A5773"/>
                    <path id="Vector_17" opacity="0.2" d="M754.14 458.49H597.64V556.83H754.14V458.49Z" fill="#5A5773"/>
                  </g>
                  <g id="woman">
                    <path id="Vector_18" d="M499.1 263.46L488.3 267.28L484.38 260.78C481.38 255.78 477.22 248.94 474.15 243.97C468.73 235.23 475.82 221.91 475.82 221.91C475.82 221.91 477.1 208.54 481.66 204.42C486.22 200.3 487.49 221.91 487.49 221.91C505.8 230.23 495.99 249.8 495.99 249.8L497.77 257.56L499.1 263.46Z" fill="#EFB7B9"/>
                    <path id="Vector_19" opacity="0.1" d="M499.1 263.46L488.3 267.28L484.38 260.78V260.21C484.38 260.21 490.57 255.96 497.79 257.56L499.1 263.46Z" fill="black"/>
                    <path id="Vector_20" d="M481.66 307.26L487.07 302.68L484.36 261.46C484.36 261.46 498.36 251.88 508.72 266.87C508.72 266.87 508.3 276.03 508.72 278.53C509.14 281.03 505.81 283.53 508.72 286.02C511.63 288.51 508.72 296.85 508.72 296.85C508.72 296.85 512.01 302.68 514.09 304.34C516.17 306 518.26 319.75 514.09 322.66C509.92 325.57 502.1 332.24 502.1 335.15C502.1 338.06 492.1 345.15 492.1 345.15L483.32 333.54L481.66 307.26Z" fill="#FF6484"/>
                    <path id="Vector_21" d="M364.66 557.48C344.48 548.92 348.96 528.01 352.76 517.48C353.519 515.347 354.411 513.263 355.43 511.24L366.75 504.37C366.75 504.37 369.06 510.26 371.53 518.16C377.26 536.54 383.85 565.63 364.66 557.48Z" fill="#EFB7B9"/>
                    <path id="Vector_22" d="M392.4 748.27C391.034 750.932 390.025 753.764 389.4 756.69C388.401 761.398 387.965 766.209 388.1 771.02C388.18 774.35 388.46 776.53 388.46 776.53L365.98 791.99C365.98 791.99 366.11 790.18 366.33 787.52C366.91 780.44 368.12 767.3 369.26 765.6C370.05 764.42 370.6 759.68 370.95 755.28C371.3 750.88 371.44 747.02 371.44 747.02C371.44 747.02 398.49 736.88 392.4 748.27Z" fill="#EFB7B9"/>
                    <path id="Vector_23" d="M445.49 759.36C445.49 759.36 445.49 763.66 445.33 768.66C445.17 773.66 444.87 779.56 444.24 782.31C443.66 784.83 443.62 789.46 443.73 793.73C443.88 798.73 444.24 803.23 444.24 803.23C444.24 803.23 421.29 781.53 423.63 779.81C424.26 779.35 424.88 777.5 425.45 775.04C426.15 772.04 426.78 768.04 427.25 764.64C427.81 760.59 428.16 757.33 428.16 757.33L445.49 759.36Z" fill="#EFB7B9"/>
                    <path id="Vector_24" opacity="0.1" d="M392.4 748.27C391.034 750.932 390.025 753.764 389.4 756.69C383.247 756.529 377.106 756.058 371 755.28C371.34 750.98 371.49 747.02 371.49 747.02C371.49 747.02 398.49 736.88 392.4 748.27Z" fill="black"/>
                    <path id="Vector_25" opacity="0.1" d="M445.49 759.36C445.49 759.36 445.49 763.66 445.33 768.66C438.17 770.49 430.81 766.83 427.25 764.66C427.81 760.61 428.16 757.35 428.16 757.35L445.49 759.36Z" fill="black"/>
                    <path id="Vector_26" d="M492.48 546.66C483.32 566.66 479.57 590.38 479.57 590.38L476.24 610.38C476.24 610.38 477.91 619.12 476.24 622.45C474.57 625.78 471.24 631.2 471.24 631.2C471.24 631.2 473.74 651.6 471.24 653.68C468.74 655.76 471.24 668.25 471.24 668.25L462.91 710.25L451.25 764.37C440.01 773.54 425.05 761.88 425.05 761.88L428.35 726.49C428.35 726.49 433.35 664.49 431.27 661.95C429.19 659.41 432.1 651.54 432.1 651.54C432.1 651.54 435.02 644.04 432.94 641.13C430.86 638.22 433.35 631.97 433.35 631.97C433.35 631.97 432.52 606.57 435.02 600.74C437.52 594.91 435.02 558.85 435.02 558.85L434.02 547.62L433.44 541.4L433.39 540.79C433.39 540.79 433.01 543.28 432.39 547.14C430.55 558.89 426.63 583.24 425.08 588.25C424.026 591.86 422.595 595.35 420.81 598.66C420.81 598.66 422.97 611.15 420.81 614.48C418.65 617.81 420.48 621.56 420.48 621.56C420.48 621.56 422.56 631.97 416.31 636.13C410.06 640.29 416.31 642.38 416.31 642.38C416.31 642.38 411.73 652.38 410.07 656.53C408.41 660.68 410.07 666.53 410.07 666.53L406.74 700.25C406.74 700.25 403.82 714.4 404.24 723.98C404.66 733.56 400.49 755.62 400.49 755.62C400.49 755.62 368.43 755.62 363.49 751.87C358.55 748.12 371.74 711.54 371.74 711.54C371.74 711.54 375.49 647.42 374.65 645.76C373.81 644.1 374.65 631.6 377.57 629.11C380.49 626.62 377.57 621.61 377.57 621.61C377.57 621.61 376.74 611.61 377.57 609.12C378.4 606.63 377.44 598.29 377.44 598.29C377.44 598.29 374.65 577.89 374.24 566.23C373.83 554.57 371.74 528.35 371.74 528.35C371.74 528.35 373.92 512.13 374.25 504.41C374.37 501.57 375.64 498.02 377.25 494.68C379.052 490.909 381.124 487.273 383.45 483.8C383.45 483.8 482.1 454.64 486.24 457.54C487.34 458.31 488.94 463.54 490.52 471.11C494.92 492.26 499.22 531.96 492.48 546.66Z" fill="#444053"/>
                    <path id="Vector_27" d="M431.7 292.19C447.567 292.19 460.43 279.327 460.43 263.46C460.43 247.593 447.567 234.73 431.7 234.73C415.833 234.73 402.97 247.593 402.97 263.46C402.97 279.327 415.833 292.19 431.7 292.19Z" fill="#A1616A"/>
                    <path id="Vector_28" opacity="0.1" d="M490.52 471.13C487.21 472.83 484.99 474 484.99 474C484.99 474 438.36 496.07 425.1 494.82C414.84 493.82 388.65 494.39 377.24 494.67C379.042 490.899 381.114 487.263 383.44 483.79C383.44 483.79 482.1 454.64 486.24 457.54C487.34 458.33 488.94 463.54 490.52 471.13Z" fill="black"/>
                    <path id="Vector_29" d="M411.71 309.34C411.71 309.34 385.1 313.54 378.82 317.66C372.54 321.78 360.1 329.74 360.1 346.81C360.1 363.88 357.19 381.37 355.52 386.36C353.85 391.35 381.75 431.74 381.75 431.74L371.75 492.95C371.75 492.95 411.75 491.7 425.07 492.95C438.39 494.2 485 472.13 485 472.13C485 472.13 512.48 457.56 518.31 457.13C524.14 456.7 506.65 425.07 506.65 425.07C506.65 425.07 479.58 373.87 484.16 365.54C488.74 357.21 492.1 345.14 492.1 345.14L487.1 302.68C487.1 302.68 479.61 300.18 473.36 304.76C467.11 309.34 445.88 306.84 445.88 306.84L411.71 309.34Z" fill="#FF6484"/>
                    <path id="Vector_30" opacity="0.1" d="M366.54 383.45L356.1 386.36L353.21 425.54C353.21 425.54 356.96 435.95 353.21 439.28C349.46 442.61 348.63 513.81 348.63 513.81C348.63 513.81 360.71 522.55 374.86 515.89C374.86 515.89 372.78 507.98 379.03 479.25C385.28 450.52 385.27 435.95 385.27 435.95C385.27 435.95 385.27 400.14 388.19 394.31C391.11 388.48 388.19 373.91 388.19 373.91C388.19 373.91 382.36 366 382.77 363.09C383.18 360.18 366.54 383.45 366.54 383.45Z" fill="black"/>
                    <path id="Vector_31" opacity="0.1" d="M371.53 518.19C365.399 520.254 358.726 520.012 352.76 517.51C353.519 515.377 354.411 513.292 355.43 511.27L366.75 504.4C366.75 504.4 369.1 510.29 371.53 518.19Z" fill="black"/>
                    <path id="Vector_32" d="M365.91 383.45L355.5 386.36L352.59 425.54C352.59 425.54 356.34 435.95 352.59 439.28C348.84 442.61 348.01 513.81 348.01 513.81C348.01 513.81 360.1 522.54 374.24 515.85C374.24 515.85 372.16 507.94 378.4 479.21C384.64 450.48 384.65 435.91 384.65 435.91C384.65 435.91 384.65 400.1 387.56 394.27C390.47 388.44 387.56 373.87 387.56 373.87C387.56 373.87 381.73 365.96 382.15 363.05C382.57 360.14 365.91 383.45 365.91 383.45Z" fill="#FF6484"/>
                    <path id="Vector_33" opacity="0.1" d="M472.5 389.69C472.5 389.69 467.09 436.32 451.26 445.07C451.26 445.07 486.65 435.08 472.5 389.69Z" fill="black"/>
                    <path id="Vector_34" opacity="0.1" d="M392.98 393.86C392.98 393.86 412.54 403.43 407.98 410.09C403.42 416.75 392.98 393.86 392.98 393.86Z" fill="black"/>
                    <path id="Vector_35" opacity="0.1" d="M392.98 413.01C392.98 413.01 405.47 425.92 402.98 430.5C400.49 435.08 392.98 413.01 392.98 413.01Z" fill="black"/>
                    <path id="Vector_36" opacity="0.1" d="M493.73 306.01C493.73 306.01 498.63 314.33 499.1 319.75C499.57 325.17 493.73 306.01 493.73 306.01Z" fill="black"/>
                    <path id="Vector_37" opacity="0.1" d="M493.73 294.77C493.73 294.77 496.19 292.68 502.47 294.77C508.75 296.86 493.73 294.77 493.73 294.77Z" fill="black"/>
                    <path id="Vector_38" opacity="0.1" d="M469.69 538.12C459.374 539.583 448.854 538.236 439.24 534.22L433.99 547.66L433.41 541.44C434.27 536.22 435.94 530.63 439.24 529.22C439.24 529.22 450.8 538.43 469.69 538.12Z" fill="black"/>
                    <path id="Vector_39" opacity="0.1" d="M384.28 555.61C384.28 555.61 409.89 560.92 417.07 559.98L384.28 555.61Z" fill="black"/>
                    <path id="Vector_40" opacity="0.1" d="M386.94 562.32C386.94 562.32 397.87 561.85 403.33 564.04L386.94 562.32Z" fill="black"/>
                    <path id="Vector_41" opacity="0.1" d="M388.19 566.85C393.177 567.909 398.239 568.578 403.33 568.85L388.19 566.85Z" fill="black"/>
                    <path id="Vector_42" opacity="0.1" d="M387.56 624.15C388.03 623.84 409.42 618.84 412.08 622.43L387.56 624.15Z" fill="black"/>
                    <path id="Vector_43" opacity="0.1" d="M387.56 630.71C387.56 630.71 406.14 628.52 406.61 630.71C407.08 632.9 387.56 630.71 387.56 630.71Z" fill="black"/>
                    <path id="Vector_44" opacity="0.1" d="M387.56 636.64C387.56 636.64 407.39 632.54 409.73 633.54C412.07 634.54 387.56 636.64 387.56 636.64Z" fill="black"/>
                    <path id="Vector_45" opacity="0.1" d="M388.5 776.54L366.02 792C366.02 792 366.15 790.19 366.37 787.53C371.37 775.23 377.88 768.74 377.88 768.74C382.95 767.42 386.15 768.64 388.14 771.03C388.22 774.35 388.5 776.54 388.5 776.54Z" fill="black"/>
                    <path id="Vector_46" d="M390.69 790.43C387.513 797.677 385.915 805.518 386 813.43C386.127 819.918 384.481 826.318 381.24 831.94C380.771 832.732 380.243 833.488 379.66 834.2C379.66 834.2 364.04 834.07 358.57 829.54C357.955 829.054 357.454 828.439 357.1 827.74C353.97 821.5 363.19 797.61 363.19 797.61C368.03 779.19 377.86 769.35 377.86 769.35C395.37 764.82 390.69 790.43 390.69 790.43Z" fill="#444053"/>
                    <path id="Vector_47" opacity="0.1" d="M381.24 831.89C380.771 832.682 380.243 833.438 379.66 834.15C379.66 834.15 364.04 834.02 358.57 829.49C361.26 820.59 369.84 793.66 373.86 798.24C377.64 802.54 380.27 823.23 381.24 831.89Z" fill="black"/>
                    <path id="Vector_48" d="M379.1 799.91L378.01 840.25H383.25C383.25 840.25 384.1 804.86 386.44 799.91C388.78 794.96 379.1 799.91 379.1 799.91Z" fill="#444053"/>
                    <path id="Vector_49" opacity="0.1" d="M444.24 803.23C444.24 803.23 421.29 781.53 423.63 779.81C424.26 779.35 424.88 777.5 425.45 775.04C429.86 774.27 437.45 776.11 443.73 793.73C443.88 798.73 444.24 803.23 444.24 803.23Z" fill="black"/>
                    <path id="Vector_50" d="M447.67 827.43C447.494 827.506 447.309 827.559 447.12 827.59C444.42 828.06 434.47 827.79 428.9 827.59C427.879 827.556 426.912 827.128 426.201 826.395C425.49 825.661 425.092 824.681 425.09 823.66C425.09 822.16 425.09 820.33 424.97 818.38C424.71 812.22 423.97 804.89 421.76 803.24C417.85 800.24 414.76 790.06 421.76 777.17C421.76 777.17 435.18 766.92 444.86 797.73C444.86 797.73 445.8 806.52 450.01 809.64C454.22 812.76 449.55 826.54 447.67 827.43Z" fill="#444053"/>
                    <path id="Vector_51" opacity="0.1" d="M447.1 827.59C444.4 828.06 434.45 827.79 428.88 827.59C427.859 827.556 426.892 827.128 426.181 826.395C425.47 825.661 425.072 824.681 425.07 823.66C425.07 822.16 425.07 820.33 424.95 818.38C426.64 810.92 429.07 803.24 432.2 801.49C432.22 801.48 441.69 800.41 447.1 827.59Z" fill="black"/>
                    <path id="Vector_52" d="M423.46 800.58L425.98 834.15H429.18V797.72L423.46 800.58Z" fill="#444053"/>
                    <path id="Vector_53" opacity="0.1" d="M431.28 217.68C422.209 218.355 413.428 221.176 405.66 225.91C403.66 227.12 401.66 228.59 400.83 230.76C400.11 232.64 400.41 234.76 400.44 236.76C400.71 253.98 381.51 267.01 382.11 284.22C382.35 291.05 385.78 297.41 386.41 304.22C387.46 315.58 380.74 326.03 374.3 335.44C380.935 335.844 387.47 333.677 392.55 329.39C399.345 352.04 400.223 376.054 395.1 399.14H404.31C405.22 399.074 406.133 399.186 407 399.47C407.808 399.854 408.532 400.394 409.13 401.06C417.74 409.63 423.51 422.3 420.22 434L439.82 426.65C444.51 424.89 449.27 423.1 453.35 420.2C457.43 417.3 460.85 413.09 461.71 408.15C462.24 405.15 462.07 401.57 464.54 399.83C465.757 399.183 467.055 398.702 468.4 398.4C473.78 396.5 476.16 390.28 477.26 384.68C478.599 377.809 479.02 370.791 478.51 363.81C477.64 352.11 474.18 340.23 477.03 328.81C478.64 322.4 482.22 316.43 482.51 309.81C482.98 298.9 474.45 289.5 473.18 278.65C472.29 271.09 475.02 263.44 474.13 255.88C473.636 251.978 472.208 248.253 469.968 245.02C467.727 241.787 464.74 239.143 461.26 237.31C458.85 236.07 456.26 235.24 453.88 233.87C448.45 230.72 444.98 225.05 440.05 221.15C435.12 217.25 426.91 215.78 423.2 220.85L431.28 217.68Z" fill="black"/>
                    <path id="Vector_54" d="M431.28 216.43C422.209 217.105 413.428 219.926 405.66 224.66C403.66 225.87 401.66 227.34 400.83 229.51C400.11 231.39 400.41 233.51 400.44 235.51C400.71 252.73 381.51 265.76 382.11 282.98C382.35 289.8 385.78 296.16 386.41 302.98C387.46 314.34 380.74 324.79 374.3 334.21C380.933 334.599 387.463 332.434 392.55 328.16C399.344 350.804 400.222 374.811 395.1 397.89H404.31C405.22 397.827 406.134 397.942 407 398.23C407.81 398.607 408.535 399.144 409.13 399.81C417.74 408.39 423.51 421.05 420.22 432.75L439.82 425.4C444.51 423.64 449.27 421.85 453.35 418.95C457.43 416.05 460.85 411.84 461.71 406.9C462.24 403.9 462.07 400.32 464.54 398.58C465.757 397.933 467.055 397.452 468.4 397.15C473.78 395.25 476.16 389.03 477.26 383.43C478.611 376.554 479.042 369.529 478.54 362.54C477.67 350.84 474.21 338.96 477.06 327.54C478.67 321.13 482.25 315.16 482.54 308.54C483.01 297.63 474.48 288.23 473.21 277.38C472.32 269.82 475.05 262.18 474.16 254.61C473.664 250.709 472.236 246.984 469.996 243.751C467.755 240.519 464.769 237.874 461.29 236.04C458.88 234.8 456.29 233.97 453.91 232.61C448.48 229.45 445.01 223.78 440.08 219.88C435.15 215.98 426.94 214.51 423.23 219.58L431.28 216.43Z" fill="#925978"/>
                    <g id="Group" opacity="0.1">
                      <path id="Vector_55" opacity="0.1" d="M400.42 231.35C400.38 231.735 400.36 232.123 400.36 232.51C400.39 232.13 400.41 231.74 400.42 231.35Z" fill="black"/>
                      <path id="Vector_56" opacity="0.1" d="M473.1 271.38C473.46 266.08 474.64 260.76 474.2 255.48C473.87 260.78 472.69 266.1 473.1 271.38Z" fill="black"/>
                      <path id="Vector_57" opacity="0.1" d="M386.41 297.73C385.83 291.53 382.94 285.73 382.26 279.54C382.124 280.675 382.074 281.818 382.11 282.96C382.35 289.71 385.7 296.02 386.38 302.74C386.545 301.075 386.555 299.397 386.41 297.73Z" fill="black"/>
                      <path id="Vector_58" opacity="0.1" d="M477.1 322.36C476.164 326.429 475.827 330.613 476.1 334.78C476.2 332.35 476.535 329.935 477.1 327.57C478.71 321.16 482.29 315.19 482.58 308.57C482.627 307.394 482.574 306.217 482.42 305.05C481.64 311 478.53 316.54 477.1 322.36Z" fill="black"/>
                      <path id="Vector_59" opacity="0.1" d="M392.55 322.9C388.379 326.395 383.217 328.494 377.79 328.9C376.65 330.68 375.47 332.42 374.3 334.14C380.933 334.529 387.463 332.365 392.55 328.09C396.203 340.246 398.162 352.848 398.37 365.54C398.667 351.11 396.703 336.723 392.55 322.9V322.9Z" fill="black"/>
                      <path id="Vector_60" opacity="0.1" d="M477.29 378.19C476.19 383.79 473.81 390.01 468.43 391.9C467.083 392.2 465.784 392.685 464.57 393.34C462.1 395.08 462.27 398.68 461.74 401.66C460.88 406.6 457.47 410.8 453.38 413.66C449.29 416.52 444.54 418.36 439.85 420.12L421.15 427.12C421.053 428.997 420.751 430.858 420.25 432.67L439.85 425.32C444.54 423.56 449.3 421.77 453.38 418.87C457.46 415.97 460.88 411.76 461.74 406.82C462.27 403.82 462.1 400.24 464.57 398.5C465.787 397.853 467.085 397.372 468.43 397.07C473.81 395.17 476.19 388.95 477.29 383.35C478.429 377.522 478.902 371.584 478.7 365.65C478.568 369.861 478.097 374.055 477.29 378.19V378.19Z" fill="black"/>
                      <path id="Vector_61" opacity="0.1" d="M409.16 394.54C408.565 393.871 407.84 393.33 407.03 392.95C406.163 392.666 405.25 392.551 404.34 392.61H396.17C395.85 394.35 395.51 396.09 395.17 397.82H404.38C405.29 397.757 406.204 397.873 407.07 398.16C407.88 398.537 408.605 399.074 409.2 399.74C415.5 406.02 420.27 414.48 421.09 423.16C422.1 412.78 416.72 402.1 409.16 394.54Z" fill="black"/>
                    </g>
                    <path id="Vector_62" opacity="0.1" d="M363.47 428.62C363.47 428.62 370.91 425.5 372.57 428.62C374.23 431.74 363.47 428.62 363.47 428.62Z" fill="black"/>
                    <path id="Vector_63" opacity="0.1" d="M366.1 433.97C366.1 433.97 373.54 430.85 375.21 433.97C376.88 437.09 366.1 433.97 366.1 433.97Z" fill="black"/>
                  </g>
                  <path id="Vector_64" d="M888.53 408.91C885.7 408.91 882.933 409.749 880.58 411.322C878.227 412.894 876.392 415.129 875.309 417.744C874.226 420.359 873.943 423.236 874.495 426.012C875.047 428.788 876.41 431.337 878.411 433.339C880.413 435.34 882.962 436.703 885.738 437.255C888.514 437.807 891.391 437.524 894.006 436.441C896.621 435.358 898.856 433.523 900.428 431.17C902.001 428.817 902.84 426.05 902.84 423.22C902.829 419.428 901.318 415.794 898.637 413.113C895.956 410.432 892.322 408.921 888.53 408.91V408.91ZM846.69 296.62V310.93H861L886.76 363.11L875.99 380.64C874.814 382.713 874.198 385.057 874.2 387.44C874.2 397.02 880.75 401.75 889.63 401.75H973.3V387.99H891.38C891.141 388.005 890.902 387.968 890.678 387.884C890.454 387.799 890.251 387.667 890.082 387.498C889.913 387.329 889.781 387.126 889.696 386.902C889.612 386.678 889.575 386.439 889.59 386.2C889.779 385.581 890.033 384.984 890.35 384.42L897.5 373.13H950.81C953.386 373.151 955.917 372.461 958.126 371.136C960.335 369.811 962.136 367.902 963.33 365.62L989.1 321.31C989.602 320.185 989.848 318.962 989.82 317.73C989.81 316.809 989.615 315.9 989.247 315.056C988.88 314.211 988.346 313.45 987.678 312.816C987.011 312.181 986.222 311.688 985.36 311.364C984.498 311.04 983.58 310.892 982.66 310.93H876.75L869.95 296.62H846.69ZM958.98 408.91C956.15 408.912 953.384 409.753 951.032 411.327C948.681 412.9 946.848 415.136 945.766 417.751C944.685 420.366 944.403 423.243 944.956 426.018C945.509 428.793 946.873 431.342 948.875 433.342C950.877 435.343 953.426 436.704 956.202 437.256C958.977 437.807 961.854 437.523 964.469 436.44C967.083 435.356 969.317 433.522 970.889 431.169C972.461 428.816 973.3 426.05 973.3 423.22C973.289 419.426 971.777 415.791 969.093 413.109C966.41 410.428 962.774 408.918 958.98 408.91V408.91Z" fill="#4CAF50"/>
                  <path id="Vector_65" d="M940.67 336.21H928.67V324.21H919.67V336.21H907.67V345.21H919.67V357.21H928.67V345.21H940.67V336.21Z" fill="white"/>
                </g>
                <defs>
                  <linearGradient id="paint0_linear" x1="612.42" y1="591.78" x2="612.42" y2="95.54" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#808080" stop-opacity="0.25"/>
                    <stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
                    <stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
                  </linearGradient>
                  <clipPath id="clip0">
                    <rect width="1116.21" height="857.08" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              <h3 class=" font-weight-regular mb-1">
                Genial, ahora solo queda sincronizar tu tienda online (Opcional)
              </h3>
              <span class="caption grey--text">Puedes optar por sincronizar tu tienda online o no. Para ello, primero deberás <strong> validar la URL del XML provisto por tu tienda.</strong>.</span><br>
              <v-row class="d-flex justify-center mt-2">
                <v-col md="6">
                  <div class="d-flex flex-column">
                    <v-btn  :color="uploadXML?'orange lighten-1':'primary'" dark outlined @click="toggleXmlUpload" >
                      <v-icon left>
                        {{uploadXML?'mdi-table-cancel':'mdi-upload'}}
                      </v-icon>
                      {{uploadXML?'No deseo cargar mi catálogo de productos':'Cargar mis productos en formato XML'}}
                    </v-btn>
                    <div v-if="uploadXML" class="mt-5 d-flex">
                      <v-text-field
                          class="mb-6"
                          label="URL XML de la tienda online"
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-cloud-tags"
                          dense
                          hint="Carga la URL del XML generado por tu tienda online."
                          persistent-hint
                          v-model="initialData.businessInfo.xmlURL"
                          :loading="loadingXMLResponse"
                          @change="validXML=false"
                      ></v-text-field>
                      <v-btn v-if="!xmlPassedValidation || !validXML" class="ml-4"  color="primary" :loading="loadingXMLResponse" @click="validateXML" :disabled="!initialData.businessInfo.xmlURL">
                        <v-icon left>
                          mdi-check
                        </v-icon>
                        Validar XML
                      </v-btn>
                      <v-icon v-if="xmlPassedValidation && validXML" class="ml-4 d-flex align-start" style="font-size: 2em;"  color="primary">
                        mdi-check
                      </v-icon>
                    </div>


                  </div>

                </v-col>
              </v-row>
            </div>
          </v-window-item>

          <v-window-item :value="4">
            <div v-if="responseSuccess" class="pa-4 text-center">
              <svg height="300" viewBox="0 0 810 873" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="undraw_completed_ngx6 1" clip-path="url(#clip0)">
                  <path id="Vector" d="M436.835 872.15C642.837 872.15 809.835 705.153 809.835 499.15C809.835 293.148 642.837 126.15 436.835 126.15C230.833 126.15 63.8352 293.148 63.8352 499.15C63.8352 705.153 230.833 872.15 436.835 872.15Z" fill="#F2F2F2"/>
                  <path id="Vector_2" d="M219.02 433.191C221.681 428.547 223.056 423.277 223.003 417.925C223.003 404.129 214.46 392.944 203.921 392.944C193.382 392.944 184.838 404.129 184.838 417.925C184.785 423.277 186.16 428.547 188.821 433.191C186.209 437.855 184.838 443.112 184.838 448.457C184.838 453.803 186.209 459.059 188.821 463.723C186.209 468.388 184.838 473.644 184.838 478.989C184.838 484.335 186.209 489.591 188.821 494.255C186.209 498.92 184.838 504.176 184.838 509.521C184.838 514.867 186.209 520.123 188.821 524.787C186.16 529.432 184.785 534.701 184.838 540.053C184.838 553.85 193.382 565.034 203.921 565.034C214.46 565.034 223.003 553.85 223.003 540.053C223.056 534.701 221.681 529.432 219.02 524.787C221.632 520.123 223.003 514.867 223.003 509.521C223.003 504.176 221.632 498.92 219.02 494.255C221.632 489.591 223.003 484.335 223.003 478.989C223.003 473.644 221.632 468.388 219.02 463.723C221.632 459.059 223.003 453.803 223.003 448.457C223.003 443.112 221.632 437.855 219.02 433.191V433.191Z" fill="#3F3D56"/>
                  <path id="Vector_3" d="M203.921 412.374C214.46 412.374 223.003 401.189 223.003 387.393C223.003 373.596 214.46 362.412 203.921 362.412C193.382 362.412 184.838 373.596 184.838 387.393C184.838 401.189 193.382 412.374 203.921 412.374Z" fill="#3F3D56"/>
                  <path id="Vector_4" d="M203.921 381.842C214.46 381.842 223.003 370.657 223.003 356.861C223.003 343.064 214.46 331.88 203.921 331.88C193.382 331.88 184.838 343.064 184.838 356.861C184.838 370.657 193.382 381.842 203.921 381.842Z" fill="#3F3D56"/>
                  <path id="Vector_5" d="M275.356 147.92C277.963 144.601 280.338 141.106 282.463 137.459L232.322 129.224L286.551 129.628C291.753 118.309 294.591 106.048 294.891 93.5945C295.191 81.1413 292.947 68.7577 288.296 57.2016L215.542 94.9429L282.638 45.622C276.352 34.7595 267.897 25.3076 257.799 17.8554C247.7 10.4031 236.176 5.11009 223.943 2.306C211.71 -0.498096 199.03 -0.753168 186.694 1.55667C174.359 3.86651 162.63 8.69177 152.241 15.7319C141.851 22.772 133.022 31.8761 126.304 42.4771C119.586 53.078 115.123 64.9488 113.193 77.3498C111.263 89.7508 111.908 102.416 115.086 114.558C118.264 126.699 123.909 138.055 131.667 147.92C127.604 153.086 124.109 158.675 121.243 164.59L186.329 198.404L116.929 175.112C112.056 189.517 110.852 204.91 113.425 219.897C115.999 234.885 122.268 248.994 131.667 260.949C121.062 274.431 114.465 290.626 112.63 307.681C110.796 324.737 113.797 341.964 121.291 357.394C128.786 372.824 140.471 385.834 155.011 394.936C169.551 404.038 186.358 408.864 203.512 408.864C220.665 408.864 237.473 404.038 252.013 394.936C266.552 385.834 278.238 372.824 285.732 357.394C293.227 341.964 296.228 324.737 294.393 307.681C292.558 290.626 285.961 274.431 275.356 260.949C288.028 244.836 294.917 224.933 294.917 204.434C294.917 183.936 288.028 164.032 275.356 147.92L275.356 147.92Z" fill="#4CAF50"/>
                  <path id="Vector_6" opacity="0.1" d="M112.106 204.434C112.075 224.938 118.967 244.852 131.667 260.949C121.062 274.431 114.465 290.626 112.63 307.682C110.796 324.737 113.797 341.964 121.292 357.394C128.786 372.824 140.471 385.834 155.011 394.936C169.551 404.038 186.358 408.865 203.512 408.865C220.665 408.865 237.473 404.038 252.013 394.936C266.553 385.834 278.238 372.824 285.732 357.394C293.227 341.964 296.228 324.737 294.393 307.682C292.558 290.626 285.961 274.431 275.356 260.949C287.607 245.396 112.106 194.175 112.106 204.434Z" fill="black"/>
                  <path id="Vector_7" d="M705.584 745.544C681.93 829.831 499.417 908.005 305.331 853.538C111.245 799.071 -20.918 632.589 2.73557 548.302C26.3892 464.015 196.902 493.841 390.988 548.308C585.075 602.775 729.238 661.257 705.584 745.544Z" fill="#3F3D56"/>
                  <path id="Vector_8" opacity="0.1" d="M705.584 745.544C681.93 829.831 499.417 908.005 305.331 853.538C111.245 799.071 -20.918 632.589 2.73557 548.302C26.3892 464.015 196.902 493.841 390.988 548.308C585.075 602.775 729.238 661.257 705.584 745.544Z" fill="black"/>
                  <path id="Vector_9" d="M705.584 745.544C729.238 661.257 591.075 548.775 396.988 494.308C202.902 439.841 26.3893 464.015 2.73565 548.302C-20.918 632.589 117.245 745.072 311.331 799.538C505.417 854.005 681.93 829.831 705.584 745.544Z" fill="#3F3D56"/>
                  <path id="Vector_10" opacity="0.1" d="M294.835 683.15C353.93 683.15 401.835 669.271 401.835 652.15C401.835 635.03 353.93 621.15 294.835 621.15C235.741 621.15 187.835 635.03 187.835 652.15C187.835 669.271 235.741 683.15 294.835 683.15Z" fill="black"/>
                  <path id="Vector_11" d="M426.591 473.755L291.258 609.088L234.377 552.207C230.73 548.56 225.785 546.512 220.628 546.512C215.471 546.512 210.525 548.56 206.879 552.207L204.835 549.15C197.242 556.744 199.285 572.112 206.878 579.705L280.681 653.507C283.462 656.289 287.234 657.852 291.168 657.853C295.101 657.854 298.874 656.293 301.657 653.513L454.075 501.254C461.673 493.663 464.43 479.745 456.835 472.15C449.244 464.559 434.182 466.164 426.591 473.755Z" fill="#57B894"/>
                  <path id="Vector_12" opacity="0.1" d="M426.591 473.755L291.258 609.088L234.377 552.207C230.73 548.56 225.785 546.512 220.628 546.512C215.471 546.512 210.525 548.56 206.879 552.207L204.835 549.15C197.242 556.744 199.285 572.112 206.878 579.705L280.681 653.507C283.462 656.289 287.234 657.852 291.168 657.853C295.101 657.854 298.874 656.293 301.657 653.513L454.075 501.254C461.673 493.663 464.43 479.745 456.835 472.15C449.244 464.559 434.182 466.164 426.591 473.755Z" fill="black"/>
                  <path id="Vector_13" d="M426.591 467.755L291.258 603.088L234.377 546.207C230.73 542.56 225.784 540.512 220.628 540.512C215.471 540.512 210.525 542.56 206.878 546.207V546.207C205.073 548.012 203.641 550.156 202.663 552.515C201.686 554.874 201.183 557.402 201.183 559.956C201.183 562.509 201.686 565.038 202.663 567.397C203.641 569.756 205.073 571.899 206.878 573.705L280.681 647.507C283.462 650.289 287.234 651.852 291.168 651.853C295.101 651.854 298.874 650.293 301.657 647.513L454.075 495.254C455.882 493.449 457.315 491.306 458.293 488.947C459.271 486.588 459.775 484.06 459.776 481.506C459.776 478.952 459.274 476.423 458.297 474.064C457.32 471.705 455.888 469.561 454.082 467.755V467.755C450.436 464.11 445.492 462.061 440.336 462.062C435.181 462.062 430.236 464.11 426.591 467.755Z" fill="#57B894"/>
                  <path id="Vector_14" d="M492.492 698.125C492.492 698.125 480.389 701.756 474.338 709.018C474.338 709.018 463.445 713.859 456.789 712.649C450.132 711.439 438.029 723.541 447.711 727.172C457.394 730.803 516.698 740.486 517.908 737.46C519.118 734.434 519.118 703.572 519.118 703.572L492.492 698.125Z" fill="#575A89"/>
                  <path id="Vector_15" d="M587.562 692.929C587.562 692.929 576.257 698.573 571.535 706.762C571.535 706.762 561.63 713.393 554.864 713.337C548.099 713.282 538.241 727.274 548.401 729.198C558.561 731.121 618.648 730.532 619.323 727.344C619.999 724.156 614.728 693.747 614.728 693.747L587.562 692.929Z" fill="#575A89"/>
                  <path id="Vector_16" d="M512.159 282.694C527.199 282.694 539.391 270.502 539.391 255.463C539.391 240.423 527.199 228.231 512.159 228.231C497.12 228.231 484.928 240.423 484.928 255.463C484.928 270.502 497.12 282.694 512.159 282.694Z" fill="#FFB9B9"/>
                  <path id="Vector_17" d="M534.247 260.001C534.247 260.001 537.273 280.576 542.719 282.997C546.508 284.862 549.98 287.313 553.006 290.258L537.878 297.52L508.226 295.705C508.226 295.705 510.646 282.392 506.41 275.735C502.174 269.078 534.247 260.001 534.247 260.001Z" fill="#FFB9B9"/>
                  <path id="Vector_18" d="M590.525 357.429C590.525 357.429 604.444 393.133 590.525 427.626C576.607 462.119 570.556 467.566 570.556 467.566L566.32 422.18L571.19 417.761C571.19 417.761 574.14 403.992 571.743 395.839C569.345 387.687 572.976 362.271 572.976 362.271L590.525 357.429Z" fill="#FFB9B9"/>
                  <path id="Vector_19" d="M486.441 434.888C486.441 434.888 468.891 474.827 470.707 494.192C472.522 513.557 486.441 595.251 486.441 595.251C486.441 595.251 484.625 700.546 490.072 700.546C495.518 700.546 521.539 710.228 521.539 703.572C521.539 696.915 526.985 537.157 526.985 537.157C526.985 537.157 557.848 607.354 560.873 612.8C563.899 618.247 583.264 703.572 589.92 703.572C596.577 703.572 617.152 697.52 615.336 693.284C613.521 689.048 592.946 594.646 581.448 570.44C569.951 546.234 572.371 454.858 572.371 454.858L553.007 412.498L486.441 434.888Z" fill="#2F2E41"/>
                  <path id="Vector_20" d="M503.99 243.057C503.99 243.057 487.046 241.847 485.836 237.611C484.625 233.375 482.205 229.744 483.415 229.744C484.625 229.744 485.23 226.718 485.23 226.718C485.23 226.718 486.441 226.113 487.046 226.718C487.651 227.324 488.256 222.482 488.256 222.482C488.256 222.482 488.861 220.667 489.467 221.877C490.072 223.088 490.072 222.482 490.677 220.667C491.282 218.852 494.308 217.641 494.308 217.641C494.308 217.641 505.805 218.246 507.621 215.826C509.436 213.405 530.011 215.221 534.247 218.246C538.483 221.272 557.243 232.165 554.217 241.242C551.191 250.319 540.299 270.289 540.299 273.314C540.299 276.34 536.063 275.735 534.852 272.709C533.642 269.684 523.96 266.053 523.96 264.237C523.96 262.422 518.513 245.478 515.488 246.688C512.462 247.898 508.831 257.581 507.621 255.765C506.411 253.95 503.99 243.057 503.99 243.057Z" fill="#3F3D56"/>
                  <path id="Vector_21" d="M587.5 325.962C587.5 325.962 562.689 289.653 555.427 287.838C548.165 286.023 531.826 281.181 531.826 281.181C531.826 281.181 515.488 283.602 510.646 286.023C505.805 288.443 487.651 292.679 487.046 299.941C486.441 307.203 491.887 376.794 488.861 383.451C485.836 390.107 475.548 453.647 480.389 452.437C485.23 451.227 481.6 435.493 502.78 434.888C523.96 434.283 535.457 421.575 543.324 436.703C551.191 451.832 573.581 463.33 573.581 463.33C574.144 458.451 574.952 453.604 576.002 448.806C577.817 440.334 571.161 419.759 571.161 419.759C571.161 419.759 571.766 371.953 574.187 366.507C576.607 361.06 587.5 325.962 587.5 325.962Z" fill="#575A89"/>
                  <path id="Vector_22" d="M578.422 324.752L587.5 325.962C587.5 325.962 586.894 338.065 589.315 341.696C591.736 345.327 595.972 364.086 593.551 364.691C591.13 365.296 569.345 368.927 569.345 368.927L568.135 341.091L578.422 324.752Z" fill="#575A89"/>
                  <path id="Vector_23" d="M480.389 346.537C480.389 346.537 474.338 385.871 471.312 390.712C468.286 395.554 453.158 463.33 453.158 463.33C453.158 463.33 428.28 497.926 440.383 497.321C452.486 496.715 465.261 465.75 465.261 465.75C465.261 465.75 502.78 397.369 502.78 390.107C502.78 382.846 511.857 346.537 511.857 346.537H480.389Z" fill="#FFB9B9"/>
                  <path id="Vector_24" d="M497.939 293.284L487.046 297.52C487.046 297.52 473.128 347.142 476.153 351.378C479.179 355.614 515.488 349.563 516.093 347.747C516.698 345.932 509.436 293.284 497.939 293.284Z" fill="#575A89"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="809.835" height="872.15" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              <h2 class="title font-weight-regular mb-2">
                ¡Excelente, hemos actualizado sus datos!
              </h2>
              <span class="caption grey--text">Para comenzar a editar su primer anuncio haga click en el boton <strong>Configurar Campaña</strong>.</span><br>
              <span class="caption grey--text">De lo contrario, presione <strong>Finalizar</strong> y podrá hacerlo en otro momento.</span>

              <div class="d-flex justify-center ma-6">
                <v-btn
                    color="blue "
                    text
                    outlined
                    @click="initCampaignConfig"
                >
                  Configurar Campaña
                </v-btn>
              </div>
            </div>
          </v-window-item>
        </v-window>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
              v-if="step !== 0 && step !== 4"

              text
              @click="step--"
              :class="step === 0 ? 'hidden':''"
          >
            Atrás
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              v-if="step !== 3 && step !== 4"
              color="primary"
              depressed
              @click="step++"
              :disabled="!this.initialData.accountPlan || (step===1 && !validFirstStepForm) || (step===2 &&  !validSecondStepForm)"
          >
            Siguiente
          </v-btn>
          <v-btn
              v-if="step===3"
              color="primary"
              depressed
              @click="submitInitialData"
              :disabled="!validFirstStepForm || !validSecondStepForm || !validXML "
              :loading="loadingResponse"
          >
            Guardar Datos
          </v-btn>
          <v-btn
              v-if="step===4"
              color="primary"
              depressed
              @click="toggleInitialFormModal"
          >
            Finzalizar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-snackbar
          v-model="snackbar"
          top
          class="my-10"
          timeout="6000"
      >
        <v-icon left>mdi-alert</v-icon>
        {{ errorMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="snackbar = false"

          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>


</template>
<script>

//hay que agregar la validación a todos los inputs
import countryList from "@/utils/countryList";
import httpClient from "@/api/httpClient";

export default {
  name: "InitialFormDialog",
  data(){
    return{
      step: 0,
      initialFormDialog:null,
      countries:countryList(),
      workAreas:['Accesorios y Bijouterie', 'Alimentos y Bebidas', 'Anteojos', 'Antigüedades', 'Arte', 'Automóviles y Motos', 'Autopartes y Repuestos', 'Bicicletas', 'Bikinis y Trajes de Baño', 'Blanquería', 'Calzado', 'Carteras/Bolsos/Mochilas', 'Casa y Jardín', 'Colchones y Sommiers', 'Computación', 'Cosmética y Belleza', 'Cotillón y Artículos para fiestas', 'Cursos y Talleres', 'Decoración y Bazar', 'Deportes y Tiempo Libre', 'Electro y Tecnología', 'Flores y Plantas', 'Herramientas y Ferretería', 'Iluminación y Artículos de electricidad', 'Indumentaria para adultos', 'Indumentaria para bebés y chicos', 'Indumentaria y Accesorios para futuras mamás', 'Instrumentos musicales', 'Juguetes/Juegos/Hobbies',
        'Lencería y Ropa Interior', 'Librería e Insumos para oficinas', 'Libros y Revistas', 'Mascotas', 'Materiales de construcción', 'Muebles', 'Regalos y Objetos', 'Relojes', 'Telas y Mercería', 'Viajes y Turismo', 'Otro'],
      initialData:{
        name:'',
        corporateName:'',
        fiscalId:'',
        administrativeContact:'',
        administrativeContactEmail:'',
        administrativeContactPhone:'',
        accountCountry:null,
        accountPlan:null,
        businessInfo: {
          shopURL:'',
          facebookURL:'',
          instagramURL:'',
          workArea:null,
          featuredInfo:[],
          competition:[],
          xmlURL:'',
          description:''
        }
      },
      loadingResponse:false,
      responseSuccess:false,
      globalClient:null,
      snackbar:false,
      errorMessage:null,
      validFirstStepForm:false,
      validSecondStepForm:false,

      nameRules: [
        v => !!v || 'Debes ingresar un nombre para tu negocio.',
      ],
      corporateNameRules: [
        v => !!v || 'Debes ingresar la razón social de tu negocio.',
      ],
      fiscalIdRules:[
        v => !!v || 'Debes ingresar el ID fiscal de tu negocio.',

      ],
      administrativeContactRules: [
        v => !!v || 'Debes ingresar el nombre del contacto administrativo.',
      ],
      emailRules: [
        v => !!v || 'Debes ingresar una casilla de email.',
        v => /.+@.+\..+/.test(v) || 'Debes ingresar una casilla de email válida.',
      ],
      phoneRules: [
        v => !!v || 'Debes ingresar el número de teléfono del contacto administrativo.',
      ],
      countryRules: [
        v => !!v || 'Debes elegir el país al que tu negocio pertenece.',
      ],
      shopDomainRules:[
        v => !!v || 'Debes ingresar la URL de tu tienda online.',
      ],
      facebookDomainRules:[
        v => !!v || 'Debes ingresar la URL del perfíl de Facebook de tu negocio.',
      ],
      instagramDomainRules:[
        v => !!v || 'Debes ingresar la URL del perfíl de Instagram de tu negocio.',
      ],
      featuredInfoRules:[
        v => !!v.length>0 || 'Debes ingresar los nombres de tus competidores.',
      ],
      competitionRules:[
        v => !!v.length>0 && v.length<=10|| 'Debes ingresar los nombres de tus competidores (10 máximo).',
      ],
      descriptionRules:[
        v => !!v.length>0 || 'Debes ingresar una descripción para tu negocio.',
      ],
      // xmlRules:[
      //   v => !!v || 'Debes ingresar la URL del archivo XML de tu tienda online.',
      // ],
      loadingXMLResponse:false,
      validXML:true,
      xmlPassedValidation:false,
      uploadXML:false
    }
  },
  methods:{
    getGlobalClient(){
      this.globalClient = this.$store.getters['account/getGlobalClient']
    },
    toggleInitialFormModal(){
      this.$store.dispatch('account/toggleInitialFormDialog')
    },
    toggleCreateCampaignModal(){
      this.$store.dispatch('campaign/toggleCreateCampaignDialog')
    },
    async submitInitialData(){
      this.loadingResponse = !this.loadingResponse
      delete this.initialData.accountPlan
      const response = await this.$store.dispatch('account/submitInitialData', {... this.initialData})
      if (response.data.status==='success'){
        this.loadingResponse = !this.loadingResponse
        this.responseSuccess = true
        this.step += 1
      }
    },
    initCampaignConfig(){
      this.toggleInitialFormModal()
      setTimeout(()=>{
        this.toggleCreateCampaignModal()
      },100)
    },
    async validateXML(){
      this.loadingXMLResponse=true
      try{
        const response = await httpClient.post('/clients/validateXML',{
          XMLUrl:this.initialData.businessInfo.xmlURL
        })

        if (response.data.status ==='success'){
          this.validXML = response.data.isXML
          this.loadingXMLResponse=false
          this.xmlPassedValidation = true
        }


      }catch (e) {
        this.loadingXMLResponse=false

        if (e.response.status === 400){
          this.validXML = false
          this.snackbar=true
          this.errorMessage = e.response.data.message
          this.loadingXMLResponse=false
          this.xmlPassedValidation = false

        }else if(e.response.status === 500){
          this.validXML = false
          this.snackbar=true
          this.errorMessage = 'La URL provista no contiene un archivo XML válido.'
          this.loadingXMLResponse=false
          this.xmlPassedValidation = false

        }
      }

    },
    toggleXmlUpload(){
      this.uploadXML= !this.uploadXML
      this.uploadXML?this.xmlPassedValidation=false:this.xmlPassedValidation=true
      this.initialData.businessInfo.xmlURL=''
    }
  },
  computed:{
    currentTitle () {
      switch (this.step) {
        case 1: return 'Datos Administrativos'
        case 2: return 'Información de su negocio'
        case 3: return 'Conectar tienda online'
        case 4: return 'Ha terminado'
        default: return 'Primeros Pasos'
      }
    },
    dialogOn(){
      return this.$store.getters['account/getInitialFormDialogToggleState']
    },
  },
  watch:{
    dialogOn(value){
      this.initialFormDialog = value
    },
    globalClient(value){
     if (value){
       this.initialData.administrativeContact = value.administrativeContact
       this.initialData.administrativeContactEmail = value.administrativeContactEmail
       this.initialData.administrativeContactPhone = value.administrativeContactPhone
       if (!value.plan){
         this.errorMessage = '¡Tu cuenta aún no tiene asignado un plan! Debes ponerte en contacto con nosotros para informarnos la situación.'
         this.snackbar = true
       }else{
         this.initialData.accountPlan = value.plan.name
         this.snackbar = false

       }
     }
    },
    uploadXML(val) {
      val ? this.validXML = false : this.validXML = true
    }
  },
  mounted() {
    this.step = 0
    this.getGlobalClient()
  }
}
</script>

<style scoped>
  .hidden{
    display: none;
  }
</style>

