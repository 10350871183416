import actions from "@/store/modules/helpArticles/actions";
import mutations from "@/store/modules/helpArticles/mutations";
import getters from "@/store/modules/helpArticles/getters";

export default {
    namespaced:true,
    state:{},
    actions,
    mutations,
    getters
}