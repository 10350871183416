import shopWindowMutations from './mutations'
import shopWindowActions from './actions'
import shopWindowGetters from './getters'

export default {
    namespaced:true,
    state:{
        createShopWindowDialog: false
    },
    mutations: shopWindowMutations,
    actions: shopWindowActions,
    getters: shopWindowGetters
}