<template>
  <v-dialog
      v-model="dialog"
      max-width="1200px"
      persistent
  >
    <v-card >
      <v-card-title>
        <span class="headline">Nueva Campaña</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-stepper class="stepper" v-model="e1" >
          <v-container class="pb-0">
            <v-row class="d-flex align-center px-4">
              <v-stepper-step
                  :complete="e1 > 1"
                  :editable="e1 > 1"

                  step="1"
              >
                <p class="text-md-center ma-0">Objetivo</p>
              </v-stepper-step>

              <v-divider/>

              <v-stepper-step
                  :complete="e1 > 2"
                  :editable="e1 > 2"
                  step="2"
              >
                <p class="text-md-center ma-0">Configuración Inicial</p>
              </v-stepper-step>

              <v-divider/>

              <v-stepper-step
                  :complete="e1 > 3"
                  :editable="e1 > 3"

                  step="3">
                <p class="text-md-center ma-0">Audiencias</p>
              </v-stepper-step>

              <v-divider/>
              <v-stepper-step
                  :complete="e1 > 4"
                  :editable="e1 > 4"

                  step="4">
                <p class="text-md-center ma-0">Vidrieras</p>
              </v-stepper-step>
              <v-divider/>
              <v-stepper-step
                  step="5"
              >
                <p class="text-md-center ma-0">Presupuesto</p>
              </v-stepper-step>
            </v-row>
          </v-container>
          <v-stepper-items>
            <!--OBJETIVOS-->
            <v-stepper-content class="my-8 pa-0" step="1">
              <v-container>
                <v-row>
                  <v-col md="12" class="d-flex flex-column justify-center align-center ">
                    <h2 class="mb-2">¿Cuál es el objetivo de la campaña?</h2>
                    <h3 class="font-weight-light">Elige un tipo de objetivo publicitario.</h3>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center flex-column align-center">
                  <v-col md="12" class="mb-10">
                    <v-item-group mandatory  v-model="campaign.objective" active-class="custom">
                      <v-container>
                        <v-row>
                          <v-col
                              v-for="objective in objectives"
                              :key="objective.objective"
                              cols="12"
                              md="4"
                          >
                            <v-item v-slot="{ active, toggle }" :value="objective.objective" >
                              <v-card
                                  @click="toggle"
                                  outlined
                                  flat
                                  :disabled="!planIncludesObjective(objective)"
                                  rounded
                                  class="rounded-xl"

                              >
                                <div class="d-flex justify-end">
                                  <v-icon class="mr-2 mt-2 selected-icon" :color="active?'primary':''">
                                    {{ active ? 'mdi-checkbox-marked-circle' : '' }}
                                  </v-icon>
                                </div>

                                <div class="d-flex justify-center">
                                  <v-icon
                                      :color="active ? 'primary':''"
                                      class="objective-icon mt-6"
                                  >
                                    {{ objective.icon }}
                                  </v-icon>
                                </div>
                                <v-card-title  class="capitalize">{{ objective.objective }}</v-card-title>
                                <v-card-subtitle >{{objective.description}}</v-card-subtitle>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-item-group>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row class="d-flex justify-end">
                  <v-btn
                      text
                      @click="toggleCreateCampaignDialog"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                      color="primary"
                      @click="pickObjective"
                  >
                    Siguiente
                  </v-btn>
                </v-row>
              </v-container>
            </v-stepper-content>
            <!--CONFIG INICIAL-->
            <v-stepper-content class="my-8 pa-0" step="2">
              <v-form ref="form" v-model="validStep2">
                <v-container>
                  <v-row>
                    <v-col md="12" class="d-flex flex-column justify-center align-center ">
                      <h2 class="mb-2">Configuración Inicial</h2>
                      <h3 class="font-weight-light">Completa los siguientes datos.</h3>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center mt-2">
                    <v-col md="6" >
                      <v-text-field
                          label="Nombre Descriptivo"
                          outlined
                          prepend-inner-icon="mdi-format-title"
                          v-model="campaign.initialConfig.descriptiveName"
                          :rules="descriptiveNameRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center ">
                    <v-col md="6" >
                      <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="campaign.initialConfig.startDate"
                              label="Fecha de inicio"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              prepend-inner-icon="mdi-calendar"
                              hint="Las campañas pueden tardar hasta 72hs hábiles, desde la fecha de inicio configurada, en ser publicadas."
                              persistent-hint
                              :rules="startDateRules"

                          ></v-text-field>
                        </template>
                        <v-date-picker
                            width="400" v-model="campaign.initialConfig.startDate" locale="es"
                            :min="minDate"

                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center align-center flex-column mt-2">
                    <v-col md="6" >
                      <v-menu
                          v-if="hasEndDate"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="campaign.initialConfig.endDate"
                              label="Fecha de finalización"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              prepend-inner-icon="mdi-calendar"
                              :rules="endDateRules"

                          ></v-text-field>
                        </template>
                        <v-date-picker
                            width="400" v-model="campaign.initialConfig.endDate" locale="es" :min="campaign.initialConfig.startDate"

                        ></v-date-picker>
                      </v-menu>
                      <div>
                        <v-switch
                            v-model="hasEndDate"
                            label="Tiene fecha de finalización"
                            color="info"
                            :value="!hasEndDate"
                            hide-details
                            class="pa-0 mt-0 mb-8"
                        ></v-switch>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              <v-container>
                <v-row class="d-flex justify-space-between">
                  <v-btn @click="toggleCreateCampaignDialog" text>
                    Salir
                  </v-btn>
                  <div>
                    <v-btn
                        text
                        @click="e1 = 1"
                    >
                      Atrás
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="dispatchInitialConfig"
                        :disabled="!validStep2"
                    >
                      Siguiente
                    </v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-stepper-content>
            <!--AUDIENCIAS-->
            <v-stepper-content class="my-8 pa-0" step="3">
              <v-form ref="form" v-model="validStep3">
                <v-container>
                  <v-row>
                    <v-col md="12" class="d-flex flex-column justify-center align-center ">
                      <h2 class="mb-2">Audiencias</h2>
                      <h3 class="font-weight-light">Configura la audiencia para tu campaña.</h3>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex flex-wrap mt-2">
                    <v-col md="12">
                      <div>
                        <h3>
                          <v-icon>
                            mdi-calendar-range
                          </v-icon>
                          Ubicación Geográfica
                        </h3>
                      </div>
                    </v-col>
                    <v-col md="6">
                      <v-autocomplete
                          v-model="campaign.audience.country"
                          :items="countries"
                          outlined
                          label="País"
                          @change="campaign.audience.regions = []"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="countryRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col md="6">
                      <v-autocomplete
                          v-if="campaign.audience.country==='Argentina'"
                          v-model="campaign.audience.regions"
                          :items="provinces"
                          outlined
                          chips
                          small-chips
                          label="Província o Región"
                          multiple
                          prepend-inner-icon="mdi-crosshairs-gps"
                          :rules="regionRules"
                      ></v-autocomplete>
                      <v-combobox
                          v-if="campaign.audience.country!=='Argentina'&&campaign.audience.country!==null"
                          v-model="campaign.audience.regions"
                          hint="Escriba la región y luego presione 'Enter'."
                          label="Provincia o Región"
                          multiple
                          persistent-hint
                          small-chips
                          outlined
                          prepend-inner-icon="mdi-crosshairs-gps"
                          :rules="regionRules"
                      >
                      </v-combobox>
                    </v-col>
                  </v-row>

                  <v-container>
                    <v-divider class="my-"></v-divider>
                  </v-container>

                  <v-row class="d-flex flex-wrap mt-3">
                    <v-col md="6">
                      <div>
                        <h3>
                          <v-icon>
                            mdi-gender-male-female
                          </v-icon>
                          Género
                        </h3>
                      </div>
                    </v-col>
                    <v-col md="6">
                      <div>
                        <h3>
                          <v-icon>
                            mdi-calendar-range
                          </v-icon>
                          Rango Etáreo
                        </h3>
                      </div>
                    </v-col>
                    <v-col md="6" class="d-flex ">
                      <v-chip-group
                          v-model="campaign.audience.gender"
                          column
                          multiple

                      >
                        <v-chip
                            outlined
                            filter
                            value="hombres"
                            color="info"
                        >
                          Hombres
                        </v-chip>
                        <v-chip
                            outlined
                            filter
                            value="mujeres"
                            color="pink"
                        >
                          Mujeres
                        </v-chip>
                      </v-chip-group>
                    </v-col>

                    <v-col md="6">
                      <v-range-slider
                          v-model="ageRange"
                          :max="maxAge"
                          :min="minAge"
                          hide-details
                          class="align-center mt-3"
                      >
                        <template v-slot:prepend>
                          <v-text-field
                              :value="ageRange[0]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              type="number"
                              style="width: 60px"
                              @change="$set(ageRange, 0, $event)"
                          ></v-text-field>
                        </template>
                        <template v-slot:append>
                          <v-text-field
                              :value="ageRange[1]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              type="number"
                              style="width: 60px"
                              @change="$set(ageRange, 1, $event)"
                          ></v-text-field>
                        </template>
                      </v-range-slider>
                      <br>
                      <div>
                        <p class="ageRangeText text-center">De {{`${ageRange[0]}`}} a {{`${ageRange[1]}`}} años</p>
                      </div>
                    </v-col>
                  </v-row>
                  <v-container>
                    <v-divider></v-divider>
                  </v-container>
                  <v-row class="d-flex mt-2">
                    <v-col md="5">
                      <div>
                        <h3>
                          <v-icon>
                            mdi-baby-face-outline
                          </v-icon>
                          Estado Parental
                        </h3>
                      </div>
                      <div>
                        <v-radio-group v-model="campaign.audience.parentalState">
                          <v-radio
                              label="Ninguno"
                              value="ninguno"
                              style="width: 40%"
                          ></v-radio>
                          <v-radio
                              label="Padres / Madres"
                              value="padres/madres"
                              style="width: 40%"
                          ></v-radio>
                          <v-radio
                              label="Futuros/as Padres y Madres"
                              value="futuros-padres/futuras-madres"
                              style="width: 55%"
                          ></v-radio>
                          <v-radio
                              label="Padres/Madres y Futuros/as Padres y Madres"
                              value="padres/madres & futuros-padres/futuras-madres"
                              style="width: 80%"
                          ></v-radio>
                        </v-radio-group>
                      </div>

                      <p>* Utiliza esta segmentación si tus productos son orientados específicamente a padres/madres y/o futuros padres/madres</p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col md="6">
                      <div>
                        <h3>
                          <v-icon>
                            mdi-bookmark-multiple-outline
                          </v-icon>
                          Intereses
                        </h3>
                      </div>
                      <v-combobox
                          v-model="campaign.audience.interests"
                          hide-selected
                          multiple
                          persistent-hint
                          small-chips
                          outlined
                          dense
                          :counter="20"
                          deletable-chips
                          :rules="interestsRules"
                          class="mt-3"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Presiona enter <kbd>enter</kbd> para agregar uno nuevo
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                      <p>* Carga intereses que pueden ser relevantes para tus potenciales clientes (ej. Ropa, Moda, Ropa para niños, Muebles de diseño, etc.).</p>
                    </v-col>
                  </v-row>
                  <v-container>
                    <v-divider></v-divider>
                  </v-container>
                  <v-row class="d-flex mt-2">
                    <v-col md="6">
                      <div>
                        <h3>
                          <v-icon>
                            mdi-information-outline
                          </v-icon>
                          Más Información
                        </h3>
                      </div>
                    </v-col>
                    <v-col md="12">
                      <v-textarea
                          outlined
                          name="input-7-4"
                          label="Agrega más información sobre tus audiencias ..."
                          prepend-inner-icon="mdi-text"
                          v-model="campaign.audience.extraInfo"
                          :rules="extraInfoRules"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>

              </v-form>
              <v-container>
                <v-row class="d-flex justify-space-between">
                  <v-btn @click="toggleCreateCampaignDialog" text>
                    Salir
                  </v-btn>
                  <div >
                    <v-btn
                        text
                        @click="e1 = 2"
                    >
                      Atrás
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="dispatchAudience"
                        :disabled="!validStep3"
                    >
                      Siguiente
                    </v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-stepper-content>
            <!--VIDRIERAS-->
            <v-stepper-content class="my-8 pa-0" step="4">
              <v-container>
                <v-row>
                  <v-col md="12" class="d-flex flex-column justify-center align-center ">
                    <h2 class="mb-2">Vidrieras</h2>
                    <h3 class="font-weight-light">Elige hasta 3 vidrieras para tu campaña.</h3>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center flex-column align-center">
                  <v-col md="12" class="mb-10">
                    <ShopWindowTable v-on:selectedShopWindowsChanged="populateShopWindows($event)"/>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row class="d-flex justify-space-between">
                  <v-btn @click="toggleCreateCampaignDialog" text>
                    Salir
                  </v-btn>
                  <div>
                    <v-btn
                        text
                        @click="e1 = 3"
                    >
                      Atrás
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="dispatchShopWindows"
                        :disabled="!validStep4"
                    >
                      Siguiente
                    </v-btn>
                  </div>

                </v-row>
              </v-container>
            </v-stepper-content>
            <!--PRESUPUESTO-->
            <v-stepper-content class="my-8 pa-0" step="5">
              <v-container>
                <v-row>
                  <v-col md="12" class="d-flex flex-column justify-center align-center ">
                    <h2 class="mb-2">Presupuesto</h2>
                    <h3 class="font-weight-light">¿Cuánto quieres invertir?.</h3>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center flex-column align-center">
                  <v-col md="12">
                    <v-item-group v-model="campaign.budget" active-class="custom" >
                      <v-container >
<!--                        PRESUPUESTOS HABILITADOS-->
                        <v-row v-if="!customBudget">
                          <v-col
                              cols="12"
                              md="4"
                              v-for="availableBudget in objectiveBudgets"
                              :key="availableBudget.id"
                          >
                            <v-item v-slot="{ active, toggle }" :value="availableBudget">
                              <v-card
                                  outlined
                                  @click="toggle"
                              >
                                <div class="d-flex justify-end">
                                  <v-icon class="mr-2 mt-2 selected-icon" :color="active?'primary':''">
                                    {{ active ? 'mdi-checkbox-marked-circle' : '' }}
                                  </v-icon>
                                </div>
                                <div class="d-flex flex-column justify-center align-center mt-4 mb-4">
                                  <div class="mb-2">
                                    <v-icon
                                        v-for="n in availableBudget.starCount"
                                        :key="n"
                                        color="info lighten-2"
                                        class="star-objective-icon mt-6 "

                                    >
                                      mdi-star
                                    </v-icon>
                                  </div>
                                  <div class="mt-4">
                                    <h2 class="headline">{{ availableBudget.dailyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} {{accountPlan.currency}}/día</h2>
                                    <h4 class="monthly-prince">{{parsedMonthlyPrice(availableBudget.dailyPrice)}} {{accountPlan.currency}}/mes</h4>
                                  </div>
                                </div>
                                <v-card-title >{{ availableBudget.title }}</v-card-title>
                                <v-card-subtitle>{{ availableBudget.description }}</v-card-subtitle>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>

<!--                        PRESUPUESTO A MEDIDA-->
                        <v-row v-if="customBudget" class="d-flex justify-center">
                          <v-col
                              cols="12"
                              md="4"
                          >
                            <v-item v-slot="{ active, toggle }" :value="customBudgetContent">
                              <v-card
                                  class="rounded-xl"
                                  outlined
                                  @click="toggle"
                              >
                                <div class="d-flex justify-end">
                                  <v-icon class="mr-2 mt-2 selected-icon" :color="active?'primary':''">
                                    {{ active ? 'mdi-checkbox-marked-circle' : '' }}
                                  </v-icon>
                                </div>
                                <div class="d-flex flex-column justify-center align-center">
                                  <div>
                                    <v-icon
                                        color="info lighten-2"
                                        class="star-objective-icon mt-6"
                                    >
                                      mdi-pencil-ruler
                                    </v-icon>
                                  </div>
                                  <div class="mt-3">
                                    <h2 class="headline">{{ customBudgetContent.dailyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} {{accountPlan.currency}}/día</h2>
                                    <h4 class="monthly-prince">{{ parsedMonthlyPrice(customBudgetContent.dailyPrice)}} {{accountPlan.currency}}/mes</h4>
                                  </div>


                                </div>
                                <v-card-title >{{customBudgetContent.title }}</v-card-title>
                                <v-card-subtitle>{{customBudgetContent.description }}</v-card-subtitle>
                                <v-card-text>
                                  <div v-if="campaign.budget">
                                    <v-text-field
                                        label="Monto"
                                        :prefix="accountPlan.currency"
                                        outlined
                                        prepend-inner-icon="mdi-cash"
                                        dense
                                        type="number"
                                        v-model.number="customBudgetContent.dailyPrice"
                                        @click.stop=""
                                    ></v-text-field>
                                  </div>
                                  <v-expand-transition>
                                    <div v-if="!validBudget">
                                      <p class="red--text">¡El monto del presupuesto a medida debe ser <strong>MAYOR</strong> al del presupuesto básico!</p>
                                    </div>
                                  </v-expand-transition>
                                </v-card-text>


                              </v-card>
                            </v-item>
                          </v-col>

                        </v-row>
                      </v-container>
                    </v-item-group>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col md="4" class="d-flex justify-center">
                    <v-switch
                        v-model="customBudget"
                        label="Quiero un presupuesto a medida"
                        color="primary"
                        :value="!customBudget"
                        hide-details
                        inset
                    ></v-switch>
                  </v-col>
                </v-row>
<!--                ACALARACIONES    -->
                <v-row>
                  <v-col md="12">
                    <h3>Importante:</h3>
                  </v-col>
                  <v-col md="6" class="d-flex align-start">
                      <v-icon class="mr-2">
                        mdi-numeric-1-circle
                      </v-icon>
                      <p>El presupuesto que configures se distribuirá en las campañas de Facebook, Instagram y Google Ads. Se optimizará la distribución del mismo en base a los resultados obtenidos en cada medio y campaña.</p>


                  </v-col>
                  <v-col md="6" class="d-flex align-start">
                    <v-icon class="mr-2">
                      mdi-numeric-2-circle
                    </v-icon>
                    <p>Puede existir una diferencia de hasta un 10% entre el presupuesto que configures y la inversión real. Esto es debido a un margen que depende de las plataformas de Facebook y Google.</p>
                  </v-col>
                  <v-col md="6" class="d-flex align-start">
                    <v-icon class="mr-2">
                      mdi-numeric-3-circle
                    </v-icon>
                    <p>Las campañas utilizan un presupuesto diario. Si modificas el presupuesto durante el mes se actualizarán los presupuesto diarios consumiendo el proporcional correspondiente a los días restantes.</p>
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row class="d-flex justify-space-between">
                  <v-btn @click="toggleCreateCampaignDialog" text>
                    Salir
                  </v-btn>
                  <div>
                    <v-btn
                        text
                        @click="e1 = 4"
                    >
                      Atrás
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="createCampaign"
                        :disabled="!validBudget || !validStep5"
                        :loading="loadingCreateCampaignResponse"

                    >
                      <v-icon left>
                        mdi-content-save
                      </v-icon>
                      Guardar Campaña
                    </v-btn>
                  </div>

                </v-row>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapActions} from 'vuex'
import ShopWindowTable from "@/components/client/shopWindow/ShopWindowTable";
import countryList from "@/utils/countryList";
import httpClient from "@/api/httpClient";


export default {
  name: "CreateCampaignDialog",
  data(){
    return{
      accountPlan:null,
      availableBudgets:[],
      objectiveBudgets:[],
      dialog:false,
      e1: 1,
      objectives:[],

      hasEndDate:false,
      countries:countryList(),
      campaignCountry:null,
      customBudget:false,
      customBudgetContent:{
        dailyPrice:0,
        description:'Con este presupuesto podrás configurar el monto diario que desees.',
        id:'customBudget',
        monthlyPrice:0,
        title:'Presupuesto a Medida',
        type:'custom'
      },
      validBudget:true,
      provinces:[
          "Todo el país", "Buenos Aires Província", "GBA Zona Norte", "GBA Zona Sur", "GBA Zona Oeste",
        "Buenos Aires Interior", "Ciudad de Buenos Aires", "Catamarca", "Chaco", "Chubut", "Córdoba",
        "Corrientes", "Entre Ríos", "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones",
        "Neuquén", "Río Negro", "Salta", "San Juan", "San Luís", "Santa Cruz", "Santa Fe",
        "Santiago del Estero", "Tierra del Fuego", "Tucumán"
      ],
      campaignProvinces:null,
      campaignGenders:[],
      minAge:13,
      maxAge:100,
      ageRange:[13, 100],
      parentalState:null,
      extraInfo:'',
      loadingCreateCampaignResponse:false,
      campaign:{
        client:null,
        objective:null,
        initialConfig:{
          descriptiveName:'',
          startDate:null,
          endDate:null,
        },
        audience:{
          country:null,
          regions:[],
          gender:[],
          ageRange:{
            minAge:null,
            maxAge:null
          },
          parentalState:'',
          interests:[],
          extraInfo:''
        },
        shopWindows:[],
        budget:null
      },
      minDate:'',

      descriptiveNameRules:[
        v => !!v || 'Debes ingresar un nombre descriptivo para tu campaña.',
      ],
      startDateRules:[
        v => !!v || 'La campaña debe tener una fecha de inicio.',
      ],
      endDateRules:[
        v => !!v || 'La campaña debe tener una fecha de finalización.',
      ],
      countryRules:[
        v => !!v || 'Debes elegir un país para tu campaña.',
      ],
      regionRules:[
        v => v.length>0 || 'Debes elegir una o más regiones para tu campaña.',
      ],
      genderRules:[
        v => v.length>0 || 'Debes elegir uno o ambos géneros.',
      ],
      parentalStateRules:[
        v => !!v || 'Debes elegir un estado parental para tu campaña.',
      ],
      interestsRules:[
        v => v.length>0 || 'Debes elegir uno o ambos géneros.',
      ],
      extraInfoRules:[
        v => !!v || 'Debes ingresar información extra acerca de la campaña.',
      ],
      validStep2:false,
      validStep3:false,
      validStep4:false,
      validStep5:false,

    }
  },
  methods:{
    //GLOBALES
    ...mapActions({
      'setObjective':'campaign/setObjective',
      'setInitialConfig':'campaign/setInitialConfig',
      'setAudience':'campaign/setAudience',
      'setShopWindows':'campaign/setShopWindows',
      'setBudget':'campaign/setBudget',
    }),
    toggleCreateCampaignDialog(){
      this.$store.dispatch('campaign/toggleCreateCampaignDialog')
    },
    async createCampaign(){
      this.loadingCreateCampaignResponse = true
      try{
        this.dispatchBudget()

        this.campaign.client = this.$store.getters['account/getGlobalClient']._id
        const finalConfig = this.$store.getters['campaign/getCampaignFinalConfig']

        const response = await httpClient.post('/campaigns/myCampaign', {client:this.campaign.client,...finalConfig})
        if (response.data.status === 'success'){

          this.toggleCreateCampaignDialog()

          this.loadingCreateCampaignResponse = false

          const newCampaign = response.data.campaign

          this.$emit('campaignCreated', newCampaign)
        }
      }catch (e) {
        console.log(e)
      }
    },

    //OBJETIVOS
    getObjectives(){
      return this.objectives = this.$store.getters['campaign/getCampaignObjectives']
    },
    //Con esta funcion comparo los objetivos fijados desde el admin con los objetivos del state
    // que iteran en el v-for del componente. retorna true o false si el argumento está dentro de los obj fijados
    planIncludesObjective(defaultObjective){
      return this.accountPlan.objectives.includes(defaultObjective.objective)
    },
    pickObjective(){
      this.setObjective(this.campaign.objective)
      this.e1 = 2
    },

    //CONFIGURACION INICIAL
    setMinDate(){
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() +1)
      this.minDate = new Date(tomorrow).toISOString().slice(0,10)
    },
    dispatchInitialConfig(){
      this.setInitialConfig(this.campaign.initialConfig)
      this.e1 = 3
    },
    //AUDIENCIAS
    dispatchAudience(){
      this.setAudience(this.campaign.audience)
      this.e1 = 4
    },

    //VIDRIERAS
    populateShopWindows(payload){
      //HAY Q CHECKEAR EL EVENTO PQ SE DISPARA 3 VECES ( CREO Q ES EL WATCHER)
      this.campaign.shopWindows = payload
    },
    dispatchShopWindows(){
      this.setShopWindows(this.campaign.shopWindows)
      this.e1 = 5
    },

    //PRESUPUESTOS
    parsedMonthlyPrice(price){
      let number = price * 31
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    dispatchBudget(){
      this.setBudget(this.campaign.budget)
    }

  },
  computed:{
    dialogOn(){
      return this.$store.getters['campaign/getCreateCampaignDialogToggleState']
    },

  },
  watch:{
    dialogOn(value){
      this.dialog = value
    },
    ageRange(value){
      this.campaign.audience.ageRange.minAge = value[0]
      this.campaign.audience.ageRange.maxAge = value[1]
    },
    'campaign.objective':function (val) {

      if (val === 'tienda online'){
        this.objectiveBudgets=this.availableBudgets.tiendaOnline
      }else if (val === 'marca'){
        this.objectiveBudgets=this.availableBudgets.marca
      }else if (val === 'mensajes'){
        this.objectiveBudgets=this.availableBudgets.mensajes
      }
    },
    customBudget(val){
      if (!val){
        this.customBudgetContent.dailyPrice = 0
      }
    },
    'customBudgetContent.dailyPrice':function (val) {
      const basicBudget = this.objectiveBudgets.find(budget =>{
        return budget.type === 'basic'
      })
      const basePrice = basicBudget.dailyPrice
      if (val <= basePrice || val ===""){
       return this.validBudget = false
      }
      return this.validBudget = true
    },
    hasEndDate(val){
      if (!val){
        this.campaign.initialConfig.endDate = null
      }
    },
    'campaign.audience.gender':function (val) {
      val.length<1?this.validStep3=false:this.validStep3=true
    },
    'campaign.shopWindows':function (val) {
      val.length<1?this.validStep4=false:this.validStep4=true
    },
    'campaign.budget':function (val) {
      val&&val.dailyPrice!==""?this.validStep5=true:this.validStep5=false

      if(!this.customBudget){
        this.validBudget = true

      }

    }
  },
  mounted() {
    this.getObjectives()
    this.setMinDate()

    this.accountPlan = this.$store.getters['account/getGlobalClient'].plan
    if (this.accountPlan){
      this.availableBudgets = this.$store.getters['account/getGlobalClient'].plan.availableBudgets
    }


    //con esto seteo que el objetivo elegido por default al abrir el modal
    // sea el primero de los q estan habilitados
    this.campaign.objective = this.accountPlan.objectives[0]

  },
  components:{
    ShopWindowTable,
  },
  provide:function () {
    return {
      campaign:this.campaign
    }
  }
}
</script>

<style scoped>
.stepper{
  box-shadow: none;
}

.objective-icon{
  font-size:90px !important;
}
.star-objective-icon{
  font-size:30px !important;
  right: 3px;
  top: 3px;
}
.white-text{
  color:white !important;
}
.capitalize{
  text-transform: capitalize;
}

.custom{
  border: 3px solid #66bb6a !important;
}

.selected-icon{
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 30px;
}

.ageRangeText{
  font-size: 1.1em;
}
.monthly-prince{
  text-align: center;
  font-weight: 400;
  color: #a0a0a0;
}

</style>