<template>
  <nav>
    <v-app-bar
        app
        color="white"
        dark
        flat
        height="80"
    >
      <v-app-bar-nav-icon color="primary"  @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
            alt="Convertics Logo"
            class="shrink mr-2"
            contain
            :src="converticsLogo"
            transition="scale-transition"
            width="200"
        />
      </div>
      <v-spacer></v-spacer>

      <v-container>
        <v-row class="d-flex justify-end">
          <v-col md="3">

          </v-col>
        </v-row>
      </v-container>

      <v-menu offset-y offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              x-large icon  class="mx-10"
          >
            <v-icon dark color="primary">
              mdi-account-circle
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item

              link
              :to="{name:'ClientProfile'}"
          >
            <v-list-item-title>Mi Perfil</v-list-item-title>
          </v-list-item>
          <v-list-item
              link
              @click="signOutUser"
          >
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>



    <v-navigation-drawer
        app
        dark
        color="dark"
        v-model="drawer"
        mini-variant
        expand-on-hover
        width="200"
        mini-variant-width="70"
    >
      <v-list nav>
        <v-list-item-group
        >
          <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :to="item.route"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-menu
        transition="slide-x-transition"
        bottom
        right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="deep-orange"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Slide X Transition
        </v-btn>
      </template>

      <v-list>
        <v-list-item
            v-for="(item, i) in items"
            :key="i"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>


  </nav>
</template>

<script>

export default {
  name: "AdminNavbar",
  data(){
    return{
      drawer:false,
      converticsLogo:require('@/assets/convertics_logo.png'),
      items:[
        {
          title:'Clientes',
          icon:'mdi-briefcase-account-outline',
          route:{name:'AdminClients'}
        },
        {
          title:'Planes',
          icon:'mdi-airplane-takeoff',
          route:{name:'AdminPlans'}

        },
        {
          title:'Usuarios',
          icon:'mdi-account-circle',
          route:'/admin/usuarios',
        },
        {
          title:'Tickets',
          icon:'mdi-face-agent',
          route:{name:'AdminSupportTickets'}
        },
        {
          title:'Novedades',
          icon:'mdi-newspaper',
          route:{name:'AdminArticles'}
        }
      ],
      userClients:[],
    }
  },
  methods:{

    signOutUser(){
      this.$store.dispatch('signOutUser')
    }
  },

  /*HAY QUE REVISAR LA LOGICA DEL SELECTOR DE CUENTA, ESTA FUNCIONANDO MAGICAMENTE*/
  computed:{
    globalClient:{
      get:function () {
        return this.$store.getters['account/getGlobalClient']
      },
      set:function (val) {
        // this.setGlobalClient(val)
        //retorno val ya que si no despacha 2 veces el action de setglobalclient
        return val
      }
    }
  },
  mounted() {

  }
}
</script>