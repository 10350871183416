<template>
  <div class="article">

    <v-bottom-sheet v-if="document" v-model="toggleSheet" persistent inset scrollable>
      <v-card>
        <div :style="document.image?`background:url(${document.image.location}) rgba(4, 2, 1, 0.6);background-size:cover;background-blend-mode: multiply; background-position-x:center top;`:`background:url(${defaultArticleBackground}) rgba(4, 2, 1, 0.6);background-size:cover;background-blend-mode: multiply; background-position-x:center top;`">
          <v-container>
            <v-row>
              <v-col md="12" class="d-flex align-center justify-space-between">
                <v-btn
                    @click="emitCloseSheet"
                    icon
                    color="white"
                    large
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
                <v-chip color="info lighten-2"  label >
                  <v-icon left>
                    mdi-tag
                  </v-icon>
                  {{document.tag}}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
          <v-card-title  class="card-title d-flex flex-column align-center justify-center" >
            <h1 class="heading white--text"> {{document.title}}</h1>
            <h4 class="heading white--text mt-5">{{document.caption}}</h4>
          </v-card-title>
        </div>
        <v-card-text class="py-3 mt-4">
          <div class="mt-5" v-html="document.description"></div>
        </v-card-text>
        <v-card-actions class="mt-3 mb-5 justify-space-between">
          <v-btn
              text
              @click="emitCloseSheet"

          >
            Salir
          </v-btn>
          <v-btn
              outlined
              color="info"
              :href="document.file.location"
          >
            <v-icon left>
              mdi-cloud-download
            </v-icon>
            Descargar Documento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>


  </div>
</template>

<script>
export default {
  name: "ArticleDetail",
  props:['sheet', 'document'],
  data(){
    return{
      toggleSheet:false,
      defaultArticleBackground:require('@/assets/default_article_background.svg'),

    }
  },
  methods:{
    emitCloseSheet(){
      this.toggleSheet = false
      this.$emit('closeSheet', false)
    }
  },
  watch:{
    sheet(val){
      this.toggleSheet = val
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.card-title{
  height: 200px !important;
}

</style>