<template>
    <nav>
      <v-app-bar
          app
          color="white"
          dark
          flat
          height="80"
      >
        <v-app-bar-nav-icon color="primary"  @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <div class="d-flex align-center">
          <v-img
              alt="Convertics Logo"
              class="shrink mr-2"
              contain
              :src="converticsLogo"
              transition="scale-transition"
              width="200"
          />

          <v-img
              alt="Vuetify Name"
              class="shrink mt-1 hidden-sm-and-down"
              contain
              min-width="100"
              src=""
              width="100"
          />
        </div>
        <v-spacer></v-spacer>

        <v-container>
          <v-row class="d-flex justify-end">
              <v-col md="3">
                <v-select
                    v-model="globalClient"
                    :items="userClients"
                    item-text="name"
                    item-value="_id"
                    label="Mis Cuentas:"
                    flat
                    light
                    hide-details
                    color="primary"
                    @change="setGlobalClient"
                ></v-select>
              </v-col>
          </v-row>
        </v-container>

        <v-menu  left nudge-left="50" max-width="350"  open-on-hover close-delay="300" :disabled="news.length<1">
          <template v-slot:activator="{ on, attrs }">

            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                large
                icon
                class="mr-3 ml-7"

            >
              <v-icon dark color="primary" v-text="notificationIcon">
              </v-icon>
              <div v-if="news.length>0" class="notification-alert"></div>
            </v-btn>
          </template>
          <v-list v-for="item in news" :key="item._id" class="py-0"
          >
            <v-list-item
                link
                :to="{name:'ClientNews'}"
            >
              <v-list-item-avatar>
                <img
                    v-if="item.image"
                    :src="item.image.location"
                    alt="avatar"
                >
                <v-icon v-else color="primary">
                  mdi-newspaper
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.caption }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="ma-0"></v-divider>
          </v-list>
        </v-menu>

        <v-menu offset-y offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                x-large
                icon
                class="mr-10 ml-3"
            >
              <v-icon dark color="primary">
                mdi-account-circle
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item

                link
                :to="{name:'ClientProfile'}"
            >
              <v-list-item-title>Mi Perfil</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                @click="signOutUser"
            >
              <v-list-item-title>Cerrar Sesión</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>


<!-- Menu navegación-->
      <v-navigation-drawer
          app
          dark
          color="dark"
          v-model="drawer"
          mini-variant
          expand-on-hover
          width="200"
          mini-variant-width="70"
      >
        <v-list nav>
          <v-list-item-group
          >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                :to="item.route"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
<!--      Menu de usuario-->
      <v-menu
          transition="slide-x-transition"
          bottom
          right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="deep-orange"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Slide X Transition
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


    </nav>
</template>

<script>

export default {
  name: "Navbar",
  data(){
    return{
      drawer:false,
      converticsLogo:require('@/assets/convertics_logo.png'),
      items:[
        {
          title:'Campañas',
          icon:'mdi-bullhorn',
          route:{name:'ClientCampaigns'}
        },
        {
          title:'Métricas',
          icon:'mdi-chart-bar',
          route:'/misMetricas',
          routeName:''
        },
        {
          title:'Catálogo',
          icon:'mdi-format-list-bulleted-type',
          route: {name:'ClientProductCatalogue'},
        },
        {
          title:'Soporte',
          icon:'mdi-face-agent',
          route:{name:'ClientSupport'}
        },
        {
          title:'Novedades',
          icon:'mdi-newspaper',
          route:{name:'ClientNews'}
        },
        {
          title:'Ayuda',
          icon:'mdi-file-document-outline',
          route:{name:'ClientDocuments'}
        }
      ],
      userClients:[],
      news:[],
      selectedClient:''
    }
  },
  methods:{
    setGlobalClient(clientId){
      this.$store.dispatch('account/setGlobalClient', clientId)
    },
    signOutUser(){
      this.$store.dispatch('signOutUser')
    },
    async getRecentNews(){
      this.news = await this.$store.getters['news/getRecentNews']
    }



    // async getMyClients(){
    //
    //     const response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/users/myClients`, {
    //       headers:{
    //         Authorization:localStorage.getItem('token')
    //       }
    //     })
    //   if (response.data.status === 'success'){
    //     this.userClients = response.data.data
    //   }
    //
    // }
  },

  /*HAY QUE REVISAR LA LOGICA DEL SELECTOR DE CUENTA, ESTA FUNCIONANDO MAGICAMENTE*/
  computed:{
    globalClient:{
      get:function () {
        return this.$store.getters['account/getGlobalClient']
      },
      set:function (val) {
        // this.setGlobalClient(val)
        //retorno val ya que si no despacha 2 veces el action de setglobalclient
        return val
      }
    },
    notificationIcon(){
      let icon = 'mdi-bell'
      if (this.news.length>0){
        icon = 'mdi-bell-ring'
      }
      return icon
    }
  },
  watch:{
    globalClient(value){
      this.globalClient = value
    },
  },
   mounted() {
    const user = this.$store.getters['getAuthenticatedUser']
    if (user){
      this.userClients = user.clients

      // const defaultGlobalClient = this.userClients[0]
      // this.$store.dispatch('account/setGlobalClient', defaultGlobalClient)
      // this.$store.dispatch('account/updateUserClientsLocally', myClients)
      // this.getRecentNews()
      this.news =  this.$store.getters['news/getRecentNews']

    }

   }

}
</script>

<style scoped>
  .notification-alert{
    border-radius: 50%;
    background-color: red;
    height: 8px;
    width: 8px;
    position: absolute;
    top:-10px;
    right: 0;

  }
</style>