import Vue from 'vue'
import Vuex from 'vuex'
import rootMutations from './mutations'
import rootGetters from './getters'
import rootActions from './actions'

import campaignModule from './modules/campaign/index'
import shopWindowModule from './modules/shopWindow/index'
import accountModule from './modules/account/index'
import newsModule from './modules/news/index'
import helpArticleModule from './modules/helpArticles/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authenticatedUser:null,
  },
  mutations: rootMutations,
  getters: rootGetters,
  actions: rootActions,
  modules: {
    campaign: campaignModule,
    shopWindow: shopWindowModule,
    account: accountModule,
    news: newsModule,
    helpArticle:helpArticleModule
  }
})

