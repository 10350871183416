import {getRecentNews} from "@/api/helpArticle.api";

export default {
    async getRecentNews(context){
        const response = await getRecentNews()
        const recentNews = response.data.data

        if (recentNews.length > 0){
            context.commit('setRecentNews', recentNews)
        }
    }
}