<template>
  <v-row justify="center">

    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>

        <v-app-bar
            dark
            color="primary"
            fixed
            height="80"
        >
          <v-btn
              icon
              dark
              @click="emitCloseDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Actualizar plan : {{ currentPlan.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                x-large
                text
                :loading="loadingResponse"
                @click="updatePlan"

            >
              <v-icon left>
                mdi-content-save
              </v-icon>
              Guardar
            </v-btn>
          </v-toolbar-items>

        </v-app-bar>


        <v-container class="mt-12 py-15">
          <v-row>
            <v-col md="12">
              <div >
                <h2>1. Propiedades del plan</h2>
                <p class="ml-5 mb-0">Configura las propiedades del plan.</p>
              </div>
            </v-col>
          </v-row>
          <v-form class="mt-5">
            <v-row>
              <v-col md="3">
                <v-autocomplete
                    outlined
                    dense
                    label="El plan será asignado a"
                    prepend-inner-icon="mdi-briefcase-outline"
                    :items="clients"
                    v-model="newPlan.client"
                    item-text="name"
                    item-value="_id"
                >
                </v-autocomplete>
              </v-col>
              <v-col md="3">
                <v-text-field
                    outlined
                    dense
                    label="Nombre del plan"
                    prepend-inner-icon="mdi-text-short"
                    v-model="newPlan.name"

                >
                </v-text-field>
              </v-col>
              <v-col md="2">
                <v-select
                    outlined
                    dense
                    label="Moneda a utilizar"
                    prepend-inner-icon="mdi-cash-marker"
                    :items="currencies"
                    v-model="newPlan.currency"
                    item-text="name"
                >
                </v-select>
              </v-col>
              <v-col md="3">
                <v-text-field
                    type="number"

                    outlined
                    dense
                    label="Precio"
                    prepend-inner-icon="mdi-currency-usd"
                    v-model.number="newPlan.price"
                    :disabled="!newPlan.currency"

                    :prefix="newPlan.currency"
                >
                </v-text-field>
              </v-col>
              <v-col md="3">
                <v-text-field
                    outlined
                    dense
                    label="Inversión mínima por audiencia"
                    prepend-inner-icon="mdi-cash-multiple"
                    :prefix="newPlan.currency"
                    type="number"
                    :disabled="!newPlan.currency"

                    v-model.number="newPlan.audienceMinInvestment"
                >
                </v-text-field>
              </v-col>

            </v-row>
          </v-form>

          <!--------OBJETIVOS-------->
          <v-row class="mt-10">
            <v-col md="12">
              <div >
                <h2>2. Habilitar Objetivos</h2>
                <p class="ml-5 mb-0">Establece los objetivos que el cliente podrá elegir para sus campañas.</p>
              </div>
            </v-col>
            <v-col md="12" class="d-flex align-center">
              <h4>Objetivos Actuales:</h4>
              <v-chip
                  label
                  color="info"
                  v-for="currentObjective in currentPlan.objectives"
                  :key="currentObjective"
                  class="ml-3"
              >
                <v-icon left v-if="currentObjective === 'tienda online'">
                  mdi-store
                </v-icon>
                <v-icon left v-else-if="currentObjective === 'marca'">
                  mdi-bullhorn
                </v-icon>
                <v-icon left v-else>
                  mdi-message
                </v-icon>
                {{currentObjective}}
              </v-chip>

            </v-col>
            <v-item-group mandatory  multiple v-model="newPlan.objectives"  active-class="custom">
              <v-container>
                <v-row >
                  <v-col
                      v-for="objective in objectives"
                      :key="objective.objective"
                      cols="12"
                      md="4"
                  >
                    <v-item v-slot="{ active, toggle }" :value="objective.objective">
                      <v-card
                          @click="toggle"
                          outlined
                          height="300"
                          flat
                          class="rounded-xl"

                      >
                        <div class="d-flex justify-end">
                          <v-icon class="mr-2 mt-2 selected-icon" :color="active?'primary':''">
                            {{ active ? 'mdi-checkbox-marked-circle' : '' }}
                          </v-icon>
                        </div>
                        <div class="d-flex justify-center">
                          <v-icon
                              :color="active ? 'primary':''"
                              class="objective-icon mt-6"
                          >
                            {{ objective.icon }}
                          </v-icon>
                        </div>
                        <v-card-title  class="capitalize">{{ objective.objective }}</v-card-title>
                        <v-card-subtitle >{{objective.description}}</v-card-subtitle>
                      </v-card>
                    </v-item>
                  </v-col>

                </v-row>
              </v-container>

            </v-item-group>
          </v-row>

          <!--------PRESUPUESTOS------------>
          <v-row class="mt-10">
            <v-col md="12">
              <div >
                <h2>3. Configurar Presupuestos</h2>
                <p class="ml-5 mb-0">Establece los objetivos que el cliente podrá elegir para sus campañas.</p>
              </div>
            </v-col>

            <v-col md="12">
              <v-card class="rounded-xl" flat outlined>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="3"></v-col>
                      <v-col md="3" class="d-flex flex-column align-center">
                        <v-icon class=" objective-icon mt-6 " :disabled="!newPlan.objectives.includes('tienda online')">
                          mdi-store
                        </v-icon>
                        <h4>
                          Presupuesto tienda online
                        </h4>
                      </v-col>
                      <v-col md="3" class="d-flex flex-column align-center">
                        <v-icon class=" objective-icon mt-6 " :disabled="!newPlan.objectives.includes('marca')">
                          mdi-bullhorn
                        </v-icon>
                        <h4>
                          Presupuesto marca
                        </h4>
                      </v-col>
                      <v-col md="3" class="d-flex flex-column align-center">
                        <v-icon class=" objective-icon mt-6 " :disabled="!newPlan.objectives.includes('mensajes')">
                          mdi-message
                        </v-icon>
                        <h4>
                          Presupuesto mensajes
                        </h4>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="3"><h4>Presupuesto básico (diario)</h4></v-col>
                      <v-col md="3">
                        <v-text-field
                          label="Monto"
                          :prefix="newPlan.currency"
                          outlined
                          prepend-inner-icon="mdi-cash"
                          dense
                          :disabled="!newPlan.objectives.includes('tienda online') || !newPlan.currency"

                          type="number"
                          v-model.number="newPlan.availableBudgets.tiendaOnline[0].dailyPrice"
                      ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="newPlan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!newPlan.objectives.includes('marca') || !newPlan.currency"
                            type="number"
                            v-model.number="newPlan.availableBudgets.marca[0].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="newPlan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!newPlan.objectives.includes('mensajes') || !newPlan.currency"
                            type="number"
                            v-model.number="newPlan.availableBudgets.mensajes[0].dailyPrice"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-8"></v-divider>
                    <v-row>
                      <v-col md="3"><h4>Presupuesto recomendado (diario)</h4></v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="newPlan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!newPlan.objectives.includes('tienda online') || !newPlan.currency"

                            type="number"
                            v-model.number="newPlan.availableBudgets.tiendaOnline[1].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="newPlan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!newPlan.objectives.includes('marca') || !newPlan.currency"
                            type="number"
                            v-model.number="newPlan.availableBudgets.marca[1].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="newPlan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!newPlan.objectives.includes('mensajes') || !newPlan.currency"
                            type="number"
                            v-model.number="newPlan.availableBudgets.mensajes[1].dailyPrice"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-8"></v-divider>
                    <v-row>
                      <v-col md="3"><h4>Presupuesto recomendado (diario)</h4></v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="newPlan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!newPlan.objectives.includes('tienda online') || !newPlan.currency"

                            type="number"
                            v-model.number="newPlan.availableBudgets.tiendaOnline[2].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="newPlan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!newPlan.objectives.includes('marca') || !newPlan.currency"
                            type="number"
                            v-model.number="newPlan.availableBudgets.marca[2].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="newPlan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!newPlan.objectives.includes('mensajes') || !newPlan.currency"
                            type="number"
                            v-model.number="newPlan.availableBudgets.mensajes[2].dailyPrice"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>


import httpClient from "@/api/httpClient";

export default {
  name: "AdminUpdatePlanDialog",
  props:['toggleDialog', 'currentPlan'],
  data(){
    return {
      dialog: false,
      loadingResponse: false,
      clients: [],
      currencies: [
        'ARS',
        'BRL',
        'CLP',
        'COP',
        'EUR',
        'MXN',
        'PEN',
        'USD',
        'UYU',

      ],
      objectives: [
        {
          objective: 'tienda online',
          description: 'Promocionar los productos de mi tienda online ante potenciales clientes (Instagram, Facebook, Google).',
          icon: 'mdi-store',
          disabled: false
        },
        {
          objective: 'marca',
          description: 'Generar mayor conocimiento sobre mi marca (Llegar a más personas, Aumentar el tráfico al perfil de Instagram, Aumentar las interacciones con mis posteos).',
          icon: 'mdi-bullhorn',
          disabled: false
        },
        {
          objective: 'mensajes',
          description: 'Incentivar a mis potenciales clientes a contactarme vía mensajes directos (Whatsapp, Facebook Messenger, Instagram Direct).',
          icon: 'mdi-message',
          disabled: false
        }
      ],
      newPlan: {
        client: null,
        name: '',
        price: 0,
        audienceMinInvestment: 0,
        currency: null,
        objectives: [],
        availableBudgets: {
          tiendaOnline:[
            {
              id: 'basicPlan',
              type: 'basic',
              dailyPrice: 0,
              monthlyPrice: '',
              title: 'Presupuesto Básico',
              description: 'Esto es lo mínimo que debes invertir para activar la campaña.',
              starCount: 1
            },
            {
              id: 'recommendedPlan',
              type: 'recommended',
              dailyPrice: 0,
              monthlyPrice: '',
              title: 'Presupuesto Recomendado',
              description: 'Esto es lo que te recomendamos invertir para obtener mejores resultados.',
              starCount: 2
            },
            {
              id: 'optimalPlan',
              type: 'optimal',
              dailyPrice: 0,
              monthlyPrice: '',
              title: 'Presupuesto Ideal',
              description: 'Este es el presupuesto ideal para maximizar tus resultados.',
              starCount: 3
            }
          ],
          marca:[
            {
              id: 'basicPlan',
              type: 'basic',
              dailyPrice: 0,
              monthlyPrice: '',
              title: 'Presupuesto Básico',
              description: 'Esto es lo mínimo que debes invertir para activar la campaña.',
              starCount: 1
            },
            {
              id: 'recommendedPlan',
              type: 'recommended',
              dailyPrice: 0,
              monthlyPrice: '',
              title: 'Presupuesto Recomendado',
              description: 'Esto es lo que te recomendamos invertir para obtener mejores resultados.',
              starCount: 2
            },
            {
              id: 'optimalPlan',
              type: 'optimal',
              dailyPrice: 0,
              monthlyPrice: '',
              title: 'Presupuesto Ideal',
              description: 'Este es el presupuesto ideal para maximizar tus resultados.',
              starCount: 3
            }
          ],
          mensajes:[
            {
              id: 'basicPlan',
              type: 'basic',
              dailyPrice: 0,
              monthlyPrice: '',
              title: 'Presupuesto Básico',
              description: 'Esto es lo mínimo que debes invertir para activar la campaña.',
              starCount: 1
            },
            {
              id: 'recommendedPlan',
              type: 'recommended',
              dailyPrice: 0,
              monthlyPrice: '',
              title: 'Presupuesto Recomendado',
              description: 'Esto es lo que te recomendamos invertir para obtener mejores resultados.',
              starCount: 2
            },
            {
              id: 'optimalPlan',
              type: 'optimal',
              dailyPrice: 0,
              monthlyPrice: '',
              title: 'Presupuesto Ideal',
              description: 'Este es el presupuesto ideal para maximizar tus resultados.',
              starCount: 3
            }
          ],
        },
      },
      // currentObjectives es una copia de los objetivos viejos de la campaña para actualizar
      currentObjectives:null,
      moneyRules: [
        v => !!v || 'Debe ingresar un monto.',
        v => (typeof v === 'number') || 'El nombre debe tener menos de 30 caracteres.',
      ],
    }
  },
  methods: {
    emitCloseDialog() {
      this.dialog = false
      this.$emit('closeDialog', false)
    },
    resetFields() {
      this.newPlan.client = null
      this.newPlan.name = ''
      this.newPlan.currency = null
      this.newPlan.price = ''
      this.newPlan.audienceMinInvestment = ''
      this.newPlan.objectives = []
      this.newPlan.availableBudgets.tiendaOnline[0].dailyPrice = ''
      this.newPlan.availableBudgets.tiendaOnline[1].dailyPrice = ''
      this.newPlan.availableBudgets.tiendaOnline[2].dailyPrice = ''

      this.newPlan.availableBudgets.marca[0].dailyPrice = ''
      this.newPlan.availableBudgets.marca[1].dailyPrice = ''
      this.newPlan.availableBudgets.marca[2].dailyPrice = ''

      this.newPlan.availableBudgets.mensajes[0].dailyPrice = ''
      this.newPlan.availableBudgets.mensajes[1].dailyPrice = ''
      this.newPlan.availableBudgets.mensajes[2].dailyPrice = ''
    },
    async getAdminClients() {
      const response = await httpClient.get(`/clients`)
      if (response.data.status === 'success') {
        this.clients = response.data.data.clients
      }
    },
    async updatePlan() {
      this.loadingResponse = true

      delete this.newPlan.__v
      delete this.newPlan.createdAt
      delete this.newPlan.updatedAt
      delete this.newPlan._id

      try {
        const response = await httpClient.patch(`/plans/${this.currentPlan._id}`, this.newPlan)
        if (response.data.status === 'success') {

          this.$emit('planUpdated')

          this.loadingResponse = false

          this.emitCloseDialog()

          this.resetFields()
        }
      } catch (e) {
          this.loadingResponse = false
          this.emitCloseDialog()
          this.resetFields()
          this.$emit('responseFail', e.response.data.message)
      }
    },
    parsedMonthlyPrice(price){
      let number = price * 31
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },


  },
  watch:{
    toggleDialog(val){
      this.dialog = val
    },
    currentPlan(val){
      if (val){
        this.newPlan = {... val}
      }
    },
    'newPlan.objectives':function (val) {
     if (!val.includes('tienda online')){
       return this.newPlan.availableBudgets.tiendaOnline
     }else if(!val.includes('marca')){
       return this.newPlan.availableBudgets.marca
     }else if(!val.includes('mensajes')){
       return this.newPlan.availableBudgets.mensajes
     }
    },
  },
  mounted() {

    this.getAdminClients()

    if (this.currentPlan){
      this.newPlan = {... this.currentPlan}
    }

  }
}
</script>

<style scoped>
.objective-icon{
  font-size:60px !important;
}
.star-objective-icon{
  font-size:30px !important;
  right: 3px;
  top: 3px;
}

.capitalize{
  text-transform: capitalize;
}

.custom{
  border: 3px solid #66bb6a !important;
}
.selected-icon{
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 30px;
}

.monthly-prince{
  text-align: center;
  font-weight: 400;
  color: #acacac;
}
</style>