import httpClient from "@/api/httpClient";

const getMyClients = async (userId) =>{
    return await httpClient.get(`/clients/myClients/${userId}`)
}

const submitInitialData = async (clientId, accountData) =>{
    return await httpClient.patch(`/clients/myClients/${clientId}/storeInitialData`, accountData)
}


const updateMyProducts = async (accountId) =>{
    return await httpClient.patch(`/clients/myClients/${accountId}/updateMyProducts`)
}

export default {
    getMyClients,
    submitInitialData,
    updateMyProducts
}