import httpClient from "@/api/httpClient";

const END_POINT = '/helpArticles'


const createHelpArticle = () =>{

}

const getAllArticles = () => httpClient.get(END_POINT)
const getRecentArticles = async () => await httpClient.get(END_POINT+'/recent?type=documento')


const deleteArticleAssets = async (articleId,assetType) => await httpClient.delete(`${END_POINT}/deleteFile/${articleId}?fileType=${assetType}`)



const getRecentNews = async () => await httpClient.get(END_POINT+'/recent?type=novedad')






export{
    createHelpArticle,
    getAllArticles,
    getRecentArticles,
    deleteArticleAssets,
    getRecentNews
}