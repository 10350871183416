<template>
  <div>
    <v-card width="350" outlined elevation="2">
      <v-container>
        <v-row>
          <v-col md="12" class="ig-logo-container d-flex justify-center pa-0">
            <img :src="igLogo" alt="" height="45">
          </v-col>
        </v-row>
        <v-card-text class="px-0">
          <v-row class="mt-1">
            <v-col md="2" class="py-0" >
              <v-avatar width="45" height="45">
                <img
                    :src="this.img?this.img:'https://via.placeholder.com/50'"
                    alt="John"
                    style="border: 1px solid #cacaca"
                >
              </v-avatar>
            </v-col>
            <v-col md="8" class="py-0">
              <h4>{{ this.$store.getters["account/getGlobalClient"].name }}</h4>
              <span>Publicidad</span>
            </v-col>
            <v-col md="2" class="d-flex justify-center">
              <v-icon>
                mdi-dots-horizontal
              </v-icon>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col md="12" class="d-flex justify-center">
              <img :src="this.img?this.img:'https://via.placeholder.com/600'" height="280" width="280" class="ig-image" alt="">
            </v-col>
          </v-row>
          <v-row class="pt-1 pb-3 pl-1">
            <v-col md="12" class="d-flex justify-space-between">
              <h4 class="ma-0">Ver más</h4>
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pt-3 pb-3 pl-1">
            <v-col md="4" class="d-flex justify-space-between">
              <v-icon>
                mdi-heart-outline
              </v-icon>
              <v-icon>
                mdi-chat-outline
              </v-icon>
              <v-icon>
                mdi-send-outline
              </v-icon>
            </v-col>
            <v-col md="6" class="d-flex align-center pl-5">
              <div class="ig-carrousel-dots first-dot"></div>
              <div class="ig-carrousel-dots"></div>
              <div class="ig-carrousel-dots"></div>
              <div class="ig-carrousel-dots"></div>
              <div class="ig-carrousel-dots"></div>
            </v-col>
            <v-col md="2" class="d-flex justify-end">
              <v-icon>
                mdi-bookmark-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="d-flex flex-column">
              <div class="d-flex">
                <p class="mb-0">
                  <strong>
                    {{ this.$store.getters["account/getGlobalClient"].name }}
                  </strong>

                  {{title }}
                </p>
              </div>
              <p v-if="!readMoreActivated && description">
                {{description.slice(0, 35)+' ...'}}
                <a class="" v-if="!readMoreActivated" @click="readMoreActivated = true" >
                  Más
                </a>
              </p>

              <v-expand-transition>
                <p v-if="readMoreActivated">
                  {{description}}
                  <a class="" v-if="readMoreActivated" @click="readMoreActivated = false">
                    Menos
                  </a>
                </p>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "InstagramPreview",
  props:['img', 'title', 'description'],
  data(){
    return {
      igLogo:require('@/assets/ig_logo.png'),
      readMoreActivated:false,
    }
  },
  methods:{
    activateReadMore(){
      this.readMoreActivated = true;
    },
  }
}
</script>

<style scoped>
.ig-logo-container{
  background-color: #f8f8f8;
  border-bottom: 1px solid #bfbfbf;
}

.ig-image{
  width: 100%;
}
.ig-carrousel-dots{
  height: 10px;
  width: 10px;
  background-color: grey;
  border-radius: 50%;
  margin-right: 8px;
}
.first-dot{
  background-color: #62aed7;

}
</style>