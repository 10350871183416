<template>
  <div class="supportTickets">
    <v-container>
      <v-row>
        <v-col md="12">
          <h1 class="subheading text-md-h4">Tickets de Soporte</h1>
        </v-col>
      </v-row>

    </v-container>
    <v-container>
      <v-row>
        <v-col md="12">
          <v-card max-height="500" class="rounded-xl">
            <v-card-title class="d-flex justify-space-between">
              <v-col md="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    outlined
                    dense
                    hide-details
                ></v-text-field>
              </v-col>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="tickets"
                :search="search"
                :loading="loadingTickets"
                loading-text="Cargando ... Por favor aguarde"
                height="370"

            >

              <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="toggleUpdateTicket(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="alertDelete(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>





    <!--Dialogo actualizar Ticket-->

    <v-dialog
        v-model="toggleUpdateTicketDialog"
        persistent
        max-width="600px"
        v-if="updatableTicket"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Actualizar Ticket : {{ updatableTicket._id }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="12"
              >
                <v-textarea
                    outlined
                    label="Mensaje de"
                    rows="3"
                    v-model="updatableTicket.message"
                    disabled
                    hide-details
                    prepend-inner-icon="mdi-text"

                />
              </v-col>
              <v-col class="d-flex" md="12">
                <v-select
                    :items="statusOptions"
                    v-model="updatableTicket.status"
                    outlined
                    label="Estado"
                    prepend-inner-icon="mdi-list-status"

                />
              </v-col>
              <v-col md="12">
                <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="updatableTicket.statusChangeTimestamp"

                        label="Fecha cambio de estado"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="updatableTicket.statusChangeTimestamp"

                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="12"
                  md="12"
              >
                <v-textarea
                    outlined
                    label="Comentarios del Administrador"
                    rows="3"
                    v-model="updatableTicket.comment"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="toggleUpdateTicketDialog = !toggleUpdateTicketDialog"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"

              @click="updateTicket"
          >
            Guardar cambios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <!--Dialogo eliminar Ticket-->

    <v-dialog
        v-model="toggleAlertDelete"
        max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          ¿Está seguro que desea eliminar el ticket?
        </v-card-title>
        <v-card-subtitle class="text-center">
          <v-icon x-large class="text-h2">
            mdi-alert
          </v-icon>
        </v-card-subtitle>
        <v-card-text>
          Si elimina el ticket no podrá recuperar sus datos.
        </v-card-text>

        <v-card-actions>

          <v-btn
              color="green darken-1"
              text
              @click="cancelDelete"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="red darken-1"
              @click="deleteTicket"
              outlined
          >
            <v-icon>
              mdi-delete
            </v-icon>
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import httpClient from "@/api/httpClient";

export default {
  name: "AdminSupportTickets",
  data(){
    return{
      showPicker: false,
      search:'',
      tickets:[],
      loadingTickets:false,
      headers: [
        { text: 'ID', value: '_id', sortable:false },
        { text: 'Cliente', align: 'start', value: 'client.name'},
        { text: 'Motivo', value: 'subject' },
        { text: 'Estado', value: 'status' },
        { text: 'Recibido el', value: 'createdAt'  },
        { text: 'Estado cambió el', value: 'statusChangeTimestamp'  },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      validForm:false,
      nameRules:[
        v => !!v || 'Debe ingresar un nombre.',
        v => (v && v.length <= 30) || 'El nombre debe tener menos de 30 caracteres.',
      ],
      emailRules:[
        v => !!v || 'Debe ingresar un email.',

      ],
      phoneRules:[
        v => !!v || 'Debe ingresar un teléfono.',

      ],
      toggleUpdateTicketDialog:false,
      updatableTicket:null,
      toggleAlertDelete:false,
      deletableTicketId:'',
      statusOptions:['nuevo', 'pendiente', 'resuelto', 'cerrado']
    }
  },
  methods:{
    validateForm() {
      this.$refs.form.validate()
    },
    async getAdminTickets(){
      this.loadingTickets = !this.loadingTickets
      const response = await httpClient.get(`/support`)
      if (response.data.status ==='success'){
        this.tickets = response.data.data.tickets
        this.tickets.map(ticket =>{
          if (ticket.statusChangeTimestamp){
            return ticket.statusChangeTimestamp = ticket.statusChangeTimestamp.substring(0,10)

          }
        })
        this.tickets.map(ticket =>{
          return ticket.createdAt = ticket.createdAt.substring(0,10)
        })
        this.loadingTickets = false
      }
    },
    toggleUpdateTicket(item){
      this.toggleUpdateTicketDialog = !this.toggleUpdateTicketDialog
      this.updatableTicket = item
    },
    async updateTicket(){
      const {status, statusChangeTimestamp, comment} = this.updatableTicket
      const response = await httpClient.patch(`/support/${this.updatableTicket._id}`,{
        status,
        statusChangeTimestamp,
        comment,
      })
      if(response.data.status === 'success'){
        this.toggleUpdateTicketDialog = false
      }
    },

    async alertDelete(item){
      this.toggleAlertDelete = true
      this.deletableTicketId = item._id
    },
    cancelDelete(){
      this.deletableTicketId = ''
      this.toggleAlertDelete = !this.toggleAlertDelete
    },
    async deleteTicket(){

      if (this.deletableTicketId){
        const response = await httpClient.delete(`/support/${this.deletableTicketId}`)
        const deletedTicket = response.data.data.ticket
        if (response.data.status === 'success'){
          this.toggleAlertDelete = false
        }
        this.tickets = this.tickets.filter(ticket =>{
          return ticket._id !== deletedTicket._id
        })
      }
    }
  },
  created() {
    this.getAdminTickets()
  }
}
</script>

<style scoped>

</style>