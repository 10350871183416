export default {
    toggleCreateCampaignDialog(state){
        state.createCampaignDialog = !state.createCampaignDialog
    },
    setObjective(state, payload) {
        state.newCampaign.objective = payload
    },
    setInitialConfig(state, payload){
        state.newCampaign.initialConfig.descriptiveName = payload.descriptiveName
        state.newCampaign.initialConfig.startDate = payload.startDate
        state.newCampaign.initialConfig.endDate = payload.endDate
    },
    setAudience(state, payload){
        state.newCampaign.audience.country = payload.country
        state.newCampaign.audience.regions = payload.regions
        state.newCampaign.audience.gender = payload.gender
        state.newCampaign.audience.ageRange.minAge = payload.ageRange.minAge
        state.newCampaign.audience.ageRange.maxAge = payload.ageRange.maxAge
        state.newCampaign.audience.parentalState = payload.parentalState
        state.newCampaign.audience.interests = payload.interests
        state.newCampaign.audience.extraInfo = payload.extraInfo
    },
    setShopWindows(state, payload){
        state.newCampaign.shopWindows = payload
    },
    setBudget(state, payload){
        state.newCampaign.budget = payload
    }
}