<template>
  <v-row justify="center">

    <v-dialog
        v-model="toggle"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card color="grey lighten-3">
        <v-app-bar
            dark
            color="primary"
            fixed
            height="60"
            elevation="1"
        >
          <v-btn
              icon
              dark
              @click="emitCloseDialog"
          >
            <v-icon large>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Detalle de cliente : {{client.name}} (ID:{{client._id}})</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          </v-toolbar-items>
        </v-app-bar>

        <v-card-text class="mt-15 pt-10">
          <div>
            <v-container>
              <v-row>
                <!--Datos generales-->
                <v-col md="12">
                  <v-card class="mb-5 rounded-xl" outlined elevation="2">
                    <v-card-title class="px-8 pb-1">GENERAL</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="2" class="d-flex  ">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-briefcase</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Nombre</h3>
                                <span >{{client.name}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="3">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1" >mdi-account</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Contacto Administrativo</h3>
                                <span  >{{client.administrativeContact}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="3">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-at</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Email administrativo</h3>
                                <span  >{{client.administrativeContactEmail}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="4">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-phone</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Teléfono contacto administrativo</h3>
                                <span  >{{client.administrativeContactPhone}}</span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <v-divider class="my-6" ></v-divider>

                        <v-row >
                          <v-col md="2" class="d-flex align-center">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-map</v-icon>
                              <div class="d-flex flex-column">
                                <h3>País</h3>
                                <span >{{client.country}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="3">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-identifier</v-icon>
                              <div class="d-flex flex-column">
                                <h3>ID Fiscal</h3>
                                <span >{{client.fiscalId}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="3">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-calendar-check</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Creado el</h3>
                                <span >{{new Date(client.createdAt).toLocaleDateString()}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="3">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-calendar-refresh</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Actualizado el</h3>
                                <span >{{new Date(client.updatedAt).toLocaleDateString()}}</span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Informacion del negocio-->
              <v-row>
                <v-col md="12">
                  <v-card class="mb-5 rounded-xl" outlined elevation="2">
                    <v-card-title class="px-8 pb-1">INFORMACIÓN DEL NEGOCIO</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="3">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-filter-variant</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Rubro</h3>
                                <span >
                                  {{client.businessInfo.workArea}}
                                </span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="3" class="d-flex  ">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-cart</v-icon>
                              <div class="d-flex flex-column">
                                <h3>URL Tienda Online</h3>
                                <span >
                                  <a :href="client.businessInfo.shopURL" target="_blank">
                                    Ir a la tienda
                                  </a>
                                </span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="3">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1" >mdi-instagram</v-icon>
                              <div class="d-flex flex-column">
                                <h3>URL Perfíl Instagram</h3>
                                <span >
                                  <a :href="client.businessInfo.instagramURL" target="_blank">
                                    Ir al perfíl
                                  </a>
                                </span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="3">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-facebook</v-icon>
                              <div class="d-flex flex-column">
                                <h3>URL Perfíl Facebook</h3>
                                <span >
                                  <a :href="client.businessInfo.facebookURL" target="_blank">
                                    Ir al perfíl
                                  </a>
                                </span>
                              </div>
                            </div>
                          </v-col>

                        </v-row>

                        <v-divider class="my-6" ></v-divider>

                        <v-row >
                          <v-col md="3" class="d-flex align-center">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-cloud-tags</v-icon>
                              <div class="d-flex flex-column">
                                <h3>XML Productos</h3>
                                <span >
                                  <a :href="client.businessInfo.xmlURL" target="_blank">
                                    Ir al archivo XML
                                  </a>
                                </span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="4" class="d-flex align-center">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-domain</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Competencia</h3>
                                <span >
                                  <v-chip class="mr-2 mb-1" small color="orange" outlined label v-for="competitor in client.businessInfo.competition" :key="competitor">
                                    {{competitor}}
                                  </v-chip>
                                </span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="5">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-information-outline</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Información destacada</h3>
                                <span>
                                  <v-chip class="mr-2 mb-1" small color="orange" outlined label v-for="info in client.businessInfo.featuredInfo" :key="info">
                                    {{info}}
                                  </v-chip>
                                </span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="12">
                            <div class="d-flex align-start ">
                              <v-icon large left color="grey lighten-1">mdi-text</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Descripción del negocio</h3>
                                <span>
                                  <span>
                                    {{client.businessInfo.description}}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- Campañas-->
              <v-row>
                <v-col md="9">
                  <v-card class="mb-5 rounded-xl"  outlined elevation="2">
                    <v-card-title class="px-8 ">CAMPAÑAS</v-card-title>

                    <div class="d-flex align-center justify-space-between">
                      <v-col md="5" class="py-0 ">
                        <v-text-field
                            class="ml-5"
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col md="2" class="py-0 mr-4">
                        <v-switch
                            v-model="enableCampaignEdit"
                            :value="!enableCampaignEdit"
                            inset
                            class="mr-8"
                            prepend-icon="mdi-pencil"
                        ></v-switch>
                      </v-col>

                    </div>

                    <v-card-text>
                      <v-container>
                        <v-row>
                         <v-col md="12">
                           <v-data-table
                            :items="campaigns"
                            :headers="headers"
                            :loading="loadingCampaigns"
                            :items-per-page="5"
                            :search="search"
                            calculate-widths

                           >
                             <template v-slot:item.initialConfig.descriptiveName="{ item }" >
                               <a :class="item.status==='activa'?'activa':'inactiva'" @click.prevent="toggleCampaignDetailDialog(item)">{{item.initialConfig.descriptiveName}}</a>
                             </template>
                             <template v-slot:item.status="{ item }">
<!--                               <v-switch-->
<!--                                   v-model="item.status"-->
<!--                                   true-value="activa"-->
<!--                                   false-value="inactiva"-->
<!--                                   inset-->
<!--                                   :label="item.status"-->
<!--                                   :disabled="!enableCampaignEdit"-->
<!--                                   :loading="loadingResponse"-->
<!--                                   @click="updateCampaignStatus(item)"-->
<!--                                   dense-->
<!--                               ></v-switch>-->
                               <v-select
                                   class="pt-5"
                                   v-model="item.status"
                                   :items="statusArray"
                                   :disabled="!enableCampaignEdit"
                                   :loading="loadingResponse"
                                   outlined
                                   dense
                                   @change="updateCampaignStatus(item)"


                               >

                               </v-select>
                             </template>
                             <template v-slot:item.actions="{ item }" >
                               <v-icon color="primary" small @click="toggleCampaignDetailDialog(item)" class="mr-4">
                                 mdi-eye
                               </v-icon>
                               <v-icon color="red lighten-2" small @click="alertDelete(item)" :disabled="!enableCampaignEdit">
                                 mdi-delete
                               </v-icon>
                             </template>
                           </v-data-table>
                         </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>

                </v-col>

                <!-- PLAN-->
                <v-col md="3">
                  <v-card class="mb-5 rounded-xl"  outlined elevation="2">
                    <v-card-title class="px-8 pb-1">PLAN</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="12" class="d-flex  ">
                            <div class="d-flex align-start ">
                              <v-icon large left  color="grey lighten-1">mdi-airplane</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Plan vigente</h3>
                                <span >{{client.plan.name}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="12">
                            <div class="d-flex align-start ">
                              <v-icon large left  color="grey lighten-1">mdi-target</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Objetivos Habilitados</h3>
                                <div>
                                  <v-chip label outlined color="orange" class="mr-2 mt-1" v-for="objective in client.plan.objectives" :key="objective">
                                  {{objective}}
                                </v-chip>
                                </div>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="12">
                            <div class="d-flex align-start ">
                              <v-icon large left  color="grey lighten-1">mdi-currency-usd</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Precio</h3>
                                <span >{{client.plan.currency+' '+client.plan.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="12">
                            <div class="d-flex align-start ">
                              <v-icon large left  color="grey lighten-1">mdi-cash</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Inv. min. / audiencia</h3>
                                <span >{{client.plan.currency+' '+client.plan.audienceMinInvestment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>


            </v-container>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>



<!--    DIALOGO DETALLE CAMPAÑA-->
    <v-dialog
        v-model="campaignDetailDialog"
        width="1200"
        v-if="currentCampaign"
    >
      <v-card>
        <v-card-title class="headline d-flex justify-space-between">
          ID: {{currentCampaign._id}}
          <v-chip dark label :color="currentCampaign.status === 'activa'?'primary':'grey'">
            <v-icon left>
              {{ currentCampaign.status === 'activa'? 'mdi-play-circle-outline': 'mdi-pause-circle-outline' }}
            </v-icon>
            {{currentCampaign.status.toUpperCase()}}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <div>
            <v-container>
              <v-row>
                <v-col md="8">
                  <!--CONFIG INICIAL-->
                  <v-card class="mb-5 rounded-xl" flat outlined>
                    <v-card-title class="px-8 pb-1">CONFIGURACIÓN INICIAL</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="12" class="d-flex align-center justify-space-around">
                            <div class="d-flex align-start ">
                              <v-icon left color="grey lighten-1">mdi-text-short</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Nombre</h3>
                                <span >{{currentCampaign.initialConfig.descriptiveName}}</span>
                              </div>
                            </div>
                            <div class="d-flex align-start ">
                              <v-icon left color="grey lighten-1" >mdi-calendar-refresh</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Comienza el</h3>
                                <span  >{{new Date(currentCampaign.initialConfig.startDate).toLocaleDateString()}}</span>
                              </div>
                            </div>
                            <div class="d-flex align-start ">
                              <v-icon left color="grey lighten-1">mdi-calendar-check</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Finaliza el</h3>
                                <span  >{{currentCampaign.initialConfig.endDate? new Date(currentCampaign.initialConfig.endDate).toLocaleDateString(): 'No posee'}}</span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>

                    </v-card-text>
                  </v-card>
                  <!--CONFIG AUDIENCIAS-->
                  <v-expansion-panels flat  >
                    <v-expansion-panel class="mb-5 rounded-xl audience-expansion-panel" >
                      <v-expansion-panel-header>
                        <v-card-title class="py-0">
                          <!--                          <v-icon left>-->
                          <!--                            mdi-account-group-->
                          <!--                          </v-icon>-->
                          AUDIENCIA
                        </v-card-title>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content >
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col md="3" class="">
                                <div class="d-flex align-start ">
                                  <v-icon left color="grey lighten-1">mdi-map</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>País</h3>
                                    <span >{{currentCampaign.audience.country}}</span>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="4">
                                <div class="d-flex align-start ">
                                  <v-icon left color="grey lighten-1" >mdi-calendar-refresh</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Rango Etáreo</h3>
                                    <span>De {{currentCampaign.audience.ageRange.minAge}} a {{currentCampaign.audience.ageRange.maxAge}} años</span>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="5">
                                <div class="d-flex align-start ">
                                  <v-icon left color="grey lighten-1">mdi-baby-carriage</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Estado Parental</h3>
                                    <span  >{{currentCampaign.audience.parentalState? currentCampaign.audience.parentalState: 'No posee'}}</span>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <div class="d-flex align-start ">
                                  <v-icon left color="grey lighten-1">mdi-map-marker</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Regiones</h3>
                                    <div class="d-flex flex-wrap">
                                      <v-chip v-for="region in currentCampaign.audience.regions" :key="region"
                                              outlined
                                              class="mr-2 mt-1"
                                              color="orange"
                                      >
                                        {{region}}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <div class="d-flex align-start ">
                                  <v-icon left color="grey lighten-1">mdi-bookmark-multiple</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Intereses</h3>
                                    <div class="d-flex flex-wrap">
                                      <v-chip v-for="interest in currentCampaign.audience.interests" :key="interest"
                                              outlined
                                              class="mr-2 mt-1"
                                              color="orange"
                                      >
                                        {{interest}}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <div class="d-flex align-start ">
                                  <v-icon left color="grey lighten-1">mdi-gender-male-female</v-icon>
                                  <div class="d-flex flex-column">
                                    <h3>Género</h3>
                                    <div class="d-flex">
                                      <v-chip v-for="gender in currentCampaign.audience.gender" :key="gender"
                                              outlined
                                              class="mr-2 mt-1"
                                              :color="gender === 'mujeres'? 'pink':'blue'"
                                              label
                                      >
                                        {{gender}}
                                      </v-chip>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                              <v-col md="12">
                                <div class="d-flex align-start ">
                                  <div class="d-flex flex-column">
                                    <h3>Información Extra</h3>
                                    <span >{{currentCampaign.audience.extraInfo?currentCampaign.audience.extraInfo:'no posee'}}</span>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>

                        </v-card-text>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <!--CONFIG VIDRIERAS-->
                  <v-card v-if="currentCampaign.shopWindows.length>0" class="mb-5 rounded-xl" flat outlined>
                    <div class="d-flex justify-space-between">
                      <v-card-title class="px-8 pb-1">VIDRIERAS</v-card-title>
                    </div>
                    <div class="d-flex align-center justify-space-between pl-8 pr-4">

                      <div class="mr-6">
                        <v-select
                            outlined
                            dense
                            :items="currentCampaign.shopWindows"
                            item-text="name"
                            hide-details
                            label="Vidrieras"
                            full-width
                            v-model="selectedShopWindow"
                            return-object
                        >
                        </v-select>
                      </div>
                      <div class="mr-2 ">
                        <v-btn   outlined color="orange lighten-1" @click="showProducts = !showProducts">
                          <v-icon left>
                            {{!showProducts?'mdi-eye':'mdi-eye-off'}}
                          </v-icon>
                          {{!showProducts?'Mostrar productos':'Ocultar productos'}}
                        </v-btn>
                      </div>
                    </div>
                    <v-card-text>
                      <v-container>
                        <v-row>

                          <v-expand-transition v-if="showProducts" >
                            <v-col md="12">
                              <div >
                                <v-data-table
                                    :headers="shopWindowHeaders"
                                    :items="selectedShopWindow.products"
                                    loading-text="Cargando ... Por favor aguarde"
                                    class="v-data-table"
                                    height="320"
                                    :items-per-page="5"
                                >
                                  <template v-slot:item.image="{ item }">
                                    <div class="p-2">
                                      <v-img :src="item['image_link']" :alt="item.title" class="product-image-container" width="50px" height="50px"></v-img>
                                    </div>
                                  </template>

                                </v-data-table>
                              </div>
                            </v-col>
                          </v-expand-transition>
                        </v-row>
                        <v-row>
                          <v-col md="4">
                            <div class="d-flex align-start ">
                              <v-icon left color="grey lighten-1">mdi-text-short</v-icon>
                              <div  class="d-flex flex-column">
                                <h3>ID Vidriera</h3>
                                <span >{{selectedShopWindow._id}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="4">
                            <div class="d-flex align-start ">
                              <v-icon left color="grey lighten-1">mdi-text-short</v-icon>
                              <div  class="d-flex flex-column">
                                <h3>Nombre de la vidriera</h3>
                                <span >{{selectedShopWindow.name}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="4">
                            <div class="d-flex align-start">
                              <v-icon left color="grey lighten-1" >mdi-calendar-check</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Fue creada el</h3>
                                <span  >{{new Date(selectedShopWindow.createdAt).toLocaleDateString()}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="4">
                            <div class="d-flex align-start">
                              <v-icon left color="grey lighten-1" >mdi-calendar-check</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Fue actualizada el</h3>
                                <span  >{{new Date(selectedShopWindow.updatedAt).toLocaleDateString()}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="4">
                            <div class="d-flex align-start">
                              <v-icon left color="grey lighten-1">mdi-cart</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Productos Totales</h3>
                                <span >{{selectedShopWindow.products.length}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="4">
                            <div class="d-flex align-start ">
                              <v-icon left color="grey lighten-1">mdi-download</v-icon>
                              <div  class="d-flex flex-column">
                                <h3>Imagen</h3>
                                <a v-if="selectedShopWindow.image" :href="selectedShopWindow.image.location" target="_blank">Descargar imagen del anuncio</a>
                                <p v-else>No Posee</p>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="4" class="d-flex">
                            <div class="d-flex align-start ">
                              <v-icon left color="grey lighten-1">mdi-text-short</v-icon>
                              <div  class="d-flex flex-column">
                                <h3>Título del anuncio</h3>
                                <span >{{selectedShopWindow.title?selectedShopWindow.title:''}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="12">
                            <div class="d-flex align-start ">
                              <v-icon left color="grey lighten-1">mdi-calendar-check</v-icon>
                              <div class="d-flex flex-column">
                                <h3>Texto Principal</h3>
                                <span  >{{selectedShopWindow.copy}}</span>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>

                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="4">
                  <!--INFO GENERAL-->
                  <v-card class="mb-5 rounded-xl"  flat outlined>
                    <v-card-title class="px-8 pb-1">INFORMACIÓN GENERAL</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="6" class="">
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="grey lighten-1">mdi-target</v-icon>
                              <div>
                                <h3>Objetivo</h3>
                                <span >{{currentCampaign.objective}}</span>
                              </div>
                            </div>
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="grey lighten-1">mdi-calendar-check</v-icon>
                              <div>
                                <h3>Estado</h3>
                                <v-chip label small :color="currentCampaign.status === 'activa'?'primary':''">{{currentCampaign.status}}</v-chip>
                              </div>
                            </div>

                          </v-col>
                          <v-col md="6">
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="grey lighten-1">mdi-calendar-check</v-icon>
                              <div>
                                <h3>Creada el</h3>
                                <span >{{new Date(currentCampaign.createdAt).toLocaleDateString()}}</span>
                              </div>
                            </div>
                            <div class="d-flex align-start mb-1">
                              <v-icon left color="grey lighten-1">mdi-calendar-refresh</v-icon>
                              <div>
                                <h3>Actualizada el</h3>
                                <span >{{new Date(currentCampaign.updatedAt).toLocaleDateString()}}</span>
                              </div>
                            </div>

                          </v-col>
                        </v-row>
                      </v-container>

                    </v-card-text>
                  </v-card>
                  <!-- PRESUPUESTO-->
                  <v-card class="rounded-xl" flat outlined >
                    <v-card-title class="px-8 pb-1">PRESUPUESTO</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col md="5">
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="grey lighten-1">mdi-cash</v-icon>
                              <div>
                                <h3>Inversión Diaria</h3>
                                <span >{{currentCampaign.budget.dailyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</span>
                              </div>
                            </div>
                          </v-col>
                          <v-col md="7">
                            <div class="d-flex align-start mb-4">
                              <v-icon left color="grey lighten-1">mdi-airplane-takeoff</v-icon>
                              <div>
                                <h3>Categoría</h3>
                                <span >{{currentCampaign.budget.title}}</span>
                                <div>
                                  <v-icon small v-for="n in currentCampaign.budget.starCount" :key="n">
                                    mdi-star
                                  </v-icon>
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
              text
              @click="closeCampaignDetailDialog"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Dialogo eliminar campaña-->

    <v-dialog
        v-model="toggleAlertDelete"
        max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          ¿Está seguro que desea eliminar la campaña?
        </v-card-title>
        <v-card-subtitle class="text-center">
          <v-icon x-large class="text-h2">
            mdi-alert
          </v-icon>
        </v-card-subtitle>
        <v-card-text>
          Si la campaña no podrá recuperar sus datos.
        </v-card-text>

        <v-card-actions>

          <v-btn
              color="green darken-1"
              text
              @click="cancelDelete"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              color="red darken-1"
              outlined
              :loading="loadingResponse"
              @click="deleteCampaign"
          >
            <v-icon>
              mdi-delete
            </v-icon>
            eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-row>
</template>

<script>
import httpClient from "@/api/httpClient";

export default {
  name: "AdminClientDetailDialog",
  props:['toggle', 'client'],
  data(){
    return{
      campaigns:[],
      search:'',
      headers:[
        { text: 'ID', value: '_id'},
        { text: 'Nombre', value: 'initialConfig.descriptiveName'},
        { text: 'Objetivo', value: 'objective' },
        { text: 'Inv Diaria', value: 'budget.dailyPrice'},
        { text: "Estado", value: "status", sortable: false, align:'center', width:'200px' },
        { text: "Acciones", value: "actions", sortable: false, align:'center'  },
      ],
      statusArray:['activa', 'inactiva', 'en revisión'],
      loadingCampaigns:false,
      enableCampaignEdit:false,
      loadingResponse:false,
      campaignDetailDialog:false,
      currentCampaign : null,
      selectedShopWindow:null,
      showProducts:false,
      shopWindowHeaders: [
        { text: 'Identificador', value: 'id' },
        { text: "Imagen", value: "image", sortable: false },
        {
          text: 'Producto',
          align: 'start',
          value: 'title',
        },
        { text: 'Precio', value: 'price' },
        { text: 'Disponibilidad', value: 'availability' },

      ],
      toggleAlertDelete:false,
      deletableCampaignId:null
    }
  },
  methods:{
    emitCloseDialog(){
      this.$emit('closeClientDetailDialog')
    },
    async getClientCampaigns(){
      this.loadingCampaigns = true
      try{
        const response = await httpClient.get(`/campaigns/myCampaigns/${this.client._id}`)
        if (response.data.status === 'success'){
          this.campaigns = response.data.campaigns
          this.campaigns.map(campaign =>{
            return campaign.budget.dailyPrice = this.client.plan.currency + ' ' + campaign.budget.dailyPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          })
          this.loadingCampaigns = false
        }
      }catch (e) {
        console.log(e)
      }
    },
    async updateCampaignStatus(item){
        this.loadingResponse = true
        try{
          const response = await httpClient.patch(`/campaigns/myCampaigns/${item._id}`, {
            status:item.status
          })
          if (response.data.status === 'success'){
            this.loadingResponse = false
          }
        }catch (e) {
          console.log(e)
        }
    },
    toggleCampaignDetailDialog(item){
      this.campaignDetailDialog = true
      this.currentCampaign = item
    },
    closeCampaignDetailDialog(){
      //Seteo un currentCampaign cualquiera por default para que no tire error
      this.currentCampaign = this.campaigns[0]
      this.campaignDetailDialog = false
    },
    async alertDelete(item){
      this.toggleAlertDelete = true
      this.deletableCampaignId = item._id
    },
    cancelDelete(){
      this.deletableCampaignId = ''
      this.toggleAlertDelete = !this.toggleAlertDelete
    },
    async deleteCampaign(){
      if (this.deletableCampaignId){
        try{
          this.loadingResponse = true
          const response = await httpClient.delete(`/campaigns/${this.deletableCampaignId}`)
          const deletedCampaign = response.data.campaign
          if (response.data.status === 'success'){
            this.toggleAlertDelete = false
            this.loadingResponse = false
          }
          this.campaigns = this.campaigns.filter(campaign =>{
            return campaign._id !== deletedCampaign._id
          })
        }catch (e) {
          if (e.response.data.status === 'error' || e.response.data.status === 'fail'){
            console.log(e.response.data.message)
            this.errorMessage = e.response.data.message
            this.snackbar = true
            this.loadingResponse=false
          }
        }
      }
    },

  },
  watch:{
    client(){
      this.getClientCampaigns()
    },
    currentCampaign(val){
      this.selectedShopWindow = val.shopWindows[0]
    },

  },
  beforeMount() {
  },
  mounted() {
    this.getClientCampaigns()
    if (this.currentCampaign){
      this.selectedShopWindow = this.currentCampaign.shopWindows[0]

    }

  }
}
</script>

<style scoped>
.activa {
 color: #4caf50 ;
}
.inactiva {
  color: #393939;
}

.product-image-container{
  border-radius: 50%;
  border: 2px solid #94c3ea;
}
.v-data-table {
  line-height: 1.5;
}

.audience-expansion-panel{
  border: 1px solid #dddddd;
}
</style>