import actions from "@/store/modules/news/actions";
import mutations from "@/store/modules/news/mutations";
import getters from "@/store/modules/news/getters";

export default {
    namespaced:true,
    state:{
        recentNews:[]
    },
    actions,
    mutations,
    getters
}