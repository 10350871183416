<template>
  <v-row justify="center">

    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>

        <v-app-bar
            dark
            color="primary"
            fixed
            height="80"
        >
          <v-btn
              icon
              dark
              @click="emitCloseDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Crear Nuevo Plan</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                dark
                x-large
                text
                :loading="loadingResponse"
                @click="createPlan"

            >
              <v-icon left>
                mdi-content-save
              </v-icon>
              Guardar
            </v-btn>
          </v-toolbar-items>

        </v-app-bar>


        <v-container class="mt-12 py-15">
          <v-row>
            <v-col md="12">
              <div >
                <h2>1. Propiedades del plan</h2>
                <p class="ml-5 mb-0">Configura las propiedades del plan.</p>
              </div>
            </v-col>
          </v-row>
          <v-form class="mt-5">
            <v-row>
              <v-col md="3">
                <v-autocomplete
                    outlined
                    dense
                    label="El plan será asignado a"
                    prepend-inner-icon="mdi-briefcase-outline"
                    :items="clients"
                    v-model="plan.client"
                    item-text="name"
                    item-value="_id"
                >
                </v-autocomplete>
              </v-col>
              <v-col md="3">
                <v-text-field
                    outlined
                    dense
                    label="Nombre del plan"
                    prepend-inner-icon="mdi-text-short"
                    v-model="plan.name"

                >
                </v-text-field>
              </v-col>
              <v-col md="2">
                <v-select
                    outlined
                    dense
                    label="Moneda a utilizar"
                    prepend-inner-icon="mdi-cash-marker"
                    :items="currencies"
                    v-model="plan.currency"
                    item-text="name"
                >
                </v-select>
              </v-col>
              <v-col md="3">
                <v-text-field
                    type="number"

                    outlined
                    dense
                    label="Precio"
                    prepend-inner-icon="mdi-currency-usd"
                    v-model.number="plan.price"
                    :disabled="!plan.currency"

                    :prefix="plan.currency"
                >
                </v-text-field>
              </v-col>
              <v-col md="3">
                <v-text-field
                    outlined
                    dense
                    label="Inversión mínima por audiencia"
                    prepend-inner-icon="mdi-cash-multiple"
                    :prefix="plan.currency"
                    type="number"
                    :disabled="!plan.currency"

                    v-model.number="plan.audienceMinInvestment"
                >
                </v-text-field>
              </v-col>

            </v-row>
          </v-form>

<!--------OBJETIVOS-------->
          <v-row class="mt-10">
            <v-col md="12">
              <div >
                <h2>2. Habilitar Objetivos</h2>
                <p class="ml-5 mb-0">Establece los objetivos que el cliente podrá elegir para sus campañas.</p>
              </div>
            </v-col>
            <v-item-group mandatory  multiple v-model="plan.objectives"  active-class="custom">
              <v-container>
                <v-row >
                  <v-col
                      v-for="objective in objectives"
                      :key="objective.objective"
                      cols="12"
                      md="4"
                  >
                    <v-item v-slot="{ active, toggle }" :value="objective.objective">
                      <v-card
                          @click="toggle"
                          outlined
                          flat
                          rounded

                      >
                        <div class="d-flex justify-end">
                          <v-icon class="mr-2 mt-2 selected-icon" :color="active?'primary':''">
                            {{ active ? 'mdi-checkbox-marked-circle' : '' }}
                          </v-icon>
                        </div>
                        <div class="d-flex justify-center">
                          <v-icon
                              :color="active ? 'primary':''"
                              class="objective-icon mt-6"
                          >
                            {{ objective.icon }}
                          </v-icon>
                        </div>
                        <v-card-title  class="capitalize">{{ objective.objective }}</v-card-title>
                        <v-card-subtitle >{{objective.description}}</v-card-subtitle>
                      </v-card>
                    </v-item>
                  </v-col>

                </v-row>
              </v-container>

            </v-item-group>
          </v-row>

<!--------PRESUPUESTOS------------>
          <v-row class="mt-10">
            <v-col md="12">
              <div >
                <h2>3. Configurar Presupuestos</h2>
                <p class="ml-5 mb-0">Establece los objetivos que el cliente podrá elegir para sus campañas.</p>
              </div>
            </v-col>
            <v-col md="12">
              <v-card class="rounded-xl" flat outlined>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="3"></v-col>
                      <v-col md="3" class="d-flex flex-column align-center">
                        <v-icon class=" objective-icon mt-6 " :disabled="!plan.objectives.includes('tienda online')">
                          mdi-store
                        </v-icon>
                        <h4>
                          Presupuesto tienda online
                        </h4>
                      </v-col>
                      <v-col md="3" class="d-flex flex-column align-center">
                        <v-icon class=" objective-icon mt-6 " :disabled="!plan.objectives.includes('marca')">
                          mdi-bullhorn
                        </v-icon>
                        <h4>
                          Presupuesto marca
                        </h4>
                      </v-col>
                      <v-col md="3" class="d-flex flex-column align-center">
                        <v-icon class=" objective-icon mt-6 " :disabled="!plan.objectives.includes('mensajes')">
                          mdi-message
                        </v-icon>
                        <h4>
                          Presupuesto mensajes
                        </h4>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="3"><h4>Presupuesto básico (diario)</h4></v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="plan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!plan.objectives.includes('tienda online') || !plan.currency"

                            type="number"
                            v-model.number="plan.availableBudgets.tiendaOnline[0].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="plan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!plan.objectives.includes('marca') || !plan.currency"
                            type="number"
                            v-model.number="plan.availableBudgets.marca[0].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="plan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!plan.objectives.includes('mensajes') || !plan.currency"
                            type="number"
                            v-model.number="plan.availableBudgets.mensajes[0].dailyPrice"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-8"></v-divider>
                    <v-row>
                      <v-col md="3"><h4>Presupuesto recomendado (diario)</h4></v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="plan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!plan.objectives.includes('tienda online') || !plan.currency"

                            type="number"
                            v-model.number="plan.availableBudgets.tiendaOnline[1].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="plan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!plan.objectives.includes('marca') || !plan.currency"
                            type="number"
                            v-model.number="plan.availableBudgets.marca[1].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="plan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!plan.objectives.includes('mensajes') || !plan.currency"
                            type="number"
                            v-model.number="plan.availableBudgets.mensajes[1].dailyPrice"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-8"></v-divider>
                    <v-row>
                      <v-col md="3"><h4>Presupuesto ideal (diario)</h4></v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="plan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!plan.objectives.includes('tienda online') || !plan.currency"

                            type="number"
                            v-model.number="plan.availableBudgets.tiendaOnline[2].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="plan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!plan.objectives.includes('marca') || !plan.currency"
                            type="number"
                            v-model.number="plan.availableBudgets.marca[2].dailyPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                            label="Monto"
                            :prefix="plan.currency"
                            outlined
                            prepend-inner-icon="mdi-cash"
                            dense
                            :disabled="!plan.objectives.includes('mensajes') || !plan.currency"
                            type="number"
                            v-model.number="plan.availableBudgets.mensajes[2].dailyPrice"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>

          </v-row>
        </v-container>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>


import httpClient from "@/api/httpClient";

export default {
  name: "AdminCreatePlanDialog",
  props:['toggleDialog'],
  data(){
    return {
      dialog: false,
      loadingResponse: false,
      clients: [],
      currencies: [
        'ARS',
        'BRL',
        'CLP',
        'COP',
        'EUR',
        'MXN',
        'PEN',
        'USD',
        'UYU',

      ],
      objectives: [
        {
          objective: 'tienda online',
          description: 'Promocionar los productos de mi tienda online ante potenciales clientes (Instagram, Facebook, Google).',
          icon: 'mdi-store',
          disabled: false
        },
        {
          objective: 'marca',
          description: 'Generar mayor conocimiento sobre mi marca (Llegar a más personas, Aumentar el tráfico al perfil de Instagram, Aumentar las interacciones con mis posteos).',
          icon: 'mdi-bullhorn',
          disabled: false
        },
        {
          objective: 'mensajes',
          description: 'Incentivar a mis potenciales clientes a contactarme vía mensajes directos (Whatsapp, Facebook Messenger, Instagram Direct).',
          icon: 'mdi-message',
          disabled: false
        }
      ],
      plan: {
        client: null,
        name: '',
        price: 0,
        audienceMinInvestment: 0,
        currency: null,
        objectives: [],
        // availableBudgets: [
        //   {
        //     id: 'basicPlan',
        //     type: 'basic',
        //     dailyPrice: 0,
        //     monthlyPrice: '',
        //     title: 'Presupuesto Básico',
        //     description: 'Esto es lo mínimo que debes invertir para activar la campaña.',
        //     starCount: 1
        //   },
        //   {
        //     id: 'recommendedPlan',
        //     type: 'recommended',
        //     dailyPrice: 0,
        //     monthlyPrice: '',
        //     title: 'Presupuesto Recomendado',
        //     description: 'Esto es lo que te recomendamos invertir para obtener mejores resultados.',
        //     starCount: 2
        //   },
        //   {
        //     id: 'optimalPlan',
        //     type: 'optimal',
        //     dailyPrice: 0,
        //     monthlyPrice: '',
        //     title: 'Presupuesto Ideal',
        //     description: 'Este es el presupuesto ideal para maximizar tus resultados.',
        //     starCount: 3
        //   }
        // ],
        availableBudgets:
          {
            tiendaOnline:[
              {
                id: 'basicPlan',
                type: 'basic',
                dailyPrice: 0,
                monthlyPrice: '',
                title: 'Presupuesto Básico',
                description: 'Esto es lo mínimo que debes invertir para activar la campaña.',
                starCount: 1
              },
              {
                id: 'recommendedPlan',
                type: 'recommended',
                dailyPrice: 0,
                monthlyPrice: '',
                title: 'Presupuesto Recomendado',
                description: 'Esto es lo que te recomendamos invertir para obtener mejores resultados.',
                starCount: 2
              },
              {
                id: 'optimalPlan',
                type: 'optimal',
                dailyPrice: 0,
                monthlyPrice: '',
                title: 'Presupuesto Ideal',
                description: 'Este es el presupuesto ideal para maximizar tus resultados.',
                starCount: 3
              }
            ],
            marca:[
              {
                id: 'basicPlan',
                type: 'basic',
                dailyPrice: 0,
                monthlyPrice: '',
                title: 'Presupuesto Básico',
                description: 'Esto es lo mínimo que debes invertir para activar la campaña.',
                starCount: 1
              },
              {
                id: 'recommendedPlan',
                type: 'recommended',
                dailyPrice: 0,
                monthlyPrice: '',
                title: 'Presupuesto Recomendado',
                description: 'Esto es lo que te recomendamos invertir para obtener mejores resultados.',
                starCount: 2
              },
              {
                id: 'optimalPlan',
                type: 'optimal',
                dailyPrice: 0,
                monthlyPrice: '',
                title: 'Presupuesto Ideal',
                description: 'Este es el presupuesto ideal para maximizar tus resultados.',
                starCount: 3
              }
            ],
            mensajes:[
              {
                id: 'basicPlan',
                type: 'basic',
                dailyPrice: 0,
                monthlyPrice: '',
                title: 'Presupuesto Básico',
                description: 'Esto es lo mínimo que debes invertir para activar la campaña.',
                starCount: 1
              },
              {
                id: 'recommendedPlan',
                type: 'recommended',
                dailyPrice: 0,
                monthlyPrice: '',
                title: 'Presupuesto Recomendado',
                description: 'Esto es lo que te recomendamos invertir para obtener mejores resultados.',
                starCount: 2
              },
              {
                id: 'optimalPlan',
                type: 'optimal',
                dailyPrice: 0,
                monthlyPrice: '',
                title: 'Presupuesto Ideal',
                description: 'Este es el presupuesto ideal para maximizar tus resultados.',
                starCount: 3
              }
            ],
          }
      },
      // currentObjectives es una copia de los objetivos viejos de la campaña para actualizar
      currentObjectives:null,
      moneyRules: [
        v => !!v || 'Debe ingresar un monto.',
        v => (typeof v === 'number') || 'El nombre debe tener menos de 30 caracteres.',
      ],
    }
  },
  methods: {
    emitCloseDialog() {
      this.dialog = false
      this.$emit('closeDialog', false)
    },
    resetFields() {
      this.plan.client = null
      this.plan.name = ''
      this.plan.currency = null
      this.plan.price = ''
      this.plan.audienceMinInvestment = ''
      this.plan.objectives = []
      this.plan.availableBudgets.tiendaOnline[0].dailyPrice = ''
      this.plan.availableBudgets.tiendaOnline[1].dailyPrice = ''
      this.plan.availableBudgets.tiendaOnline[2].dailyPrice = ''

      this.plan.availableBudgets.marca[0].dailyPrice = ''
      this.plan.availableBudgets.marca[1].dailyPrice = ''
      this.plan.availableBudgets.marca[2].dailyPrice = ''

      this.plan.availableBudgets.mensajes[0].dailyPrice = ''
      this.plan.availableBudgets.mensajes[1].dailyPrice = ''
      this.plan.availableBudgets.mensajes[2].dailyPrice = ''
    },
    async getAdminClients() {
      const response = await httpClient.get(`/clients`)
      if (response.data.status === 'success') {
        this.clients = response.data.data.clients
      }
    },
    async createPlan() {
      this.loadingResponse = true
      try {
        const response = await httpClient.post('/plans', this.plan)
        if (response.data.status === 'success') {
          let newPlan = response.data.plan

          newPlan.client = newPlan.client.name
          this.$emit('planCreated', newPlan)

          this.loadingResponse = false

          this.emitCloseDialog()

          this.resetFields()
        }
      } catch (e) {
          this.loadingResponse = false
          this.emitCloseDialog()
          this.resetFields()
          this.$emit('responseFail', e.response.data.message)
      }
    },
    parsedMonthlyPrice(price){
      let number = price * 31
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  },
  watch:{
    toggleDialog(val){
      this.dialog = val
    },

  },
  mounted() {

    this.getAdminClients()



  }
}
</script>

<style scoped>
.objective-icon{
  font-size:90px !important;
}
.star-objective-icon{
  font-size:30px !important;
  right: 3px;
  top: 3px;
}

.capitalize{
  text-transform: capitalize;
}

.custom{
  border: 3px solid #66bb6a !important;
}
.selected-icon{
  position: fixed;
  right: 3px;
  top: 3px;
  font-size: 30px;
}

.monthly-prince{
  text-align: center;
  font-weight: 400;
  color: #acacac;
}
</style>