<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <v-card height="450">
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            top
            height="6"
            striped
            background-color="grey"
            color="primary accent-4"
        ></v-progress-linear>
        <v-card-title class="justify-center">
          <v-container class="my-6">
            <v-row class="d-flex justify-center">
              <v-img
                  alt="Convertics Logo"
                  class="shrink mr-2"
                  contain
                  :src="converticsLogo"
                  transition="scale-transition"
                  width="250"
              ></v-img>
            </v-row>
          </v-container>
          <span class="text-h4">Iniciar sesión</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-form ref="form"
                    v-model="validForm">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="email"
                      label="Email"
                      required
                      outlined
                      class="input-field"
                      clearable
                      prepend-inner-icon="mdi-email"
                      :rules="emailRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="password"
                      label="Contraseña"
                      required
                      outlined
                      class="input-field"
                      clearable
                      prepend-inner-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"
                      autocomplete="newpassword"
                      :rules="passwordRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>

          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-space-between align-center">
                  <a>¿Olvidó su contraseña?</a>
                  <v-btn
                      color="primary"
                      @click="signIn"
                      :loading="loading"
                      :disabled="loading || !validForm"
                  >
                    Ingresar
                  </v-btn>
                </div>

              </v-col>
            </v-row>
          </v-container>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar"
        top
        class="my-10"
        timeout="6000"
    >
      <v-icon left>mdi-alert</v-icon>
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"

        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
// import router from "@/router";

import axios from 'axios'
import router from "@/router";

export default {
  name: "Login",
  data: () => ({
    dialog: true,
    showPassword:false,
    email:'',
    password:'',
    converticsLogo:require('@/assets/convertics_logo.png'),
    loading:false,
    validForm:false,
    emailRules: [
      v => !!v || 'Debe ingresar una casilla de e-mail.',
      v => /.+@.+\..+/.test(v) || 'El e-mail debe tener un formato válido.',
    ],
    passwordRules: [
      v => !!v || 'Debe ingresar una contraseña.',
      v => (v && v.length >= 8) || 'La contraseña debe tener al menos 8 caracteres.',
    ],
    errorMessage:'',
    snackbar:false
  }),
  methods:{
    validateForm() {
      this.$refs.form.validate()
    },
    signIn: async function () {
      this.loading = !this.loading

      if (this.validForm) {
        try {
          const response = await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/users/login`, {
            email: this.email,
            password: this.password
          })
          if (response.data.status === 'success') {
            const {user, token} = response.data
            await this.$store.dispatch('signInUser', {user, token})

            if (user.role === 'admin') {
              return router.push({path:'/admin/clientes'})
            }
            return router.push({path:'/misCampañas'})

          }

        } catch (e) {
          if (e.response.data.status === 'fail') {
            this.errorMessage = e.response.data.message
            this.snackbar = true
            this.loading = false
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  .input-field{
    background-color: white;
  }
</style>