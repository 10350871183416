<template>
 <div class="dashboard">
   <iframe  :src="dataStudioURL"  style="width:100%; height:100%; border:0" allowfullscreen=""></iframe>
 </div>
</template>

<script>
import httpClient from "@/api/httpClient";

export default {
  name: "ClientDashboard",
  data(){
    return{
      globalClientId:'',
      dataStudioURL:''
    }
  },
  methods:{
    async getClientDataStudioURL(clientId){
      const response = await httpClient.get(`/clients/myClients/${clientId}/getClientDataStudio`)
      this.dataStudioURL = response.data.data
    },
    toggleInitialFormModal(){
      this.$store.dispatch('account/toggleInitialFormDialog')
    },
  },
  created() {
    this.globalClientId = this.$store.getters['account/getGlobalClient']._id
    this.getClientDataStudioURL(this.globalClientId)
  },
  mounted() {
    this.globalClient = this.$store.getters['account/getGlobalClient']
    if (!this.globalClient.completedInitialData){
      this.$router.push({name:'ClientCampaigns'})
    }
  }
}
</script>

<style scoped>
  .dashboard{
    height: 100%;
    margin-top: -34px;
  }
</style>